<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row" fxLayoutAlign="end center" class="mb-20">
        <div fxFlex="100" style="text-align: end">
            <button class="btn btn-cancel" (click)="back()" mat-raised-button>
                {{ "Back" | translate }}
            </button>
        </div>
    </div>
    <div>
        <mat-card>
            <div class="mx-5 mt-5 description">
                <div fxLayout="row wrap" fxLayoutAlign="none center">
                    <div fxFlex="100">
                        <div fxFlex="33">
                            <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
                                [chartType]="doughnutChartType" [colors]="colors" [options]="chartOptions">
                            </canvas>
                        </div>
                        <div fxFlex="2"></div>
                        <div fxFlex="18">
                            <h2>{{ "Risk Types" | translate }}</h2>
                            <br />
                            <div>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxFlex="20px" class="round" style="background-color: #e7ef0c"></div>
                                    <div fxFlex class="ml-1 legends_text" *ngIf="risk == 'low'">
                                        {{ "low" | translate }}
                                    </div>
                                    <div fxFlex class="ml-1 legends_text" *ngIf="risk == 'medium'">
                                        {{ "medium" | translate }}
                                    </div>
                                    <div fxFlex class="ml-1 legends_text" *ngIf="risk == 'high'">
                                        {{ "high" | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="">
                            <div fxFlex="100">
                                <h2>
                                    {{ "Target Allocation by Fund" | translate }}
                                </h2>
                                <div class="legends" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                    <div fxFlex="100" *ngFor="let val of fundAssets" style="margin-top: 20px">
                                        <div class="round" style="background-color: #71c9ff"></div>
                                        <div fxFlex class="ml-1 legends_text">
                                            {{ val.asset_name }} : 
                                            <span style="font-size: 16px;">{{val?.funds_assets?.allocation | number:'1.2-2' | slice:0:-2}}</span>
                                            <span style="font-size: 13px;">{{val?.funds_assets?.allocation | number:'1.2-2' | slice:-2}}%</span>
                                            <!-- {{ tr.numberFormatter2(val?.funds_assets?.allocation) }}% -->
                                        </div>
                                    </div>
                                </div>
                                <h2 style="margin-top: 20px;">
                                    {{ "Target Allocation by Asset Class" | translate }}
                                </h2>
                                <div class="legends" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                    <div fxFlex="100" *ngFor="let val of assetClassArray" style="margin-top: 20px">
                                        <div class="round" style="background-color: #71c9ff"></div>
                                        <div fxFlex class="ml-1 legends_text">
                                            {{ val.asset_class }} : 
                                            <span style="font-size: 16px;">{{val?.allocation | number:'1.2-2' | slice:0:-2}}</span>
                                            <span style="font-size: 13px;">{{val?.allocation | number:'1.2-2' | slice:-2}}%</span>
                                            <!-- {{ tr.numberFormatter2(val?.allocation) }}% -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-5 mt-5 description">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <div fxFlex="48">
                        <h2>{{ "Investment" | translate }}</h2>
                        <p>{{ "paraA" | translate }}</p>
                    </div>
                    <div fxFlex="48">
                        <h2>{{ "Risk" | translate }}</h2>
                        <p>
                            {{ "paraBA" | translate }}
                            <span *ngIf="risk == 'low'" style="color: #00c435; font-weight: 600">{{ "low" | translate
                                }}</span><span *ngIf="risk == 'medium'" style="color: #e69a5a; font-weight: 600">{{
                                "medium" | translate }}</span><span *ngIf="risk == 'high'"
                                style="color: #ec4955; font-weight: 600">{{ "high" | translate }}</span>, {{ "paraBB" |
                            translate }}
                        </p>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>