<div [ngStyle.xs]="{'width': '100%'}" style="width: 400px">
  <div
    style="
      font-size: 24px;
      text-align: center;
      color: #111e6c;
      padding-block: 20px;
      font-weight: bold;
    "
  >
    {{alertData.heading | translate}}
  </div>

  <div
    *ngIf="hasArabic"
    [innerHTML]="alertData.message"
    style="
      font-size: 13px;
      color: #666666;
      text-align: center;
      margin-top: 20px;
      padding: 0 30px;
    "
  ></div>

  <div
    *ngIf="!hasArabic"
    style="
      font-size: 13px;
      color: #666666;
      text-align: center;
      margin-top: 20px;
      padding: 0 30px;
    "
  >
    {{alertData.message | translate}}
  </div>
  <div
    *ngIf="alertData.hasInput == true"
    fxLayout="row wrap"
    fxLayoutAlign="space-evenly start"
    fxLayoutGap="20px"
    style="text-align: right; margin-top: 20px"
  >
    <textarea
      *ngIf="alertData.hasInput == true"
      [disabled]="isLoading"
      style="
        height: 100px;
        width: 400px;
        font-size: 20px;
        background-color: #f7f8f9;
        border-radius: 10px;
        border: none;
      "
      [(ngModel)]="reasonTExt"
    >
    </textarea>
  </div>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-evenly start"
    fxLayoutGap="20px"
    style="text-align: right; padding-block: 20px"
  >
    <div fxLayoutGap="20px">
      <button
        [ngClass]="alertData.leftButton.class"
        class="btn btn-ok-sec"
        [disabled]="isLoading"
        type="button"
        (click)="onCancelClick()"
      >
        {{alertData.leftButton.text | translate}}
      </button>
      <button
        [ngClass]="alertData.rightButton.class"
        class="btn btn-ok"
        *ngIf="alertData.type == 'ask'"
        [disabled]="isLoading"
        type="button"
        (click)="onSubmitClick()"
      >
        {{alertData.rightButton.text | translate}}
      </button>
    </div>
  </div>
</div>
