import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs-compat";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ThriftPlan | Employee Portal";
  isEnglish = false;
  showTranslationToggle: boolean = true;
  subscription: Subscription;

  constructor(protected router: Router) {
    localStorage.removeItem("auth_token");
    if (localStorage.getItem("lang") == "ar") {
      this.isEnglish = false;
    } else {
      this.isEnglish = true;
    }
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes("main")) {
          this.showTranslationToggle = false;
        }
        if (event.url == "/terms/termsandconditions") {
          this.showTranslationToggle = false;
        }
      }
    });
  }

  langChange(event) {}
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
