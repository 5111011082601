export const appConfig =
{
    // *** Develop (employee_dev_oci) ***
    // base_url: 'http://api-dev.thriftplan.io/api/employeePortal/',
    // file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/Thriftplan-Bucket/o/',

    // *** Staging (employee_stag_oci) ***
    // base_url: 'http://api-staging.thriftplan.com.sa/api/employeePortal/',
    // file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/Thriftplan-Bucket/o/',

    // *** Demo (employee_demo_oci) ***
    // base_url: 'https://api-demo.thriftplan.com.sa/api/employeePortal/',
    // file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/Thriftplan-Bucket/o/',

    // *** Preprod (employee_preprod_oci) ***
    // base_url: 'http://employee-preprod.thriftplan.com.sa/api/employeePortal/',
    // file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/Thriftplan-Bucket/o/',

    // *** Production (employee_prod_oci) ***
    // base_url: 'https://api.thriftplan.com.sa/api/employeePortal/',
    // file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/ThriftPlan-Production-Bucket/o/',

    base_url: 'https://api-demo.thriftplan.com.sa/api/employeePortal/',
    file_url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axpukryuo8l1/b/Thriftplan-Production-Bucket/o/',

    dateTimeformatCMS: 'DD-MM-YYYY h:mm a',
    dateformatCMS: 'DD-MM-YYYY',
    dateformatServer: 'YYYY-MM-DD',
}
