import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
  selector: "app-help-support",
  templateUrl: "./help-support.component.html",
  styleUrls: ["./help-support.component.scss"],
})
export class HelpSupportComponent implements OnInit {
  list: any = [];
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  key: any;
  panelOpenState = false;
  NotificationIds: any[] = [];
  fileName = "";
  Form: FormGroup;
  attachment = null;
  image: string | ArrayBuffer;

  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    protected formbuilder: FormBuilder,
    public route: Router,
    protected mainService: ApiService,
    protected dec_enc: DecEncService,
    protected paginationService: PaginationService,
    protected dialog: MatDialog
  ) {
    this.Form = this.formbuilder.group({
      title: ["", [Validators.required]],
      message: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.fileName = file?.name;
    this.attachment = file;
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0] as File;
      const reader = new FileReader();
      reader.onload = (e) => (this.image = reader.result);
      reader.readAsDataURL(file);
    }
  }

  submit() {
    let enc_payload = this.dec_enc.encryption(this.Form.value);
    let formData = new FormData();
    if (this.attachment) {
      formData.append("attachment", this.attachment);
    }
    formData.append("enc_payload", enc_payload);
    let url = "home/support";
    this.mainService
      .postData(url, formData)
      .then((res) => {
        if (res.statusCode == 200) {
          this.alertService.alert(
            "Success",
            "Thank you for reaching out to us. We will get back to you soon."
          );
          return;
        }
        this.alertService.alert("Error", "Thank you for reaching out to us.");
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }
}
