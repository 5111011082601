import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MainAuthGuard implements CanActivate {
  constructor(public route: Router) {}

  canActivate() {
    let emp = localStorage.getItem('employee_token');
    if (emp) {
      return true
    }
    else {
      this.route.navigate(['/auth/login']);
      return false;
    }
  }
}

