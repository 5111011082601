import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "formatTime" })
export class FormatTime implements PipeTransform {
  mins: string;
  hrs: string;
  constructor(public tr: TranslateService) {
    if (localStorage.getItem("lang") == "ar") {
      this.hrs = "ساعات";
      this.mins = "دقيقة";
    } else {
      this.hrs = "hrs";
      this.mins = "mins";
    }
  }

  transform(value: number): any {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    let dt = `${hours > 0 ? hours + this.hrs : ""} ${minutes} ${this.mins}`;
    return dt;
  }
}
