<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxLayout="column" fxFlex="59" ngClass.sm="d-none" ngClass.xs="d-none">
        <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs" />
        <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
            <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
                <h1>{{ "Welcome to the future of employee benefits" | translate }}</h1>
            </span>
        </span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="background: white; height: 100%; flex-grow: 1;">

        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 5%;">
            <img src="assets/thrift_logo.png" alt="Thriftplan Logo" width="200px" />
            <h1 style="font-size: 28px; margin-top: 20px;">{{'Self Onboarding' | translate}}</h1>
        </div>

        <mat-horizontal-stepper style="flex-grow: 1;" class="stepperStyle" #stepper
            (selectionChange)="onStepChange($event)" [selectedIndex]="currentIndexOfStepper" [linear]="true">

            <mat-step [completed]="false" [stepControl]="personalDetailsGroup">
                <div fxLayoutAlign="center center">
                    <h2 class="mid-head">{{'Personal Details' | translate}}</h2>
                </div>
                <form [formGroup]="personalDetailsGroup">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Name" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="name">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(personalDetailsGroup, 'name')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(personalDetailsGroup, 'name', 'Name is required.')"
                                [matTooltipDisabled]="!getFieldValue(personalDetailsGroup, 'name')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field class="input-field mt-10" fxFlex="60" fxFlex.sm="100" fxFlex.xs="100"
                            appearance="outline">
                            <mat-label>{{'Gender' | translate}}</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option value="1">{{'Male' | translate}}</mat-option>
                                <mat-option value="2">{{'Female' | translate}}</mat-option>
                            </mat-select>
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(personalDetailsGroup, 'gender')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(personalDetailsGroup, 'gender', 'Gender is required.')"
                                [matTooltipDisabled]="!getFieldValue(personalDetailsGroup, 'gender')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                            fxFlexAlign="center" appearance="outline">
                            <mat-label>{{'Date_of_Birth' | translate}}</mat-label>
                            <input (click)="openDatePicker(1)" matInput type="text" formControlName="date_of_birth"
                                [matDatepicker]="picker1" [max]="currentDate" readonly />
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(personalDetailsGroup, 'date_of_birth')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(personalDetailsGroup, 'date_of_birth', 'Date of birth is required.')"
                                [matTooltipDisabled]="!getFieldValue(personalDetailsGroup, 'date_of_birth')" matTooltipPosition="above" matPrefix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Nationality" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="nationality">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(personalDetailsGroup, 'nationality')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(personalDetailsGroup, 'nationality', 'Nationality is required.')"
                                [matTooltipDisabled]="!getFieldValue(personalDetailsGroup, 'nationality')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "National ID/Iqama" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="iqama_id">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(personalDetailsGroup, 'iqama_id')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(personalDetailsGroup, 'iqama_id', 'National ID/Iqama is required.')"
                                [matTooltipDisabled]="!getFieldValue(personalDetailsGroup, 'iqama_id')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div style="display: flex; justify-content: center; margin-top: 10px;">
                        <mat-checkbox [checked]="checkboxChecked" [ngStyle.xs]="{'flex-grow': 1}" style="margin-right: 10px;" (change)="checkboxChecked = !checkboxChecked" class="example-margin"></mat-checkbox>
                        <span (click)="checkboxChecked = !checkboxChecked" [ngStyle.xs]="{'flex-grow': 9}" style="text-align: start;" [innerHTML]="'I agree to the Privacy Policy & Terms and Conditions' | translate"></span>
                    </div>
                </form>
                <div fxLayoutAlign="center end" style="margin-top: 20px; flex-grow: 1;">
                    <button [disabled]="!checkboxChecked" (click)="onNext()" style="width: 180px; font-weight: bold; border-radius: 20px;"
                        mat-raised-button class="btnLogin">{{'Next' | translate}}
                    </button>
                </div>
            </mat-step>
            <mat-step [completed]="false" [stepControl]="userDetailsGroup">
                <div fxLayoutAlign="center center">
                    <h2 class="mid-head">{{'User Details' | translate}}</h2>
                </div>
                <form [formGroup]="userDetailsGroup">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Email ID" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="email">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(userDetailsGroup, 'email')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(userDetailsGroup, 'email', 'Email ID is required.')"
                                [matTooltipDisabled]="!getFieldValue(userDetailsGroup, 'email')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Contact number" | translate }}</mat-label>
                            <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="phone">
                            <span *ngIf="!isArabic" style="margin-right: 2px;" matPrefix>+966</span>
                            <span dir="ltr" style="margin-left: 2px;" *ngIf="isArabic" matSuffix>+966</span>
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(userDetailsGroup, 'phone')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(userDetailsGroup, 'phone', 'Contact number is required.')"
                                [matTooltipDisabled]="!getFieldValue(userDetailsGroup, 'phone')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Password" | translate }}</mat-label>
                            <input autocomplete="off" [type]="passwordType" matInput formControlName="password">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(userDetailsGroup, 'password')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(userDetailsGroup, 'password', 'Password is required.')"
                                [matTooltipDisabled]="!getFieldValue(userDetailsGroup, 'password')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                            <mat-icon matTooltip="{{'Show Password' | translate}}" *ngIf="userDetailsGroup.get('password').value && passwordType == 'password'" style="cursor: pointer;" (click)="handlePassword('password')" matSuffix>visibility</mat-icon>
                            <mat-icon matTooltip="{{'Hide Password' | translate}}" *ngIf="userDetailsGroup.get('password').value && passwordType == 'text'" style="cursor: pointer;" (click)="handlePassword('password')" matSuffix>visibility_off</mat-icon>
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Confirm Password" | translate }}</mat-label>
                            <input autocomplete="off" [type]="confirmPasswordType" matInput formControlName="confirm_password">
                            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(userDetailsGroup, 'confirm_password')" #tooltip="matTooltip"
                                [matTooltip]="getErrorMessage(userDetailsGroup, 'confirm_password', 'Confirm Password is required.')"
                                [matTooltipDisabled]="!getFieldValue(userDetailsGroup, 'confirm_password')" matTooltipPosition="above" matSuffix>info
                            </mat-icon>
                            <mat-icon matTooltip="{{'Show Password' | translate}}" *ngIf="userDetailsGroup.get('confirm_password').value && confirmPasswordType == 'password'" style="cursor: pointer;" (click)="handlePassword('confirmPassword')" matSuffix>visibility</mat-icon>
                            <mat-icon matTooltip="{{'Hide Password' | translate}}" *ngIf="userDetailsGroup.get('confirm_password').value && confirmPasswordType == 'text'" style="cursor: pointer;" (click)="handlePassword('confirmPassword')" matSuffix>visibility_off</mat-icon>
                        </mat-form-field>
                    </div>
                </form>
                <div fxLayoutAlign="center end" style="margin-top: 20px; flex-grow: 1;">
                    <button (click)="onNext()" style="width: 180px; font-weight: bold; border-radius: 20px;"
                        mat-raised-button class="btnLogin">{{'Next' | translate}}</button>
                </div>
            </mat-step>
            <mat-step [completed]="false" [stepControl]="employmentDataGroup">
                <div fxLayoutAlign="center center">
                    <h2 class="mid-head">{{'Employment Data' | translate}}</h2>
                </div>
                <form [formGroup]="employmentDataGroup">
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Position" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="position">
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Monthly Salary" | translate }}</mat-label>
                            <input autocomplete="off" type="text" matInput formControlName="monthly_salary">
                        </mat-form-field>
                        <mat-form-field fxFlex="60" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                            fxFlexAlign="center" appearance="outline">
                            <mat-label>{{'Employee Since' | translate}}</mat-label>
                            <input (click)="openDatePicker(2)" matInput type="text" formControlName="employee_since"
                                [matDatepicker]="picker2" [max]="currentDate" readonly />
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </form>
                <div fxLayoutAlign="center end" style="margin-top: 20px; flex-grow: 1;">
                    <button (click)="onNext()" style="width: 180px; font-weight: bold; border-radius: 20px;"
                        mat-raised-button class="btnLogin">{{'Submit' | translate}}</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>