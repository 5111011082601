import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-congratulations-dialog',
    templateUrl: './congratulations-dialog.component.html',
    styleUrls: ['./congratulations-dialog.component.scss']
})
export class CongratulationsDialogComponent implements OnInit
{

    constructor(
        protected apiService: ApiService,
        protected alertService: AlertService,
        protected dialog: MatDialog,
        protected dialogRef: MatDialogRef<CongratulationsDialogComponent>,
    ) 
    {

    }

    ngOnInit(): void
    {

    }

    onContact()
    {
        
    }

    thankyouCall()
    {
        this.apiService.postData('home/thank-you', {}).then(response => {
            if (response.statusCode == 200) {

            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    closeDialog()
    {
        this.thankyouCall();
        this.dialogRef.close();
    }
}
