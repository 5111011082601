import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import * as Highcharts from "highcharts";
import { AlertService } from "src/app/services/alert.service";

@Component({
    selector: "app-return-calculator",
    templateUrl: "./return-calculator.component.html",
    styleUrls: ["./return-calculator.component.scss"],
})
export class ReturnCalculatorComponent implements OnInit
{
    Form: FormGroup;
    highcharts = Highcharts;
    years: any = [];
    list: any = [];
    no_of_years: any = [];
    employee_contribution: number[] = [];
    employer_contribution: number[] = [];
    sum_contribution: any = [];
    sum;
    MatchingContribution = 0;
    RiskValue: any = 0;
    mainRisk: any;
    vested_amount: number[] = [];
    index: number[] = [];
    accumulative_return: number[] = [];
    employee_balance: number[] = [];
    sum_ecvm: number[] = [];
    listSum: any;
    sum_ebvm: number[] = [];
    salary: number = 0;
    editModes = {
        salary: false,
    }
    
    constructor(
        public tr: TranslationService,
        public alertService: AlertService,
        protected formbuilder: FormBuilder,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        protected mainService: ApiService,
        public dec_enc: DecEncService
    )
    {
        this.Form = this.formbuilder.group({
            salary: [
                "",
                [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)],
            ],
            contribution_percent: ["5"],
            risk_level: ["medium"],
            growth_percent: ["5"],
            matching_percent: ["100"],
            no_of_years: ["30"],
        });
    }

    ngOnInit(): void
    {
        this.mainRisk = "low";
        this.getYears();
    }

    Contribution(event)
    {
        this.MatchingContribution = event.target.value;
        this.Form.get("contribution_percent").setValue(this.MatchingContribution);
        this.getall();
    }

    Risk(event)
    {
        this.tr.selectedApp.subscribe((state) =>
        {
            this.tr.translation = state;
            this.riskArabic(event);
        });
        this.riskArabic(event);
    }

    riskArabic(event)
    {
        this.RiskValue = event.target.value;
        if (this.RiskValue == "0") {
            this.mainRisk = "low";
            this.Form.get("risk_level").setValue("low");
        }
        if (this.RiskValue == "50") {
            this.mainRisk = "medium";
            this.Form.get("risk_level").setValue("medium");
        }
        if (this.RiskValue == "100") {
            this.mainRisk = "high";
            this.Form.get("risk_level").setValue("max");
        }
        this.getall();
    }

    back()
    {
        window.history.back();
    }

    getYears()
    {
        for (let i = 0; i < 30; i++) {
            this.years[i] = i + 1 + " " + this.tr.translation.Years;
        }
    }

    getall()
    {
        let url = "return_calculator";
        let enc_payload = this.dec_enc.encryption(this.Form.value);
        this.mainService
            .postData(url, { enc_payload })
            .then((res) =>
            {
                if (res.statusCode == 200) {
                    let data = res.data;
                    let result = this.dec_enc.decryption(data);
                    this.employee_contribution = [];
                    this.vested_amount = [];
                    this.employee_balance = [];
                    this.sum_ecvm = [];
                    this.sum_ebvm = [];
                    this.list = result.monthly_calculator;
                    this.listSum = result.sum;
                    this.list.forEach((element) =>
                    {
                        this.employee_contribution.push(element.employee_contribution);
                        this.employee_balance.push(element.employee_balance);
                        this.vested_amount.push(element.vested_amount);
                        this.sum_ecvm.push(element.sum_ecvm);
                        this.sum_ebvm.push(element.sum_ebvm);
                        this.index.push(element.index);
                    });
                    this.lineGraph();
                    return;
                }
                this.alertService.alertAr("Error", res);
            })
            .catch((error) =>
            {
                this.alertService.alert("Error", "Server Error");
            });
    }

    lineGraph()
    {
        this.tr.selectedApp.subscribe((state) =>
        {
            this.tr.translation = state;
            this.lineGraphOptions();
        });
        this.lineGraphOptions();
    }

    lineGraphOptions()
    {
        Highcharts.chart("container", {
            chart: {
                type: "areaspline",
            },
            xAxis: {
                title: {
                    text: this.tr.translation.Years,
                },
                min: 0,
                max: 30, //years
                tickInterval: 5,
            },
            yAxis: {
                title: {
                    text: "",
                },
                labels: {
                    enabled: false,
                },
                gridLineColor: "transparent",
            },

            plotOptions: {
                areaspline: {
                    fillOpacity: 0.2,
                    marker: {
                        radius: 0,
                    },
                },
            },
            series: [
                {
                    data: this.sum_ebvm,
                    type: "areaspline",
                    name: this.tr.translation.AccumulativeReturn,
                    color: "#8EBFE8",
                    fillColor: "#8EBFE8",
                },
                {
                    data: this.sum_ecvm,
                    type: "areaspline",
                    name: this.tr.translation.Vested_return,
                    color: "#C5DAEC",
                    fillColor: "#C5DAEC",
                },
                {
                    data: this.employee_contribution,
                    type: "areaspline",
                    name: this.tr.translation.Total_Employee_Contribution,
                    color: "#5391D9",
                    fillColor: "#5391D9",
                },
            ],
            legend: {
                enabled: false,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
            },

            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
        });
    }

    setMode(control, mode) {
        if (!this.salary)
        {
            this.salary = 0;
        }
        this.editModes[control] = mode;
    }
}
