import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, delay } from "rxjs/operators";
import { ApiLoaderService } from "./api-loader.service";

@Injectable({
  providedIn: "root",
})
export class ApiLoaderInterceptorService implements HttpInterceptor {
  count: any;
  unloaderUrl: any;
  constructor(private loaderService: ApiLoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = req.url.split("fetch");
    this.unloaderUrl = req.url;
    var lastSegment = this.unloaderUrl.split("/").pop();
    if (
      lastSegment === "unread_count" ||
      lastSegment === "home/unread_count" ||
      lastSegment === "notifications" ||
      lastSegment === "home/read_notifications" ||
      lastSegment === "read_notifications"
    ) {
    } else {
      this.showLoader();
      this.count++;
    }
    const customReq = req.clone({});
    return next.handle(customReq).pipe(
      delay(1000),
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.hideLoader();
          }
        },
        (err: any) => {
          this.count--;
          this.hideLoader();
        }
      )
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
