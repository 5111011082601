import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { appConfig } from "src/config";

@Component({
  selector: "app-academy",
  templateUrl: "./academy.component.html",
  styleUrls: ["./academy.component.scss"],
})
export class AcademyComponent implements OnInit {
  list: any = [];
  searchTimer: any;
  search: any;
  level: any;
  sort_by: any = 0;
  colHeader = {
    id: "Course ID",
    title: "Title",
    total_lessons: "Chapters",
    level: "Level",
    category: "Category",
    duration: "Duration",
    created_at: "Created Date",
    revised_on: "Revised Date",
    actions: "Actions",
  };
  columnTypes = {
    created_at: "date",
    revised_on: "date",
    duration: "time",
    actions: "actionsSeperate",
  };
  headingData: string = "Courses";
  searchHeading: string = "Search";
  headerProps = {
    heading: "Courses",
    hasSearch: true,
    hasButton: false,
    ActionButtons: [
      {
        label: "Create Contributions Request",
        route: "manage_withdrawls/add",
        type: "link",
        visibility: true,
        isCustom: false,
        icon: "person_add",
      },
    ],
    filterArray: [
      {
        label: "Sort by",
        type: "sort_by",
        key: "sort_by",
        visibility: true,
        selected: "0",
        options: [
          {
            value: "0",
            label: "Latest",
            key: "sort_by",
          },
          {
            value: "1",
            label: "On Going",
            key: "sort_by",
          },
          {
            value: "2",
            label: "Relevant",
            key: "sort_by",
          },
        ],
      },
      {
        label: "Level",
        type: "level",
        key: "level",
        visibility: true,
        selected: "all",
        options: [],
      },
    ],
  };
  actions = [
    {
      label: "View Course",
      type: "view",
      visibility: true,
      isCustom: true,
      background: "#4318FF0D",
      source: "assets/table-view.svg",
    },
    {
      label: "Start Course",
      type: "start",
      visibility: true,
      isCustom: true,
      background: "#4318FF0D",
      source: "assets/table-view.svg",
    },
  ];
  paginations = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0,
  };

  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    public route: Router,
    protected mainService: ApiService,
    public dec_enc: DecEncService
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getDropdownData("academy/courses/levels");
  }

  getList() {
    let count = 1;
    let urlprefix = "academy/courses";
    if (this.sort_by == 1) {
      urlprefix = "academy/courses/on-going";
    } else if (this.sort_by == 2) {
      urlprefix = "academy/courses/relevant";
    }
    let url = `${urlprefix}?per_page=${this.paginations.per_page}&sort_order=asc&page=${this.paginations.page}`;
    if (this.paginations.per_page != null) {
      count = this.paginations.per_page * (this.paginations.page - 1) + 1;
    }
    let data = {
      search: this.search ? this.search : null,
      course_level_id: this.level ? this.level : null,
    };
    let enc_payload = this.dec_enc.encryption(data);
    this.mainService
      .postData(url, { enc_payload })
      .then((res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          this.paginations = result.pagination;
          this.list = result.courses;
          this.afterList();
          return;
        }
        this.alertService.alertAr("Error", res.error);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  getDropdownData(url) {
    this.headerProps.filterArray[1].options = [];
    let df = {
      value: "all",
      label: "All",
      key: "level",
    };
    this.headerProps.filterArray[1].options.push(df);
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          result.lovs_course_levels.forEach((item) => {
            let dt = {
              value: item.id,
              label: item.name,
              key: "level",
            };
            this.headerProps.filterArray[1].options.push(dt);
          });
          return;
        }
        this.alertService.alertAr("Error", res.error);
      },
      (error) => {
        this.alertService.alertAr("Error", this.tr.translation.serverError);
      }
    );
  }

  actionClicked(event) {
    if (event.action.type == "start") {
      let enc_payload = this.dec_enc.encryption({
        course_id: event.element.id,
      });
      this.mainService.postData("academy/courses/start", { enc_payload }).then(
        (res) => {
          if (res.statusCode == 200) {
            this.route.navigate([
              "main/academy-courses/course/" + event.element.id,
            ]);
            return;
          }
          this.alertService.alertAr("Error", res.error);
        },
        (error) => {
          this.alertService.alertAr("Error", this.tr.translation.serverError);
        }
      );
    } else {
      this.route.navigate(["main/academy-courses/course/" + event.element.id]);
    }
  }

  afterList() {
    this.list.forEach((item) => {
      item["view"] =
        item["total_lessons"] > 0 && item["course_users"].length > 0
          ? true
          : false;
      item["start"] =
        item["total_lessons"] > 0 && item["course_users"].length == 0
          ? true
          : false;
      item["created_at"] = moment(item["created_at"]).format(
        appConfig.dateformatCMS
      );
      item["revised_on"] = moment(item["revised_on"]).format(
        appConfig.dateformatCMS
      );

      item["level"] = item["course_level"]["name"];
      item["category"] = item["course_category"]["name"];
    });
  }

  setPage(page) {
    this.paginations.page = page;
    this.getList();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getList();
    }, 800);
  }

  onFilters(event) {
    if (event.key == "level") {
      if (event.selected == "all") {
        this.level = null;
      } else {
        this.level = event.selected;
      }
    } else if (event.key == "sort_by") {
      this.sort_by = event.selected;
    }
    this.getList();
  }
}
