<div
  *ngIf="methodType != 'delete' && !risk"
  style="min-width: 300px; max-width: 350px; text-align: center"
>
  <div *ngIf="type == 'info'" class="content-head clearfix">
    <div style="font-size: 25px" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'success'" class="content-head clearfix">
    <div style="font-size: 25px; color: green" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'error'" class="content-head clearfix">
    <div style="font-size: 25px; color: red" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'ask'" class="content-head clearfix">
    <div style="font-size: 25px; color: red" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div class="row">
    <div
      class="col-sm-12"
      [innerHTML]="message"
      style="max-height: 400px; overflow: auto"
    >
    </div>
    <div class="col-sm-12" style="text-align: center; margin-top: 30px">
      <button
        mat-raised-button
        color="primary"
        style="
          margin-right: 5px;
          min-width: 100px;
          background: transparent
            linear-gradient(283deg, #040405 0%, #004390 55%, #3f638f 100%) 0% 0%
            no-repeat padding-box;
          color: white;
          border-radius: 50px;
        "
        class="btn btn-clear"
        [disabled]="isLoading"
        type="button"
        (click)="onCancelClick()"
      >
        {{cancelButtonText}}
      </button>
      <button
        mat-raised-button
        color="primary"
        style="
          margin-right: 5px;
          min-width: 100px;
          background: transparent
            linear-gradient(283deg, #040405 0%, #004390 55%, #3f638f 100%) 0% 0%
            no-repeat padding-box;
          color: white;
          border-radius: 50px;
        "
        class="btn btn-submit btn-primary"
        *ngIf="type == 'ask'"
        [disabled]="isLoading"
        type="button"
        (click)="onSubmitClick()"
      >
        {{submitButtonText}}
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="methodType == 'delete'  && !risk"
  style="min-width: 300px; text-align: center"
>
  <img src="assets/images/dowwak-portal/delete-modal.png" alt="" />
  <div class="row">
    <div
      class="col-sm-12"
      style="max-height: 400px; overflow: auto; margin-bottom: 5px"
    >
      Are you sure you want to delete?
    </div>
    <div class="col-sm-12" style="max-height: 400px; overflow: auto">
      If this record is deleted, you won't be able to recover it!
    </div>
    <div class="col-sm-12" style="margin-top: 30px">
      <button
        mat-raised-button
        color="primary"
        style="
          margin-right: 5px;
          min-width: 100px;
          background: transparent
            linear-gradient(283deg, #040405 0%, #004390 55%, #3f638f 100%) 0% 0%
            no-repeat padding-box;
          color: white;
          border-radius: 50px;
        "
        class="btn btn-clear"
        [disabled]="isLoading"
        type="button"
        (click)="onCancelClick()"
      >
        CANCEL
      </button>
      <button
        mat-raised-button
        color="primary"
        style="background: #e51607"
        class="btn btn-submit btn-primary"
        *ngIf="type == 'ask'"
        [disabled]="isLoading"
        type="button"
        (click)="onSubmitClick()"
      >
        DELETE
      </button>
    </div>
  </div>
</div>

<div *ngIf="risk" style="min-width: 300px; width: 350px; text-align: center">
  <div *ngIf="type == 'info'" class="content-head clearfix">
    <div style="font-size: 25px" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'success'" class="content-head clearfix">
    <div style="font-size: 25px; color: green" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'error'" class="content-head clearfix">
    <div style="font-size: 25px; color: red" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div *ngIf="type == 'ask'" class="content-head clearfix">
    <div style="font-size: 25px; color: red" class="title mb-4">
      {{heading | titlecase}}
    </div>
  </div>
  <div class="row text-center mx-1">
    <div
      class="col-sm-12"
      [innerHTML]="message"
      style="max-height: 400px; overflow: auto; text-transform: capitalize"
    >
    </div>
    <div class="col-sm-12" style="text-align: center; margin-top: 20px">
      <button
        mat-raised-button
        color="primary"
        style="
          margin-right: 5px;
          background-color: #0f4a90;
          color: white;
          border-radius: 50px;
          min-width: 80px;
        "
        class="btn btn-clear"
        [disabled]="isLoading"
        type="button"
        (click)="onOkClick()"
      >
        {{submitButtonText}}
      </button>
      <button
        mat-raised-button
        color="primary"
        style="
          margin-right: 5px;
          background-color: #0f4a90;
          color: white;
          border-radius: 50px;
          min-width: 80px;
        "
        class="btn btn-clear"
        [disabled]="isLoading"
        type="button"
        (click)="onCancelClick()"
      >
        {{cancelButtonText}}
      </button>
    </div>
  </div>
</div>
