import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormBuilder } from "@angular/forms";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import NestedList from "@editorjs/nested-list";
import Table from "@editorjs/table";
import SimpleImage from "@editorjs/simple-image";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Alert from "editorjs-alert";
import Raw from "@editorjs/raw";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import DragDrop from "editorjs-drag-drop";
import Underline from "@editorjs/underline";
import ColorPlugin from "editorjs-text-color-plugin";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { appConfig } from "src/config";
import * as moment from "moment";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
  editor: EditorJS = null;
  data: any;
  updatedAt = null;
  lang = "en";
  isAdminApproved: boolean = true;
  userRole: string = null;
  isEnglish: boolean = true;
  forceStaus: any;
  TermoldStatus: any;
  TermCurrentStatus: any;

  constructor(
    public route: Router,
    public http: HttpClient,
    public tr: TranslationService,
    protected dialog: MatDialog,
    protected formbuilder: FormBuilder,
    public dec_enc: DecEncService,
    private mainService: ApiService,
    protected messagingService: MessageService,
    protected alertService: AlertService,
    public prService: ProfileService
  ) {
    this.TermCurrentStatus = this.prService.user.terms_status;
    this.TermoldStatus = this.prService.user.terms_old_status;
    this.getTerms();
    this.editor = new EditorJS({
      holder: "editorjs",
      readOnly: true,
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
        },
        paragraph: {
          inlineToolbar: true,
          tunes: ["alignmentTuneTool"],
        },
        image: {
          class: SimpleImage,
          inlineToolbar: true,
          tunes: ["alignmentTuneTool"],
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+L",
          tunes: ["alignmentTuneTool"],
        },
        Color: {
          class: ColorPlugin,
          config: {
            type: "text",
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+T",
          tunes: ["alignmentTuneTool"],
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+A",
          tunes: ["alignmentTuneTool"],
        },
        Marker: {
          class: ColorPlugin,
          config: {
            type: "marker",
          },
        },
        delimiter: Delimiter,
        inlineCode: InlineCode,
        raw: Raw,
        underline: Underline,
        alignmentTuneTool: {
          class: AlignmentTuneTool,
        },
      },
      onReady: () => {
        new DragDrop(this.editor);
      },
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem("lang") == "ar") {
      this.isEnglish = false;
    } else {
      this.isEnglish = true;
    }
  }

  getTerms() {
    let url = "home/terms";
    this.mainService.postData(url, {}).then(res => {
        if (res.statusCode == 200) {
          this.data = this.dec_enc.decryption(res.data);
          this.forceStaus = this.data.force_accept;
          this.updatedAt = moment(this.data.updated_at).format(
            appConfig.dateTimeformatCMS
          );
          if (this.isEnglish) {
            if (this.data.description != null) {
              this.editor.blocks.renderFromHTML(this.data.description.join(""));
            }
          } else {
            if (this.data.description_ar != null) {
              this.editor.blocks.renderFromHTML(
                this.data.description_ar.join("")
              );
            }
          }
          return;
        } 
        else {
          this.alertService.alert("Error", res.error).then((res) => {
            this.route.navigate(["/"]);
          });
        }
      });
  }

  onAcceptTerms() {
    let url = "home/accept_terms";
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          localStorage.setItem("terms_status", "1");
          this.route.navigate(["/main"]);
        } else {
          this.alertService.alertAr("Error", res);
        }
      },
      (error) => {
        this.alertService.alertAr("Error", "Server Error");
      }
    );
  }

  RejcetTerms() {
    let url = "home/reject_terms";
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          localStorage.setItem("terms_status", "2");
          this.route.navigate(["/main"]);
        } else {
          this.alertService.alertAr("Error", res);
        }
      },
      (error) => {
        this.alertService.alertAr("Error", "Server Error");
      }
    );
  }

  onCancelTerms() {
    if (this.TermCurrentStatus == "0" && this.TermoldStatus == "0") {
      this.alertService
        .alertAsk(
          "Confirmation",
          "You must accept the Terms and Conditions to continue.You will be taken back to the login screen. Are you sure you want to cancel?",
          "yes",
          "no",
          false
        )
        .then((result) => {
          if (result) {
            this.logout();
          }
        });
    }
    if (this.TermCurrentStatus == "1" && this.TermoldStatus == "1") {
      this.route.navigate(["/main"]);
    }
    if (this.TermCurrentStatus == "0" && this.TermoldStatus == "1") {
      if (this.forceStaus != "0") {
        this.RejcetTerms();
      } else {
        this.alertService
          .alertAsk(
            "Confirmation",
            "You must accept the Terms and Conditions to continue. You will be taken back to the login screen. Are you sure you want to cancel?",
            "Yes",
            "No",
            false
          )
          .then((result) => {
            if (result) {
              this.logout();
            }
          });
      }
    }
  }

  logout() {
    this.mainService.onLogout().then((result) => {
      if (result.statusCode == 200) {
        localStorage.clear();
        localStorage.removeItem("employee_token");
        localStorage.removeItem("name");
        localStorage.removeItem("employee_password_token");

        window.location.reload();
      }
    });
  }

  switchLang(param) {
    this.lang = param;
    if (param == "en") {
      this.editor.render(this.data.description);
    }
    if (param == "ar") {
      this.editor.render(this.data.description_ar);
    }
  }
}
