import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  @Input() img = null;
  @Input() imgCustom = null;
  @Input() icon = null;
  @Input() topText = null;
  @Input() middleText = null;
  @Input() middleTextNumber = null;
  @Input() middleTextNumberFour = null;
  @Input() middleTextNumberFive = null;
  @Input() middleTextAlphabet = null;
  @Input() bottomText = null;
  @Input() error = false;
  @Input() layout? = "row";

  constructor() {}

  ngOnInit(): void {}
}
