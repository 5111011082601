<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <div class="mt-4 profile-card">
    <ng-container *ngFor="let lis of list">
      <mat-card class="mb-3" (click)="onSelectFund(lis.id, lis.risk_rating)">
        <div class="text-center mb-3" class="fund-togle">
          <div class="recomend" *ngIf="recomended_risk == lis.risk_rating">
            <p class="text-center">
              {{ "Recomended" | translate }}
            </p>
          </div>
          <div class="arrow-right" *ngIf="lis.id == select_fund"></div>
          <h1
            style="color: #00c435"
            class="text-center"
            *ngIf="lis.risk_rating == 'low'"
          >
            {{ "low" | translate }}
          </h1>
          <h1
            style="color: #e69a5a"
            class="text-center"
            *ngIf="lis.risk_rating == 'medium'"
          >
            {{ "medium" | translate }}
          </h1>
          <h1
            style="color: #ec4955"
            class="text-center"
            *ngIf="lis.risk_rating == 'high'"
          >
            {{ "high" | translate }}
          </h1>
          <div class="text-center mb-1">
            <h3 class="text-center" style="color: #213060">
              {{ "Fund Name" | translate }}: {{ lis.name }}
            </h3>
            <h3 class="text-center" style="color: #213060">
              {{ "ExpectedReturn" | translate }} :
              {{
                lis?.expected_return_percentage
                  ? lis?.expected_return_percentage
                  : "0"
              }}
              %
            </h3>
            <p
              style="color: #213060"
              *ngIf="recomended_risk == lis.risk_rating"
            >
              {{ "RecommandedRisk" | translate }} {{ lis.risk_rating }}
              {{ "Risk" | translate }}
            </p>
            <a
              style="color: #707eae"
              routerLink="../../reallocate-funds/fund-detail/{{ lis.id }}"
              >{{ "learn_more" | translate }}</a
            >
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
