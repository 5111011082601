<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <mat-card>
    <div fxLayout="row wrap">
      <div fxFlex="89">
        <h2 style="color: #213060">
          {{ course?.title }}
        </h2>
        <p style="color: #213060">
          {{ course?.description }}
        </p>
      </div>
      <div fxFlex="10" fxLayoutAlign="end start">
        <mat-icon style="cursor: pointer" (click)="onLike()">{{
          course?.course_user_likes?.length > 0 ? "favorite" : "favorite_border"
        }}</mat-icon>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="20px">
      <div fxLayout="row wrap">
        <mat-icon style="font-size: 22px">import_contacts</mat-icon>
        <div>&nbsp;{{ course?.total_lessons }}</div>
      </div>
      <div fxLayout="row wrap">
        <mat-icon style="font-size: 22px">watch_later</mat-icon>
        <div>&nbsp;{{ course?.duration | formatTime }}</div>
      </div>
    </div>
  </mat-card>

  <div fxLayout="row wrap" class="mt-20">
    <app-table
      fxFlex="100"
      [headingData]="headingData"
      [headerProps]="headerProps"
      [searchHeading]="searchHeading"
      (searchData)="searchData($event)"
      [dataSource]="list"
      [columnTypes]="columnTypes"
      [colHeader]="colHeader"
      [actions]="actions"
      (setPage)="setPage($event)"
      [pagination]="paginations"
      (actionClicked)="actionClicked($event)"
      (emitFilters)="onFilters($event)"
    >
    </app-table>
  </div>
</div>
