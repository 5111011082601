export const english = {
  //auth
  sign_in: 'Login',
  admin: 'Employee',
  portal: 'Portal',
  welcome: 'Welcome to',
  thrift: 'Thrift',
  plan: 'Plan',
  secure: 'This is a secure website',
  request: 'Please enter your username and password to log in',
  username: 'Username',
  password: 'Password',
  forgot: 'Forgot Password',
  send_otp: 'Confirm OTP',
  otp_pl: 'Enter 6 Digit Code',
  otp_req: 'Please enter your 6 digit code here to login',
  forgot_password: 'Forgot Password',
  emailforget: 'Please enter your email address to reset your password.',
  email_req: 'Please enter your email address to',
  reqover_req: ' reset your password.',
  email_address: 'Enter email address',
  reqover_password: 'Send OTP',
  rec_req: 'Please enter the 6-digit code sent',
  rec_email: 'to your Email Address.',
  confirm_otp: 'Confirm',
  creat_new: 'Create New Password',
  req: 'Please enter your new password.',
  rec_password: 'Enter New Password',
  con_password: 'Confirm New Password',
  done: 'Done',

  //dashboard
  thrift_hed: 'thrift',
  plan_hed: 'plan',
  home: 'Home',
  profile: 'My Profile',
  mp: 'Manage Portfolio',
  myp: 'My Portfolio',
  mr: 'Manage Redemptions',
  mw: 'Manage Withdrawals',
  mi: 'Manage Investments',
  risk: 'Risk Appetite Survey',
  mc: 'Manage Contributions',
  reports: 'Reports',
  noti: 'Notifications',
  Log_Out: 'Log Out',
  sbs: 'Sort By Status',
  sbo: 'Sort By Owner',
  view: 'View All',
  search: 'Search',
  iqama_national_ID_Scaned_Copy: 'National/Iqama ID Scanned Copy',
  low: 'LOW',
  medium: 'MEDIUM',
  high: 'HIGH',
  //detail profile
  My_detail_information: 'My Detailed Information',
  Employee_Basic_Details: 'Employee Basic Details',
  Next_of_Kin_Information: 'Next of Kin Information',
  Plan_Specific_details: 'Plan Specific Details',
  Tax_Residency_Information: 'Tax Residency Information',
  Name: 'Name',
  Phone: 'Phone',
  Email: 'Email',
  Gender: 'Gender',
  Date_of_Birth: 'Date of Birth',
  Address: 'Address',
  Iqama_Id_National_Id: 'National/Iqama ID',
  Iqama_National_ID_expiry: 'National/Iqama ID Expiry Date',
  Are_You_Dual_Nationality_Holder: 'Dual Nationality Holder?',
  Nationality: 'Nationality',
  Employee_Since: 'Employee Since',
  Position: 'Position',
  Salary: 'Salary',
  COMMENT: 'COMMENT',

  RelationshiptoNextofKin: 'Relationship to Next of Kin',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Contactdetails: 'Contact Details',
  DoesanyoneholdaPowerofattorneyforyou: ' Does anyone hold a Power of attorney for you?',
  MonthlyContributionAmount: 'Monthly Contribution Amount',
  Matching: 'Matching %',
  Matching_Rule: 'Matching Rule',

  Tax: 'Tax',
  TaxSSNNo: 'Tax SSN No',
  TaxITINNo: 'Tax ITIN No',


  //manage portfolio
  Profit: 'Profit/Loss',
  ProfitTotal: 'Total Profit/Loss',
  Risk_type: 'Risk Type',
  Total_Balance: 'Total Balance',
  Total_Contributions: 'Total Contributions',
  Employer_Contribution: 'Employer Contribution',
  My_Contribution: 'My Contribution',
  Total_Withdrawan_Amount: 'Total Withdrawn Amount',
  Available_for_Withdrawal: 'Available for Withdrawal',
  Available_units: 'Available Units',
  total_units: 'Total Number of Units',
  Fund_Name: 'Portfolio Name',
  Investment_Bank: 'Investment Bank',
  Invested_Amount: 'Invested Amount',
  Liquidity_Type: 'Liquidity Type',
  Average_Return: 'Average Return',
  Vested: 'Vested',
  Vested_return: 'Vested Return',
  Unvested: 'Unvested',
  Vested_amount: 'Vested Amount',
  Unvested_amount: 'Unvested Amount',
  Employee_Contribution_Detail: 'Employee Contribution Details',
  Employee_Contribution: 'Employee Contribution',
  Total_Employee_Contribution: 'Total Employee Contribution',
  Employer_Contribution_Detail: 'Employer Contribution Details',
  Yearly_Profit_Report: 'Profit Report',
  sby: 'Sort By Year',
  sbm: 'Sort By Month',

  //manage Contribution
  Manage_Contributions: 'Manage Contributions',
  Contribution_ID: 'Contribution ID',
  CSV_ID: 'CSV ID',
  Total_Contribution: 'Total Contribution',
  Created_At: 'Created At',
  Status: 'Status',
  Action: 'Action',
  Years: 'Years',
  //manage Withdrawals
  Manage_Withdrawal: 'Manage Withdrawal',
  Withdrawal_ID: 'Withdrawal ID',
  Employee_ID: "Employee ID",
  Withdrawal_Amount: 'Withdrawal Amount',
  Withdrawal_Units: 'Withdrawn Units',
  Create_New_Withdrawal_Requests: 'Create New Withdrawal Request',
  create: 'Create',
  Available_Withdrawal_Amount: 'Available Withdrawal Amount',
  //Reallocate 
  Reallocate_Funds: 'Reallocate Portfolios',
  Expected_Yield: 'Expected Yield',
  Fund_Allocation_Detail: 'Portfolio Allocation Detail',
  Recomended: 'Recomended',
  Reallocation_ID: 'Reallocation ID',
  Employee_Name: 'Employee Name',
  Employer_Name: 'Employer Name',
  Date_of_Request: 'Date of Request',
  To_Fund: 'To Portfolio',
  Confirmation_Date: 'Confirmation Date',
  allNoti: 'All Notifications',
  Risk_Appetite_Survey: 'Risk Appetite Survey',
  next: 'Next',
  previous: 'Previous',
  settings: 'Settings',
  ques: 'Question',
  Risk_Appetite_Survey_Results: ' Risk Appetite Survey Results',
  Risk_Profiles: 'Risk Profiles',
  Public_Equity: 'Public Equity',
  Fixed_Income_Investment: 'Fixed Income Investment',
  Money_Markeet: 'Money Market',
  Real_Estate: 'Real Estate',
  Low_Risk: 'Low Risk',
  Investment: 'Investment',
  Risk: 'Risk',
  back: 'Back',
  //return caculator
  Return_Calculator: 'Return Calculator',
  Amount: 'Employee Amount',
  Amounts: 'Amount',
  Employer_Amount: 'Employer Amount',
  Duration: 'Duration',
  Return_Calculator_Graph: 'Return Calculator Graph',
  submit: 'Submit',
  Cancel: 'Cancel',

  //my profile
  path: 'Home > My Profile',
  myprofile: 'My Profile',
  updateprofile: 'Update Profile',
  name: 'Name',
  email: 'Email ID',
  contact: 'Contact Number',
  iqama_id: 'Iqama ID',
  national_id: 'National ID',
  set_password: 'New Password',
  confirm_password: 'Confirm New Password',
  update_password: 'Change Password',
  btn_text: 'Update Profile',
  paraA: 'Based on your risk tolerance your savings are invested in a combination of Money Market , highly liquid short term investments like US Treasury Bills and commercial papers Fixed Income Investment long term debt issues such as bonds and Equity investment ownership stakes in companies',
  paraBA: 'Your risk tolerance is the level of volatility in your returns you are happy to accept, higher volatility means higher chance of returns, but also higher chance of losses. Your current level is',
  paraBB: ', you can change this by retaking the risk tolerance survey to let us help youdetermine which level of risk is right for you.',
  alertA: 'Are You Sure You Select This Fund ?',
  yes: 'Yes',
  no: 'No',
  learn_more: 'Learn More',
  emp_contri: 'Total Employer <br>Contribution',
  total_portFolio: 'Total <br>Portfolio',
  currunt_year: 'Current Year',
  default: 'Default',
  no_record_found: 'No Record Found',
  Vestedd: 'Vested',

  //View Contribution
  view_contribution: 'View Contributions',
  Employee_contribution_amount: 'Employee Contribution Amount',
  Employer_contribution_amount: 'Employer Contribution Amount',
  by_status: 'By Status',
  logs: 'Logs',
  all_logs: 'All Logs',
  loading: 'Loading...',

  //status in reallocated fund,manage_withdrawls,manage-contribution

  accepted: 'Accepted',
  completed: 'Completed',
  waiting: 'Waiting For Bank Approval',
  pending: 'Pending',
  declined: 'Declined',
  rejected: 'Rejected',
  Under_Process: 'Under Process',
  declinedAdmin: 'Declined By Admin',
  declinedEmployer: 'Declined By Employer',
  declinedBank: 'Declined By Bank',
  cancel: 'Cancelled',
  // new arabic issues 
  MatchingRule: 'Matching Rule',
  employeePoratl: 'Employee Portal',
  UsernamePassword: 'Incorrect username or password entered.Please check and try again.',
  AuthenticationFailure: 'Authentication Failure',
  VerificationFailure: 'Verification Failure',
  error: 'Error',
  okay: 'Okay',
  serverError: 'Server Error',
  RecommandedRisk: 'Your recomended Risk profile is',

  high_risk: 'High Risk',
  medium_risk: 'Medium Risk',
  low_risk: 'Low Risk',

  success: 'Success',
  passwordUpdated: 'Password has been updated successfully',
  profileUpdated: 'Profile has been updated successfully',


  PortfolioBreakdown: 'Portfolio Breakdown',
  VestingBreakdown: 'Vesting Breakdown',
  Contributionbreakdownbyrisk: 'Contribution breakdown by risk',
  Passwordandconfirm: 'Password and confirm password must be same',
  AccumulativeReturn: 'Accumulative Return',
  monthlyContribution: 'Monthly Contribution %',
  month: 'Month',
  calcutaorend: 'That the calculations are based on an assumption of 5% annual increase in salary and a 3 year straight line vesting schedule for contributions',
  balance: 'Your balance in insufficient',
  pendingRequest: 'Your request have already in pending state',
  fundSelect: 'Fund Selected Successfully',
  fundRequst: 'Your request has been generated.Once approved, you will be notified',
  allocatedFund: 'You have already generated a reallocation fund request',
  allocatedFundNew: 'You can not generate a reallocation request as you have a pending contribution request in your account',
  actvenoti: 'Activity-Notifications',
  Activity_Notification: 'Activity Notification',
  All_Activity_Notification: 'All Activity Notification',
  SAR: 'SAR',
  Payoff: 'Payoff',
  From_Portfolio: 'From Portfolio',
  To_Portfolio: 'To Portfolio',
  help_support: 'Help & Support',
  Faqs: 'FAQs',
  Contact: 'Contact Us',
  follow: 'Follow Us',
  HowCanWeHelp: 'How can we help you?',
  GeneralInquiry: 'General Inquiry​',
  ReportanIssue: 'Report an Issue',
  Suggestion: 'Suggestion',
  Complaint: 'Complaint',
  send: 'Send',
  TypeMessage: 'Type Message…',
  SelectOption: 'Select Option',
  messageHelp: 'Thank you for reaching out to us. We will get back to you soon.',
  q1: 'How long does it take for the contribution status to be accepted?',
  q2: 'What is the vested and unvested amount?',
  q3: 'How can I withdraw my amount?',
  ans1: 'When the employer makes a contribution, the amount is sent to the bank. The bank allocates the amount to the right fund. Each bank has different policies but normally the application is processed within 5 working days.',
  ans2: 'The Unvested amount is the amount of money that has been promised to you by the company but the company has not yet processed it. Vested amount of money is the money that rightfully belongs to the Employee and which he can withdraw at any time.',
  ans3: 'The request for withdrawing money requires your employer’s clearance. Once the company processes the request the amount will be transferred to your account from your employer, depending on their policies.',
  systemError: 'System does not support this file format.',
  wallet: 'Portfolio',
  enterAmount: 'Enter amount',
  enterWithdrawal: 'Enter amount to withdraw',
  yourAmount: 'Total amount',
  submitrequest: 'Submit Request',
  amountwithdrawn: 'You cannot request to withdraw more than the total available amount',
  withsrawnSuccess: 'Your withdrawal request has been sent to employer for approval',
  AmountReq: 'Withdrawn amount is required and should be greater than 0.',
  InternetConnection: 'No Internet Connection',
  ExpectedReturn: 'Expected Return',
  withdrawalCancelled: 'Withdrawal request has been cancelled',
  confirmPasswordValidation: 'Password and confirm password are not matched',
  alert: 'Alert',
  "Date":"Date",
  "mins":"mins",
  "hrs":"hrs"
};
export const arabic = {
  "Date":"تاريخ",
  "hrs":"ساعات",
  alert: 'انذار',
  "mins":"دقيقة",
  confirmPasswordValidation: 'كلمة المرور وتأكيد كلمة المرور غير متطابقين',
  withdrawalCancelled: 'تم إلغاء طلب السحب',
  ExpectedReturn: 'العائد المتوقع',
  AmountReq: 'يجب تحديد المبلغ المسحوب ويجب أن يكون أكبر من 0',
  InternetConnection: 'لا يوجد اتصال مع شبكة الانترنت',
  withsrawnSuccess: 'تم ارسال طلبك بنجاح',
  amountwithdrawn: 'لا يمكن طلب سحب اكثر من مجموع المبلغ المتوفر',
  submitrequest: 'قدم الطلب ',
  yourAmount: 'المبلغ الاجمالي',
  enterWithdrawal: 'ادخل المبلغ للسحب',
  enterAmount: 'ادخل المبلغ',
  wallet: 'المحفظه',
  systemError: 'النظام لا يدعم تنسيق الملف هذا.',
  q1: 'ما هي المدة التي يستغرقها قبول حالة المساهمة؟ ',
  q2: 'ما هو المبلغ المكتسب وغير المكتسب؟ ',
  q3: 'كيف يمكنني سحب المبلغ الخاص بي؟ ',
  ans1: 'عندما يقدم صاحب العمل المساهمات، يتم إرسال مبلغ المساهمة إلى البنك. ويخصص البنك المبلغ للصندوق الصحيح. لكل بنك سياسات مختلفة، ولكن عادةً ما تتم معالجة الطلب في غضون 5 أيام عمل',
  ans2: 'المبلغ غير المكتسب هو مبلغ المال الذي وعدتك به الشركة، لكن الشركة لم تقم بتقديمه بعد. المبلغ المكتسب من المال هو المال المملوك للموظف من الشركة والذي يمكن سحبه في أي وقت',
  ans3: 'يتطلب طلب سحب الأموال موافقة من صاحب العمل. بمجرد قيام الشركة بمعالجة الطلب، سيتم تحويل المبلغ إلى حسابك من صاحب العمل ، اعتمادًا على سياساتهم',
  GeneralInquiry: 'استفسار عام​',
  ReportanIssue: 'ابلغ عن مشكله',
  Suggestion: 'اقتراح',
  Complaint: 'شكوى​',
  send: 'ارسال',
  TypeMessage: 'اكتب رسالة',
  SelectOption: 'اختر من القائمة',
  messageHelp: 'شكر لتواصلك معنا. سوف نعود اليك قريبا.',
  HowCanWeHelp: 'كيف يمكننا مساعدتك؟',
  myp: 'محفظتي',
  Contact: 'تواصل معنا',
  follow: 'تابعنا',
  Faqs: 'الأسئلة الشائعة',
  help_support: 'المساعده والدعم',
  From_Portfolio: 'من المحفظة',
  To_Portfolio: 'إلى المحفظة',
  SAR: 'ريال',
  AuthenticationFailure: 'فشل المصادقة',
  Activity_Notification: 'إخطار النشاط',
  All_Activity_Notification: 'كل إخطار النشاط',

  actvenoti: 'إخطارات النشاط',
  balance: 'ليس لديك رصيد كاف',
  pendingRequest: 'لديك بالفعل طلب سحب معلق',
  fundSelect: 'تم اختيار الصندوق بنجاح',
  fundRequst: 'تم إنشاء طلبك ، وسيتم إخطارك بمجرد الموافقة عليه',
  allocatedFund: 'لقد قمت بالفعل بإنشاء طلب صندوق إعادة تخصيص',
  allocatedFundNew: 'لا يمكنك إنشاء طلب إعادة تخصيص لأن لديك طلب مساهمة معلق في حسابك',


  month: 'شهر',
  calcutaorend: 'أن الحسابات تستند إلى افتراض زيادة سنوية بنسبة 5٪ في الراتب وجدول استحقاق ثابت للاشتراكات لمدة 3 سنوات',
  PortfolioBreakdown: 'تفاصيل المحفظة',
  VestingBreakdown: 'تفاصيل المستحقات المالية',
  Contributionbreakdownbyrisk: ' تفصيل المساهمه حسب المخاطر',
  Passwordandconfirm: 'كلمة المرور غير متطابقه',
  AccumulativeReturn: 'العائد التراكمي',
  monthlyContribution: 'المساهمة الشهرية',

  profileUpdated: 'تم تحديث الملف الشخصي بنجاح',
  passwordUpdated: 'تم تحديث كلمة السر بنجاح',
  success: 'النجاح',

  high_risk: "مخاطر عالية",
  Medium_risk: "مخاطر متوسطة",
  medium_risk: "مخاطر متوسطة",
  low_risk: "مخاطر منخفضة",
  //auth
  RecommandedRisk: 'المحفظة المرشحة لك هي',
  serverError: 'حدث خطأ داخلي في الخادم',
  UsernamePassword: 'اسم المستخدم أو كلمة المرور المدخلة غير صحيحة',
  error: 'خطأ',
  okay: 'حاول مجدداً',
  employeePoratl: 'بوابة الموظف',
  sign_in: 'تسجيل الدخول',
  admin: 'الموظف',
  portal: 'بوابة ',
  welcome: 'مرحبا بك في',
  thrift: 'ثرفت ',
  plan: 'بلان',
  secure: 'هذا الموقع آمن',
  request: 'من فضلك أدخل إسم المستخدم و كلمة المرور الخاصة بك للتسجيل',
  username: 'اسم المستخدم',
  password: 'كلمه المرور',
  forgot: 'هل نسيت كلمة المرور',
  send_otp: 'تأكيد OTP',
  otp_pl: 'أدخل كود 6 أرقام',
  otp_req: 'الرجاء إدخال الرمز المكون من6 أرقام هنا لتسجيل الدخول',
  forgot_password: 'نسيت كلمة المرور',
  emailforget: 'الرجاء ادخال عنوان البريد الإلكتروني او رقم الهاتف الخاص بك لاستعادة كلمة المرو',
  email_req: 'الرجاء ادخال عنوان البريد الإلكتروني او رقم الهاتف الخاص بك',
  reqover_req: ' لاستعادة كلمة المرو',
  email_address: 'أدخل عنوان البريد الإلكتروني أو رقم الهاتف',
  reqover_password: 'استعادة كلمة المرور',
  rec_req: 'الرجاء إدخال الرمز المكون من 6 أرقام المرسل',
  rec_email: 'إلى عنوان بريدك الإلكتروني.',
  confirm_otp: 'تأكيد',
  creat_new: 'إنشاء كلمة مرور جديدة',
  req: 'الرجاء إدخال كلمة المرور الجديدة.',
  rec_password: 'أدخل كلمة مرور جديدة',
  con_password: 'تأكيد كلمة المرور الجديدة',
  done: 'تم',

  //dashboard
  thrift_hed: 'thrift',
  plan_hed: 'plan',
  home: 'الرئيسية',
  profile: 'ملفي',
  mp: 'ادارة المحفظة',
  mr: 'إدارة عمليات الاسترداد',
  mw: 'إدارة عمليات السحب',
  mi: 'إدارة الاستثمارات',
  risk: 'استبيان مدى تقبل المخاطر',
  mc: 'إدارة المساهمة',
  reports: 'التقارير',
  noti: 'الإشعارات',
  Log_Out: 'تسجيل الخروج',
  search: 'بحث',
  iqama_national_ID_Scaned_Copy: 'الإقامة / نسخة من بطاقة الهوية الوطنية',
  sbs: 'فرز حسب الحالة',
  sbo: "فرز حسب المالك",
  view: 'مشاهدة الكل',
  low: 'منخفضة',
  medium: 'متوسطة',
  high: 'مرتفعة',
  //detail profile
  My_detail_information: 'معلوماتي التفصيلية',
  Employee_Basic_Details: 'التفاصيل الأساسية للموظف',
  Next_of_Kin_Information: 'أقرب الأقرباء',
  Plan_Specific_details: 'تفاصيل الخطة المحددة',
  Tax_Residency_Information: 'معلومات الإقامة الضريبية',
  Name: 'اسم',
  Phone: 'هاتف',
  Email: 'بريد إلكتروني',
  Gender: 'جنس',
  Date_of_Birth: 'تاريخ الولادة',
  Address: 'عنوان',
  Iqama_Id_National_Id: 'رقم الإقامة / الرقم القومي',
  Iqama_National_ID_expiry: 'انتهاء صلاحية الإقامة / الهوية الوطنية',
  Are_You_Dual_Nationality_Holder: 'هل أنت مزدوج الجنسية؟',
  Nationality: 'جنسية',
  Employee_Since: 'موظف منذ',
  Position: 'موضع',
  Salary: 'مرتب',
  COMMENT: 'تعليق',

  RelationshiptoNextofKin: 'العلاقة بأقرب الأقارب',
  FirstName: 'الاسم الأول',
  LastName: 'الكنية',
  Contactdetails: 'بيانات المتصل',
  DoesanyoneholdaPowerofattorneyforyou: 'هل يمتلك أي شخص توكيل رسمي نيابة عنك؟',
  MonthlyContributionAmount: 'مبلغ المساهمة الشهرية',
  Matching: 'مطابقة',
  Matching_Rule: 'قاعدة المطابقة',

  Tax: 'ضريبة',
  TaxSSNNo: 'ضريبة SSN لا',
  TaxITINNo: 'ضريبة ITIN لا',

  //manage portfolio
  Profit: 'الربح/الخسارة',
  ProfitTotal: 'الربح/الخسارة',
  Risk_type: 'مستوى المخاطرة',
  Total_Balance: 'اجمالي الميزانية',
  Total_Contributions: 'اجمالي المساهمة ',
  Employer_Contribution: 'مساهمة صاحب العمل',
  My_Contribution: 'مساهمتي',
  Total_Withdrawan_Amount: 'أجمالي المبالغ المسحوبة',
  Available_for_Withdrawal: 'متاح للسحب',
  Available_units: 'الوحدات المتاحة',
  total_units: 'العدد الإجمالي للوحدات',
  Fund_Name: 'اسم المحفظة',
  Investment_Bank: 'اسم البنك المستثمر',
  Invested_Amount: 'اجمالي الاستثمار',
  Liquidity_Type: 'نوع السيولة (التمويل)',
  Average_Return: 'متوسط العائد',
  Vested: 'مكتسب',
  Vested_return: 'عودة مكتسبة',
  Unvested: 'غير مكتسب',
  Employee_Contribution_Detail: 'تفاصيل مساهمة الموظف',
  Total_Employee_Contribution: 'إجمالي مساهمة الموظف',
  Employer_Contribution_Detail: 'تفاصيل مساهمة صاحب العمل',
  Yearly_Profit_Report: 'تقرير الربح السنوي',
  sby: 'الترتيب حسب السنة',
  sbm: 'فرز حسب الشهر',


  //manage contributions
  Manage_Contributions: 'إدارة المساهمة',
  Contribution_ID: 'الرقم التسلسلي',
  CSV_ID: 'رقم الملف',
  Total_Contribution: 'اجمالي المساهمة',
  Created_At: 'أنشئت في',
  Status: 'الحالة',
  Action: 'الإجراءات',
  //manage Withdrawals
  Manage_Withdrawal: 'إدارة عمليات السحب',
  Withdrawal_ID: 'الرقم التسلسلي',
  Employee_ID: "الرقم التعريفي للموظف",
  Withdrawal_Amount: 'اجمالي السحب',
  Withdrawal_Units: 'وحدات السحب',
  Available_Withdrawal_Amount: 'مبلغ السحب المتاح',
  Payoff: 'العائد',

  Create_New_Withdrawal_Requests: 'طلب سحب جديد',
  create: 'إضافة',
  //Reallocate 
  Reallocate_Funds: 'إعادة تخصيص المحافظ',
  Expected_Yield: 'العائد المتوقع',
  Fund_Allocation_Detail: 'تفاصيل تخصيص المحفظة',
  Recomended: 'موصى به',
  Reallocation_ID: 'الرقم التسلسلي',
  Employee_Name: 'اسم الموظف',
  Employer_Name: 'اسم صاحب العمل',
  Date_of_Request: 'تاريخ الطلب',
  To_Fund: 'لمحفظة',
  Confirmation_Date: 'تاريخ تأكيدا',
  allNoti: 'كل الإشعارات',
  Risk_Appetite_Survey: 'استبانة نسبة المخاطرة',
  next: 'التالي',
  previous: 'السابق',
  settings: 'إعدادات',
  ques: 'سؤال',

  Risk_Appetite_Survey_Results: "نتائج استبيان مدى تقبل المخاطر",
  Risk_Profiles: "ملفات تعريف المخاطر",
  Public_Equity: 'حقوق الملكية العامة',
  Fixed_Income_Investment: 'استثمار الدخل الثابت',
  Money_Markeet: 'سوق المال',
  Real_Estate: 'العقارات',
  Investment: 'استثمار',
  Risk: 'مخاطرة',
  back: 'رجوع',
  //return caculator
  Return_Calculator: 'العائد المحسوب',
  Amount: 'الكمية',
  Amounts: 'الكمية',
  Employer_Amount: 'صاحب العمل',
  Duration: 'مدة',
  Return_Calculator_Graph: 'إرجاع الرسم البياني للحاسبة',
  submit: 'ارسل',
  Cancel: 'إلغاء',
  cancel: 'إلغاء',
  //my profile
  path: 'الصفحة الرئيسية > ملفي الشخصي',
  myprofile: 'ملفي',
  updateprofile: 'ملفي',
  name: 'اسم',
  email: 'البريد الإلكتروني',
  contact: 'رقم الاتصال',
  iqama_id: 'معرف الإقامة',
  national_id: 'الهوية الوطنية',
  set_password: 'أنشئ كلمة مرور',
  confirm_password: 'تأكيد كلمة المرور',
  update_password: 'تطوير كلمة السر',
  btn_text: 'تحديث الملف',
  paraA: 'بناءً على قدرتك على تحمل المخاطر ، يتم استثمار مدخراتك في مزيج من أسواق المال والاستثمارات قصيرة الأجل عالية السيولة مثل سندات الخزانة الأمريكية والأوراق التجارية ؛ استثمار الدخل الثابت ، إصدارات الديون طويلة الأجل مثل السندات ؛ والاستثمار في الأسهم وحصص الملكية في الشركات.',
  paraBA: 'تحملك للمخاطرة هو مستوى التقلب في عائداتك الذي يسعدك قبوله ، التقلب العالي يعني فرصة أكبر للعوائد ، ولكن أيضًا فرصة أكبر للخسارة. مستواك الحالي هو',
  paraBB: 'يمكنك تغيير هذا من خلال إعادة إجراء استطلاع لتحمل المخاطر للسماح لنا بمساعدتك في تحديد المستوى المناسب للمخاطر بالنسبة لك.',
  alertA: 'هل أنت متأكد من تحديد هذا الصندوق؟',
  yes: 'نعم',
  no: 'لا',
  learn_more: 'تعلم أكثر',
  emp_contri: 'إجمالي مساهمة صاحب العمل',
  total_portFolio: 'إجمالي المحفظة',
  Employee_Contribution: 'مساهمة الموظف',
  currunt_year: 'السنة الحالية',
  default: 'الإفتراضي',
  no_record_found: 'لم يتم العثور على أي سجل',
  //View Contribution
  view_contribution: 'عرض المساهمات',
  Employee_contribution_amount: "مبلغ مساهمة الموظف",
  Employer_contribution_amount: "مبلغ مساهمة صاحب العمل",
  Vested_amount: "المبلغ المكتسب",
  Unvested_amount: "مبلغ غير مكتسب",
  Years: 'سنوات',
  by_status: 'حسب الحالة',
  logs: 'السجلات',
  all_logs: 'كل السجلات',
  loading: 'تحميل...',

  //status in reallocated fund

  accepted: 'وافقت',
  completed: "منجز",
  waiting: 'في انتظار موافقة البنك',
  pending: 'قيد الانتظار',
  declined: 'رفض',
  rejected: 'مرفوض',
  declinedAdmin: 'رفضه المسؤول',
  declinedEmployer: 'رفضه صاحب العمل',
  declinedBank: 'مرفوض من قبل البنك',
  Under_Process: 'تحت العملية',


  // new arabic issues 
  MatchingRule: 'قاعدة المطابقة'
};



