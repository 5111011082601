import { Component, OnInit } from "@angular/core";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import * as CryptoJS from "crypto-js";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { AlertService } from "src/app/services/alert.service";
@Component({
    selector: "app-withdrawls-form",
    templateUrl: "./withdrawls-form.component.html",
    styleUrls: ["./withdrawls-form.component.scss"],
})
export class WithdrawlsFormComponent implements OnInit
{
    Form: FormGroup;
    setPassword: FormGroup;
    failureAlert: boolean;
    alertMsg: any = "";
    sub: Subscription;
    id: any;
    path: any;
    hed: any;
    button: any;
    ut;
    list: any = [];
    available_units: any;
    weighted_nav_unit: any;
    navUnitAmount: number = 0;
    numRegex = /^-?\d*[.,]?\d{0,5}$/;
    amount: number = 0;
    editModes = {
        amount: false,
    }
    constructor(
        public tr: TranslationService,
        protected formbuilder: FormBuilder,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        public alertService: AlertService,
        protected mainService: ApiService,
        public dec_enc: DecEncService
    )
    {
        this.Form = this.formbuilder.group({
            amount: [
                "",
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.pattern(this.numRegex),
                ],
            ],
            active: ["1", [Validators.required]],
            available_amount: ["0"],
        });
    }

    ngOnInit(): void
    {
        this.sub = this._route.params.subscribe((params) =>
        {
            this.id = params["id"];
        });
        this.getall();
    }

    getValue(name)
    {
        return this.Form.get(name);
    }

    getFieldValue(field)
    {
        if (this.Form.get(field).touched) {
            if (this.Form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    submit()
    {
        this.Form.get("available_amount").setValue(this.available_units);
        let url;
        let enc_payload = this.dec_enc.encryption(this.Form.value);
        let message = "Withdrawls request has been created successfully";
        if (this.id) {
            url = "withdrawals/update";
            message = "Update Withdrawls Successfully";
        } else {
            url = "withdrawals/create";
        }
        if (+this.Form.get("amount").value > +this.available_units) {
            this.alertService.alert(
                "Error",
                "You cannot request to withdraw more than the total available amount"
            );
        } else {
            this.mainService
                .postData(url, { enc_payload })
                .then((res) =>
                {
                    if (res.statusCode == 200) {
                        this.alertService.alert(
                            "Success",
                            "Your withdrawal request has been sent to Company for approval"
                        );
                        this.getall();
                        return;
                    }
                    this.alertService.alertAr("Error", res);
                })
                .catch((error) =>
                {
                    this.alertService.alert("Error", this.tr.translation.serverError);
                });
        }
    }

    getall()
    {
        let url = "home/portfolio";
        this.mainService.postData(url, {}).then(
            (res) =>
            {
                if (res.statusCode == 200) {
                    let result = this.dec_enc.decryption(res.data);
                    this.weighted_nav_unit = result.employees_stats?.weighted_nav_unit;
                    if (result.employees_stats?.available_withdrawal_amount) {
                        this.available_units = Number(
                            result.employees_stats?.available_withdrawal_amount
                        );
                        if (result.employees_stats?.available_withdrawal_amount < 0) {
                            this.available_units = 0;
                        } else {
                            this.available_units =
                                result.employees_stats?.available_withdrawal_amount;
                        }
                    } else {
                        this.available_units = 0;
                    }
                }
            },
            (error) =>
            {
                this.alertService.alert("Error", "Server Error");
            }
        );
    }

    back()
    {
        window.history.back();
    }

    reset()
    {
        this.Form.reset();
    }

    checkValidations()
    {
        if (this.Form.invalid) {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    restrictDecimalPlaces(event: any)
    {
        const value = event.target.value;
        const regex = /^\d+(\.\d{0,5})?$/;
        if (!regex.test(value)) {
            event.target.value = this.truncateDecimal(value, 5);
        }
    }

    truncateDecimal(value: string, decimalPlaces: number): string
    {
        const stringValue = value.toString();
        const decimalIndex = stringValue.indexOf('.');
        if (decimalIndex !== -1) {
            const truncatedValue = stringValue.substring(0, decimalIndex + decimalPlaces + 1);
            return truncatedValue;
        }
        return stringValue;
    }

    setMode(control, mode) {
        if (!this.amount)
        {
            this.amount = 0;
        }
        this.editModes[control] = mode;
    }

    getErrorMessage(control, fieldName, minVal?, maxVal?)
    {
        if (this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError("required")) {
                return `${fieldName} is a required field.`;
            }
            if (this.Form.get(control).hasError("minlength")) {
                return `Minimum length for ${fieldName} is ${minVal} characters.`;
            }
            if (this.Form.get(control).hasError("maxlength")) {
                return `Maximum length for ${fieldName} is ${maxVal} characters.`;
            }
            if (this.Form.get(control).hasError("email")) {
                return `${fieldName} should be in valid email format.`;
            }
            if (control == "name" && this.Form.get(control).hasError("pattern")) {
                return `${fieldName} should be letters only.`;
            }
            if (control == "phone" && this.Form.get(control).hasError("pattern")) {
                return `${fieldName} should be numbers only.`;
            }
            if (control == "amount" && this.Form.get(control).hasError("pattern")) {
                return `${fieldName} should be numbers only and start from 1.`;
            }
        }
    }
}
