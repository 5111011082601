import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ProfileService } from "src/app/services/profile.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  role: string = "";

  constructor(
    private router: Router,
    protected profileService: ProfileService,
    protected apiService: ApiService,
    protected cryptoService: DecEncService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let roles = route.data["roles"] as Array<string>;
    return new Promise((res) => {
      if (this.role) {
        if (roles.includes(this.role) || roles.includes("all")) {
          res(true);
        } else {
          this.router.navigate(["/main"]);
          res(false);
        }
      } else {
        this.apiService.postData("viewProfile", {}).then((response) => {
          if (response.statusCode == 200) {
            let decryptedResponse = this.cryptoService.decryption(
              response.data
            );
            this.profileService.user = decryptedResponse.user;
            this.role = decryptedResponse?.user?.role;
            if (this.role) {
              if (roles.includes(this.role) || roles.includes("all")) {
                res(true);
              } else {
                this.router.navigate(["/main"]);
                res(false);
              }
            }
          }
        });
      }
    });
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  // {

  //     let terminations = route.data["terminations"] as Array<string>;

  //     this.termination = localStorage.getItem('termination_status');
  //     if (this.termination != null)
  //     {

  //         if(terminations[1] == '1' && this.termination == 1)
  //         {
  //             return true;
  //         }
  //         if(terminations[0] == 'all' && this.termination != 1)
  //         {
  //             return true;
  //         }
  //         else
  //         {
  //             this.router.navigate(['/main']);
  //             return false;
  //         }
  //     }

  // }
}
