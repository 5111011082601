import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

export class AlertData {
  heading: string = "";
  message: string = "";
  hasInput: boolean = false;
  hasArabic: boolean = false;
  type: "ask" | "success" | "error" | "info";
  leftButton: {
    text: string;
    class: string;
  };
  rightButton: {
    text: string;
    class: string;
  };
}

@Component({
  selector: "alert-dialog",
  templateUrl: "./alert.dialog.new.html",
})
export class AlertDialogNew implements OnInit {
  isLoading: boolean;
  hasInput: boolean = false;
  hasArabic: boolean = false;
  reasonTExt = "";
  dataToSubmit: any;
  methodName: any;
  showLoading: boolean;
  alertData: AlertData;

  constructor(
    protected dialogRef: MatDialogRef<AlertDialogNew>,
    protected dialog: MatDialog
  ) {
    this.isLoading = false;
    this.showLoading = false;
    this.hasInput = true;
    this.alertData = {
      heading: "Data",
      message: "",
      type: "ask",
      hasInput: false,
      hasArabic: false,
      leftButton: {
        text: "No",
        class: "Yes",
      },
      rightButton: {
        text: "No",
        class: "Yes",
      },
    };
  }

  ngOnInit() {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onSubmitClick(): void {
    this.isLoading = true;
    this.dialogRef.close(true);
  }
}
