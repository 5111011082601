import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from "src/app/services/alert.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  LOGIN_KEY = "login_status";
  Form: FormGroup;
  constructor(
    protected authService: AuthService,
    protected route: Router,
    protected alertService: AlertService
  ) {
    this.Form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {}

  sendOTP() {
    if (!this.checkValidations()) {
      return;
    }
    let url = `forgot`;
    let payload = {
      username: this.Form.get("email").value,
      portalID: "employeePortal",
    };
    let data = {
      enc_payload: this.authService.encryptionAuth(payload),
      enc_password: localStorage.getItem("password_token"),
    };
    localStorage.setItem("forgot_data", JSON.stringify(data));
    this.authService.auth(url, data).then((response) => {
      if (response?.statusCode == 200) {
        localStorage.setItem(this.LOGIN_KEY, response.data);
        this.route.navigateByUrl("auth/reset-password");
      } else {
        this.alertService.alertAr("Error", response);
      }
    });
  }

  getFieldValue(field) {
    if (this.Form.get(field).touched) {
      if (this.Form.get(field).invalid) {
        return true;
      }
    }
    return false;
  }

  getErrorMessage(control, fieldName, minMaxValue?) {
    if (this.Form.get(control).invalid) {
      if (this.Form.get(control).hasError("required")) {
        return `${fieldName} is a required field.`;
      }
      if (this.Form.get(control).hasError("email")) {
        return `${fieldName} is in incorrect format. It should be in valid email format.`;
      }
    }
  }

  checkValidations() {
    if (this.Form.invalid) {
      this.Form.markAllAsTouched();
      return false;
    }
    return true;
  }
}
