import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { ProfileService } from 'src/app/services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanActivate {
  constructor(private router: Router,
    protected profileService: ProfileService,
    protected apiService: ApiService,
    protected cryptoService: DecEncService) {

  }

  // canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
  //   return new Promise(res => {
  //     this.apiService.postData('viewProfile', {}).then(response => {
  //       if (response.statusCode == 200) {
  //         let decryptedResponse = this.cryptoService.decryption(response.data);
  //         let user = decryptedResponse?.user;
  //         if ((user.terms_status == '0' || user.terms_status == void 0)) {
  //           res(true);
  //         }
  //         else {
  //           this.router.navigate(['/main']);
  //           res(false);
  //         }
  //       }
  //       else {
  //         this.router.navigate(['/auth/login']);
  //         res(false);
  //       }
  //     })
  //   })
  // }

  canActivate() {
    let terms_status = localStorage.getItem('terms_status');
    let token = localStorage.getItem('employee_token');
    if ((terms_status == '0' || terms_status == void 0) && token) {
      return true;
    }
    else {
      if (token == void 0) 
      {
        this.router.navigate(['/auth/login']);
      }
      else
      {
        this.router.navigate(['/main']);
      }
      return false;
    }
  }
}

