<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <mat-horizontal-stepper
    [selectedIndex]="currentIndexOfStepper"
    matStepperIcon="number"
    (selectionChange)="onStepChange($event)"
    linear
    #stepper
  >
    <mat-step [stepControl]="Form" [completed]="false">
      <form [formGroup]="Form">
        <ng-template matStepLabel>{{
          "Employee_Basic_Details" | translate
        }}</ng-template>
        <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              formControlName="name"
              readonly
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'name')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(Form, 'name', 'Name')"
              [matTooltipDisabled]="!getFieldValue(Form, 'name')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Phone" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="phone"
            />
            <span matPrefix>+966</span>
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'phone')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(Form, 'phone', 'Phone', 9, 9)"
              [matTooltipDisabled]="!getFieldValue(Form, 'phone')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "email" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="email"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'email')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(Form, 'email', 'Email')"
              [matTooltipDisabled]="!getFieldValue(Form, 'email')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            class="input-field mt-10"
            fxFlex="24"
            fxFlex.sm="46"
            fxFlex.xs="100"
            appearance="outline"
          >
            <mat-label>{{ "Gender" | translate }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="1">{{ "Male" | translate }}</mat-option>
              <mat-option value="2">{{ "Female" | translate }}</mat-option>
              <mat-option value="0">{{ "Other" | translate }}</mat-option>
            </mat-select>
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'gender')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(Form, 'gender', 'Gender')"
              [matTooltipDisabled]="!getFieldValue(Form, 'gender')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="23.3"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Date_of_Birth" | translate }}</mat-label>
            <input
              matInput
              type="text"
              readonly
              formControlName="date_of_birth"
              [matDatepicker]="picker1"
              [max]="currentDate"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'date_of_birth')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(Form, 'date_of_birth', 'Date of Birth')
              "
              [matTooltipDisabled]="!getFieldValue(Form, 'date_of_birth')"
              matTooltipPosition="above"
              matPrefix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="97"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Address" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="address"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'address')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(Form, 'address', 'Address')"
              [matTooltipDisabled]="!getFieldValue(Form, 'address')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Iqama_Id_National_Id" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="iqama_id"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'iqama_id')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(Form, 'iqama_id', 'National/Iqama ID', 0, 10)
              "
              [matTooltipDisabled]="!getFieldValue(Form, 'iqama_id')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Iqama_National_ID_expiry" | translate }}</mat-label>
            <input
              matInput
              type="text"
              readonly
              formControlName="iqama_expiry"
              [matDatepicker]="picker2"
              [min]="currentDate"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(Form, 'iqama_expiry')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(
                  Form,
                  'iqama_expiry',
                  'National/Iqama ID Expiry'
                )
              "
              [matTooltipDisabled]="!getFieldValue(Form, 'iqama_expiry')"
              matTooltipPosition="above"
              matPrefix
              >info
            </mat-icon>
          </mat-form-field>

          <div fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97">
            <div
              class="iqama-id mt-20"
              fxLayout="row wrap"
              style="min-height: 162px; border-radius: 20px"
              ngStyle.xs="min-height:280px;"
              fxLayoutAlign="space-between"
            >
              <div
                class="image-holder"
                fxFlex="47"
                fxFlex.xs="100"
                fxFlex.sm="100"
              >
                <button mat-button class="upload-button">
                  <ng-container *ngIf="!iqama_image_new">
                    <h5>There is no Image</h5>
                  </ng-container>
                  <ng-container *ngIf="iqama_image_new">
                    <img class="upload-image" [src]="iqama_image_new" />
                  </ng-container>
                </button>
              </div>
              <div
                class="button-text"
                ngStyle.sm="margin-top: 20px;"
                ngStyle.xs="margin-top: 20px;"
                fxFlex="47"
                fxFlex.xs="100"
                fxFlex.sm="100"
              >
                <h2>{{ "Iqama/National Id" | translate }}</h2>
              </div>
            </div>
          </div>

          <div fxLayout="row wrap" fxFlex="48" fxFlex.xs="100">
            <mat-form-field
              fxFlex="48"
              fxFlex.xs="100"
              style="width: 100%"
              class="input-field mt-10"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "Nationality" | translate }}</mat-label>
              <input
                autocomplete="off"
                readonly
                type="text"
                matInput
                formControlName="nationality"
              />
              <mat-icon
                class="mat-icon-error"
                *ngIf="getFieldValue(Form, 'nationality')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage(Form, 'nationality', 'Nationality')
                "
                [matTooltipDisabled]="!getFieldValue(Form, 'nationality')"
                matTooltipPosition="above"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>
            <div
              class="radio-button"
              fxLayoutGap="10px"
              fxLayoutAlign="center center"
              fxFlex="52"
              fxFlex.xs="100"
              fxLayout="row"
            >
              <label>{{ "Are_You_Dual_Nationality_Holder" | translate }}</label>
              <mat-radio-group
                fxLayoutGap="16"
                formControlName="are_you_dual_nationality_holder"
              >
                <mat-radio-button style="margin-right: 10px" value="yes">{{
                  "yes" | translate
                }}</mat-radio-button>
                <mat-radio-button value="no">{{
                  "no" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>

            <mat-form-field
              fxFlex="100"
              style="width: 100%"
              class="input-field mt-10"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "Employee_Since" | translate }}</mat-label>
              <input
                matInput
                type="text"
                readonly
                formControlName="employee_since"
                [matDatepicker]="picker3"
                [max]="currentDate"
                readonly
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker3"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
              <mat-icon
                class="mat-icon-error"
                *ngIf="getFieldValue(Form, 'employee_since')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage(Form, 'employee_since', 'Employee Since')
                "
                [matTooltipDisabled]="!getFieldValue(Form, 'employee_since')"
                matTooltipPosition="above"
                matPrefix
                >info
              </mat-icon>
            </mat-form-field>

            <mat-form-field
              fxFlex="100"
              style="width: 100%"
              class="input-field mt-10"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "Position" | translate }}</mat-label>
              <input
                autocomplete="off"
                readonly
                type="text"
                matInput
                formControlName="position"
              />
              <mat-icon
                class="mat-icon-error"
                *ngIf="getFieldValue(Form, 'position')"
                #tooltip="matTooltip"
                [matTooltip]="getErrorMessage(Form, 'position', 'Position')"
                [matTooltipDisabled]="!getFieldValue(Form, 'position')"
                matTooltipPosition="above"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div fxLayoutAlign="end center" fxLayoutGap="10px">
          <button
            (click)="onCancel()"
            style="
              background-color: #e9eaef;
              color: white;
              line-height: 34px;
              padding: 0 16px;
              border-radius: 10px;
            "
            mat-raised-button
          >
            {{ "Cancel" | translate }}
          </button>
          <button (click)="onNext()" class="btn-submit" mat-raised-button>
            {{ "next" | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="kinForm" [completed]="false">
      <form [formGroup]="kinForm">
        <ng-template matStepLabel>{{
          "Next_of_Kin_Information" | translate
        }}</ng-template>

        <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "RelationshiptoNextofKin" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="relation_to_nok"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(kinForm, 'relation_to_nok')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(
                  kinForm,
                  'relation_to_nok',
                  'Relationship to Next of Kin'
                )
              "
              [matTooltipDisabled]="!getFieldValue(kinForm, 'relation_to_nok')"
              matTooltipPosition="above"
              matSuffix
            >
              info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "FirstName" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="first_name"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(kinForm, 'first_name')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(kinForm, 'first_name', 'First Name')
              "
              [matTooltipDisabled]="!getFieldValue(kinForm, 'first_name')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "LastName" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="last_name"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(kinForm, 'last_name')"
              #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(kinForm, 'last_name', 'Last Name')"
              [matTooltipDisabled]="!getFieldValue(kinForm, 'last_name')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="24"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Contactdetails" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="contact_details"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(kinForm, 'contact_details')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(kinForm, 'contact_details', 'Contact Details')
              "
              [matTooltipDisabled]="!getFieldValue(kinForm, 'contact_details')"
              matTooltipPosition="above"
              matSuffix
            >
              info
            </mat-icon>
          </mat-form-field>

          <div
            class="radio-button"
            fxFlex="24"
            fxLayoutGap="10px"
            fxLayoutAlign="none none"
            fxFlex.xs="100"
            fxLayout="column"
          >
            <label
              [class.valid-label]="!kinForm.get('power_of_attorney').invalid"
              [class.invalid-label]="
                kinForm.get('power_of_attorney').touched &&
                kinForm.get('power_of_attorney').invalid
              "
              >{{ "DoesanyoneholdaPowerofattorneyforyou" | translate }}</label
            >
            <mat-radio-group
              fxLayoutGap="16"
              formControlName="power_of_attorney"
            >
              <mat-radio-button style="margin-right: 10px" value="1" disabled>{{
                "yes" | translate
              }}</mat-radio-button>
              <mat-radio-button value="0" disabled>{{
                "no" | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div fxLayoutAlign="end center" fxLayoutGap="10px">
          <button
            (click)="onCancel()"
            style="
              background-color: #e9eaef;
              color: white;
              line-height: 34px;
              padding: 0 16px;
              border-radius: 10px;
            "
            mat-raised-button
          >
            {{ "Cancel" | translate }}
          </button>
          <button (click)="onNext()" class="btn-submit" mat-raised-button>
            {{ "next" | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="planForm" [completed]="false">
      <form [formGroup]="planForm">
        <ng-template matStepLabel>{{
          "Plan_Specific_details" | translate
        }}</ng-template>

        <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "MonthlyContributionAmount" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="monthly_contribution_amount"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(planForm, 'monthly_contribution_amount')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(
                  planForm,
                  'monthly_contribution_amount',
                  'Monthly Contribution Amount'
                )
              "
              [matTooltipDisabled]="
                !getFieldValue(planForm, 'monthly_contribution_amount')
              "
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Matching" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="matching_percent"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(planForm, 'matching_percent')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(planForm, 'matching_percent', 'Matching %')
              "
              [matTooltipDisabled]="
                !getFieldValue(planForm, 'matching_percent')
              "
              matTooltipPosition="above"
              matSuffix
            >
              info
            </mat-icon>
          </mat-form-field>

          <mat-form-field
            fxFlex="48"
            fxFlex.xs="100"
            style="width: 100%"
            class="input-field mt-10"
            fxFlexAlign="center"
            appearance="outline"
          >
            <mat-label>{{ "Matching_Rule" | translate }}</mat-label>
            <input
              autocomplete="off"
              readonly
              type="text"
              matInput
              formControlName="matching_rule"
            />
            <mat-icon
              class="mat-icon-error"
              *ngIf="getFieldValue(planForm, 'matching_rule')"
              #tooltip="matTooltip"
              [matTooltip]="
                getErrorMessage(planForm, 'matching_rule', 'Matching Rule')
              "
              [matTooltipDisabled]="!getFieldValue(planForm, 'matching_rule')"
              matTooltipPosition="above"
              matSuffix
              >info
            </mat-icon>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="end center" fxLayoutGap="10px">
          <button
            (click)="onCancel()"
            style="
              background-color: #e9eaef;
              color: white;
              line-height: 34px;
              padding: 0 16px;
              border-radius: 10px;
            "
            mat-raised-button
          >
            {{ "Cancel" | translate }}
          </button>
          <button (click)="onNext()" class="btn-submit" mat-raised-button>
            {{ "next" | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <!-- <mat-step [stepControl]="taxForm" [completed]="false">
      <form [formGroup]="taxForm">
        <ng-template matStepLabel>{{'Tax_Residency_Information' | translate}}</ng-template>

        <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
          <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
            fxFlexAlign="center" appearance="outline">
            <mat-label>{{ 'Tax' | translate}}</mat-label>
            <input autocomplete="off" readonly type="text" matInput formControlName="tax">
            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax')" #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(taxForm, 'tax', 'Tax')"
              [matTooltipDisabled]="!getFieldValue(taxForm, 'tax')" matTooltipPosition="above" matSuffix>info
            </mat-icon>
          </mat-form-field>

          <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
            fxFlexAlign="center" appearance="outline">
            <mat-label>{{ 'TaxSSNNo' | translate}}</mat-label>
            <input autocomplete="off" readonly type="text" matInput formControlName="tax_ssn_no">
            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax_ssn_no')" #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(taxForm, 'tax_ssn_no', 'Tax SSN No')"
              [matTooltipDisabled]="!getFieldValue(taxForm, 'tax_ssn_no')" matTooltipPosition="above" matSuffix>info
            </mat-icon>
          </mat-form-field>

          <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
            fxFlexAlign="center" appearance="outline">
            <mat-label>{{ 'TaxITINNo' | translate}}</mat-label>
            <input autocomplete="off" readonly type="text" matInput formControlName="tax_itn_no">
            <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax_itn_no')" #tooltip="matTooltip"
              [matTooltip]="getErrorMessage(taxForm, 'tax_itn_no', 'Tax ITIN No')"
              [matTooltipDisabled]="!getFieldValue(taxForm, 'tax_itn_no')" matTooltipPosition="above" matSuffix>info
            </mat-icon>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="end center" fxLayoutGap="10px">
          <button (click)="onCancel()" style="background-color: #E9EAEF; color: white; line-height: 34px; padding: 0 16px; border-radius: 10px;" mat-raised-button>{{'Cancel' | translate}}</button>
      </div>
      </form>
    </mat-step> -->
  </mat-horizontal-stepper>
</div>
