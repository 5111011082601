import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TokenService } from "src/app/lib/token/token.service";
import { AlertService } from "src/app/services/alert.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  LOGIN_KEY = "login_status";
  accessToken: any;
  otp: string;
  showOtpComponent = false;
  showCredentials = true;
  otpTries = 0;
  loginData: any;
  showPassword: boolean = false;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
  };
  isEnglish: string = "ltr";

  Form: FormGroup;
  checked: boolean;

  timer: any;
  timerRunning: boolean = true;
  timerValue: string = "03:00";
  timerOn = true;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected route: Router,
    public authorization: TokenService
  ) {
    this.Form = new FormGroup({
      adminUsername: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      adminPassword: new FormControl(null, Validators.required),
    });
    this.startCall();
    if (localStorage.getItem("lang") == "ar") {
      this.isEnglish = "rtl";
    } else {
      this.isEnglish = "ltr";
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  onOtpChange(otp) {
    this.otp = otp;
    if (otp.toString().length == 6 && this.otpTries > 0) {
      return;
    }
    if (otp.toString().length < 6) {
      this.otpTries = 0;
    }
    if (otp.toString().length == 6 && this.otpTries == 0) {
      let data = {
        otp: otp,
        user: this.authService.decryptionAuth(
          localStorage.getItem(this.LOGIN_KEY)
        ),
      };
      let otpp = this.authService.encryptionAuth(data);
      let data1 = {
        enc_password: localStorage.getItem("password_token"),
        enc_payload: otpp,
      };
      this.validate(data1);
      this.otpTries++;
    }
  }

  navigateToForgot() {
    this.route.navigateByUrl("auth/recovery-password");
  }

  timerOTP(remaining) {
    let m = Math.floor(remaining / 60);
    let s = remaining % 60;

    let min = m < 10 ? '0' + m : m;
    let sec = s < 10 ? '0' + s : s;
    this.timerValue = min + ':' + sec;
    remaining -= 1;

    if (remaining >= 0) {
      this.timer = setTimeout(x => {
        this.timerRunning = true;
        this.timerOTP(remaining);
      }, 1000);
      return
    }
    else {
      this.timerRunning = false;
    }
  }

  login(optData?) {
    if (!this.checkValidations()) {
      return;
    }
    let creds = {
      username: this.Form.get("adminUsername").value,
      password: this.Form.get("adminPassword").value,
      portalID: "employeePortal",
    };
    let data = {
      enc_password: localStorage.getItem("password_token"),
      enc_payload: this.authService.encryptionAuth(creds),
    };
    if (!optData) {
      this.loginData = data;
    }
    this.authService.auth("login", this.loginData).then((res) => {
      if (res.statusCode == 200) {
        let decrypted = this.authService.decryptionAuth(res.data);
        this.setDataAfterValidate(decrypted);
        localStorage.setItem(this.LOGIN_KEY, res.data);
        this.showOtpComponent = true;
        this.showCredentials = false;
        if (!optData) {
          this.timerOTP(180);
        }
      } else {
        this.alertService.alertAr("Error", res);
      }
    });
  }

  validate(data) {
    this.authService.auth("validate", data).then((response) => {
      if (response.statusCode == 200) {
        localStorage.removeItem(this.LOGIN_KEY);
        let decrypted = this.authService.decryptionAuth(response.data);
        localStorage.setItem("employee_token", decrypted.access_authorization);
        this.authorization.setToken(decrypted.access_authorization);
        this.authService.token = decrypted.access_authorization;
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem(
          "employee_password_token",
          decrypted.password_token
        );
        this.router.navigateByUrl("terms");
      } else {
        this.alertService.alertAr("Error", response);
      }
    });
  }

  setDataAfterValidate(result) {
    localStorage.setItem("employee_id", result.id);
    localStorage.setItem("terms_status", result.terms_status);
    localStorage.setItem("termination_status", result?.termination_status);
  }

  resendOTP() {
    if (!this.timerRunning) {
      this.timerRunning = true;
      this.timerOTP(180);
    }
    this.login(true);
  }

  startCall() {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) => {
      if (res.statusCode == 200) {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }

  checkValidations() {
    if (this.Form.invalid) {
      this.Form.markAllAsTouched();
      return false;
    }
    return true;
  }

  getFieldValue(field) {
    if (this.Form.get(field).touched) {
      if (this.Form.get(field).invalid) {
        return true;
      }
    }
    return false;
  }

  getErrorMessage(control, fieldName, minMaxValue?) {
    if (this.Form.get(control).invalid) {
      if (this.Form.get(control).hasError("required")) {
        return `${fieldName} is a required field.`;
      }
      if (this.Form.get(control).hasError("email")) {
        return `${fieldName} is in incorrect format. It should be in valid email format.`;
      }
    }
  }
}
