<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div class="profile-card">
        <div class="card-set">
            <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <div fxFlex="100" class="tableHeading">
                    <h2 style="margin: 0; font-weight: bold; color: #213060">
                        {{ "View Contributions" | translate }}
                    </h2>
                </div>
            </div>
            <form [formGroup]="Form">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Employee name" | translate }}</mat-label>
                        <input autocomplete="off" readonly type="text" matInput formControlName="employee_name" />
                    </mat-form-field>

                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Employee_Contribution" | translate }} ({{
                            "SAR" | translate
                            }})</mat-label>
                            <div style="font-weight: bold;" class="show">
                                <!-- {{Form.get('employee_contribution_amount').value | number:'1.4-4'}} -->
                                <span>{{Form.get('employee_contribution_amount').value | number:'1.4-4' | slice:0:-4}}</span>
                                <span style="font-size: 11px;">{{Form.get('employee_contribution_amount').value | number:'1.4-4' | slice:-4}}</span>
                            </div>
                        <input autocomplete="off" readonly type="text" matInput
                            formControlName="employee_contribution_amount" class="hide-input">
                    </mat-form-field>

                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Employer_Contribution" | translate }} ({{
                            "SAR" | translate
                            }})</mat-label>
                            <div style="font-weight: bold;" class="show">
                                <!-- {{Form.get('employer_contribution_amount').value | number:'1.4-4'}} -->
                                <span>{{Form.get('employer_contribution_amount').value | number:'1.4-4' | slice:0:-4}}</span>
                                <span style="font-size: 11px;">{{Form.get('employer_contribution_amount').value | number:'1.4-4' | slice:-4}}</span>
                            </div>
                        <input autocomplete="off" readonly type="text" matInput
                            formControlName="employer_contribution_amount" class="hide-input">
                    </mat-form-field>

                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Vested Amount (SAR)" | translate }}</mat-label>
                        <div style="font-weight: bold;" class="show">
                            <!-- {{Form.get('vested_amount').value | number:'1.4-4'}} -->
                            <span>{{Form.get('vested_amount').value | number:'1.4-4' | slice:0:-4}}</span>
                            <span style="font-size: 11px;">{{Form.get('vested_amount').value | number:'1.4-4' | slice:-4}}</span>
                        </div>
                        <input autocomplete="off" readonly type="text" matInput formControlName="vested_amount" class="hide-input">
                    </mat-form-field>

                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Unvested Amount (SAR)" | translate }}</mat-label>
                        <div style="font-weight: bold;" class="show">
                            <!-- {{Form.get('un_vested_amount').value | number:'1.4-4'}} -->
                            <span>{{Form.get('un_vested_amount').value | number:'1.4-4' | slice:0:-4}}</span>
                            <span style="font-size: 11px;">{{Form.get('un_vested_amount').value | number:'1.4-4' | slice:-4}}</span>
                        </div>
                        <input autocomplete="off" readonly type="text" matInput formControlName="un_vested_amount" class="hide-input">
                    </mat-form-field>

                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Created Date" | translate }}</mat-label>
                        <input autocomplete="off" readonly type="text" matInput formControlName="date" />
                    </mat-form-field>
                    <mat-form-field fxFlex="48" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                        class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                        <mat-label>{{ "Status" | translate }}</mat-label>
                        <input autocomplete="off" readonly type="text" matInput formControlName="status" />
                    </mat-form-field>
                    <div class="button-container" fxLayoutAlign="end" fxFlex="97" fxLayoutGap="10px">
                        <button class="btn btn-cancel" (click)="back()">
                            {{ "Cancel" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>