import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";

export class actionButton {
  label: string;
  route?: string = null;
  type: string; // output or else like link etc
  visibility: boolean = true;
  isCustom: boolean = false;
  color?: string;
  background?: string;
  icon?: string;
  source?: string;
  selected?: boolean = false;
}

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", minHeight: "80px" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TableComponent implements OnInit, OnChanges {
  objectKeys = Object.keys;
  search = "";
  @Input() props: {
    ActionButtons: actionButton[];
    inputData: any;
    checkedBoxes: any;
    checkedOne: any;
    columnHeader: any;
    dataSource: any;
    pagination: any;
    columnTypes: any;
    columnFilters: any;
    hasColumnFilter: false;
  };
  @Input() dataSource: any;
  @Input() columnTypes: any; // 'number', 'date', actions > 'actionsMenu', 'actionsSeperate'
  @Input() colHeader: any;
  @Input() actions: actionButton[];
  @Input() pagination: any;
  @Input() headingData: any;
  @Input() searchHeading: any = "Search";
  @Input() isExpandable: boolean = false;
  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitCheckBox: EventEmitter<any> = new EventEmitter<any>();
  @Output() setPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchData: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitFilters: EventEmitter<any> = new EventEmitter<any>();
  checkedBoxes = [];
  headerCheckBoxValue: any;
  expandedElement = null;
  allRowsExpanded = false;
  hasDivs: boolean = false;
  @Input() headerProps: {
    heading: string;
    hasSearch: boolean;
    hasButton: boolean;
    ActionButtons: actionButton[];
    hasMoreLinks: false;
    ActionMore: actionButton[];
    filterArray: [];
  };
  @Output() outPutHeaders: EventEmitter<any> = new EventEmitter<any>();
  selection = new SelectionModel<any>(true, []);

  colHeaderExpand = {
    no: "No.",
    employee_id: "Employee ID",
    iqama_id: "National ID/Iqama",
    name: "Employee Name",
    employee_contribution_amount: "Employee Contribution (SAR)",
    employer_contribution_amount: "Employer Contribution (SAR)",
    error: "Error Message",
  };
  divColor: any;

  constructor(protected router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onAllChecked();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  checkBox(event, row) {
    if (event.checked) {
      row.checked = true;
    } else {
      row.checked = false;
    }
    this.onAllChecked();
  }

  checkBoxAll(checked) {
    if (checked) {
      this.dataSource.forEach((item) => {
        item.checked = true;
      });
    } else {
      this.dataSource.forEach((item) => {
        item.checked = false;
      });
      this.headerCheckBoxValue = false;
    }
    this.headerCheckBoxValue = true;
    this.onAllChecked();
  }

  onAllChecked() {
    let checkedCount = 0;
    this.dataSource.forEach((element) => {
      if (element["checked"]) {
        checkedCount++;
      }
    });
    let dataLength = this.dataSource.length;
    this.headerCheckBoxValue =
      dataLength > 0 && dataLength == checkedCount ? true : false;

    let obj = {
      checkCount: checkedCount,
      checked: false,
    };
    this.emitCheckBox.emit(obj);
  }

  isChecked() {
    if (this.headerCheckBoxValue) return true;
    return false;
  }

  expandAll() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  setPagination(page) {
    this.setPage.emit(page);
  }

  getPaginationText() {
    let pagination = "Total Count: ";
    if (this.pagination.page < this?.pagination?.pages) {
      pagination +=
        this.pagination.per_page * this?.pagination?.page +
        "/" +
        this?.pagination?.count;
    } else if (this?.pagination?.page == this?.pagination?.pages) {
      pagination += this?.pagination?.count + "/" + this?.pagination?.count;
    }
    return pagination;
  }

  onRowActionClicked(elem, act, index) {
    if (act.label == "expand") {
      this.expandedElement = this.expandedElement === elem ? null : elem;
    }
    let row = {
      element: elem,
      action: act,
      index: index,
      expanded: this.expandedElement,
    };
    this.actionClicked.emit(row);
  }

  onSearch() {
    this.searchData.emit(this.search);
  }

  onTableHeaderButton(item): void {
    if (item.type == "output") {
      this.outPutHeaders.emit(item);
    } else {
      let url = "/main/" + item.route;
      this.router.navigateByUrl(url);
    }
  }

  onChangeFilters(filter): void {
    this.emitFilters.emit(filter);
  }
}
