import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
    selector: "app-activity-notifications",
    templateUrl: "./activity-notifications.component.html",
    styleUrls: ["./activity-notifications.component.scss"],
})
export class ActivityNotificationsComponent implements OnInit
{
    list: any = [];
    key: any;
    log: any;
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    NotificationIds: any[] = [];
    pagination = {
        page: 1,
        pages: null,
        per_page: 10,
        count: 0,
    };

    constructor(
        public tr: TranslationService,
        protected alertService: AlertService,
        public route: Router,
        protected mainService: ApiService,
        protected dec_enc: DecEncService,
        protected paginationService: PaginationService,
        protected dialog: MatDialog
    )
    {
        this.key = localStorage.getItem("password_token");
    }

    ngOnInit(): void
    {
        this.getLogs();
    }

    getLogs()
    {
        let count = 1;
        if (this.pagination.per_page != null) {
            count = this.pagination.per_page * (this.pagination.page - 1) + 1;
        }
        let data = {
            type: "activity",
        };
        let payload = this.dec_enc.encryption(data);
        let url = `home/logs?per_page=10&sort_order=DESC&page=${this.pagination.page}`;
        this.mainService.postData(url, { enc_payload: payload }).then((result) =>
        {
            if (result.statusCode == 200) {
                this.log = this.dec_enc.decryption(result.data).logs;
                this.pagination = this.dec_enc.decryption(result.data).pagination;
                this.countValue();
                return;
            } else {
                this.alertService.alert("Error", result);
            }
        });
    }

    shortTitle(desc): any
    {
        if (desc.length > 100) {
            desc = desc.split('","').join(", ").replace('["', "").replace('"]', "");
            return desc;
        } else {
            return desc;
        }
    }

    countValue()
    {
        this.NotificationIds = [];
        let url = "home/read_notifications";
        this.log.forEach((element) =>
        {
            this.NotificationIds.push(element.id);
        });
        let data = {
            ids: this.NotificationIds.join(","),
            type: "activity",
        };
        let payload = this.dec_enc.encryption(data);
        this.mainService.postData(url, { enc_payload: payload }).then((res) =>
        {
            if ((res.statusCode = 200)) {
                this.tr.setApiOnCall(1);
                this.NotificationIds = [];
            }
        });
    }

    setPage(page)
    {
        this.pagination.page = page;
        this.getLogs();
    }

    markAsRead()
    {
        let body = {
            type: 'activity'
        };
        let payload = this.dec_enc.encryption(body);
        this.mainService.readNotifications(`read_all_notifications`, { enc_payload: payload }).then(response =>
        {
            if (response.statusCode == 200) {
                this.mainService.setApiOnCall(1);
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.toastSuccess('النجاح', 'تم تمييز جميع الإشعارات كمقروءة.');
                }
                else {
                    this.alertService.toastSuccess('Success', 'All notifications have been marked as read.');
                }
                // this.alertService.toastSuccess('Success', 'All notifications have been marked as read.');
                // this.alertService.alert('Success', 'Marked all as read.');
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }
}
