import { Component, OnInit } from "@angular/core";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-withdrawls-view",
  templateUrl: "./withdrawls-view.component.html",
  styleUrls: ["./withdrawls-view.component.scss"],
})
export class WithdrawlsViewComponent implements OnInit {
  Form: FormGroup;
  setPassword: FormGroup;
  failureAlert: boolean;
  alertMsg: any = "";
  sub: Subscription;
  id: any;
  path: any;
  hed: any;
  button: any;
  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    protected formbuilder: FormBuilder,
    protected dialog: MatDialog,
    protected _route: ActivatedRoute,
    protected mainService: ApiService,
    public dec_enc: DecEncService
  ) {
    this._route.params.subscribe((params) => {
      this.id = Number(params["id"]);
    });
    this.Form = this.formbuilder.group({
      amount: ["", [Validators.required]],
      on_date: ["", [Validators.required]],
      active: ["1", [Validators.required]],
      notes: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getall();
  }

  getall() {
    let url = "withdrawals";
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          let list = result.withdrawals;
          list.forEach((obj) => {
            if (this.id === obj.id) {
              if (obj.amount)
              {
                obj.amount = Number(obj.amount).toFixed(4);
              }
              this.Form.patchValue(obj);
            }
          });
        }
      },
      (error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      }
    );
  }
}
