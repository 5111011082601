<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <div class="profile-card">
    <ng-container *ngFor="let item of fundlist">
      <mat-card class="mb-3" (click)="onSelectFund(item.id, item.risk_rating)">
        <div class="text-center mb-3" class="fund-togle">
          <div class="recomend" *ngIf="recomended_risk == item.risk_rating">
            <p class="text-center">
              {{ "Recomended" | translate }}
            </p>
          </div>
          <div
            class="arrow-right"
            *ngIf="
              item.risk_rating == select_fund && item.id == this.select_fund_id
            "
          ></div>
          <h1
            style="color: #00c435"
            class="text-center"
            *ngIf="item.risk_rating == 'low'"
          >
            {{ "low" | translate }}
          </h1>
          <h1
            style="color: #e69a5a"
            class="text-center"
            *ngIf="item.risk_rating == 'medium'"
          >
            {{ "medium" | translate }}
          </h1>
          <h1
            style="color: #ec4955"
            class="text-center"
            *ngIf="item.risk_rating == 'high'"
          >
            {{ "high" | translate }}
          </h1>
          <div class="portfolio mb-1">
            <!-- <p *ngIf="recomended_risk==item.risk_rating">Your recomended risk profile is {{item.risk_rating}} risk</p> -->
            <h3 class="portfolio-name">
              {{ "Fund_Name" | translate }}: {{ item.name }}
            </h3>
            <h3 class="portfolio-name">
              {{ "ExpectedReturn" | translate }}:
              <!-- {{
                item?.expected_return_percentage
                  ? item?.expected_return_percentage
                  : "0"
              }} -->
              <span style="font-size: 16px;">{{item?.expected_return_percentage | number:'1.2-2' | slice:0:-2}}</span>
              <span style="font-size: 13px;">{{item?.expected_return_percentage | number:'1.2-2' | slice:-2}}%</span>
            </h3>
            <a
              routerLink="../../main/reallocate-funds/fund-detail/{{ item.id }}"
              >{{ "learn_more" | translate }}</a
            >
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
  <div class="card-main">
    <app-table
      fxFlex="100"
      [headingData]="headingData"
      [headerProps]="headerProps"
      (searchData)="searchData($event)"
      [dataSource]="list"
      [columnTypes]="columnTypes"
      [colHeader]="colHeader"
      [actions]="actions"
      (setPage)="setPage($event)"
      [pagination]="paginations"
      (actionClicked)="actionClicked($event)"
      (emitFilters)="onFilters($event)"
    >
    </app-table>
  </div>
</div>
