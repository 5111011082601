import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "tp-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.scss"],
})
export class TPPaginationComponent implements OnInit {
  @Output() setPage: EventEmitter<any> = new EventEmitter<any>();
  @Input() pagination: any;
  isArabic = false;

  constructor() {
    this.isArabic = localStorage.getItem("lang") == "ar" ? true : false;
  }

  ngOnInit() {}

  setPagination(page) {
    this.setPage.emit(page);
  }

  getPaginationText() {
    let pagination = ": ";
    if (this.pagination.page < this?.pagination?.pages) {
      pagination +=
        this.pagination.per_page * this?.pagination?.page +
        "/" +
        this?.pagination?.count;
    } else if (this?.pagination?.page == this?.pagination?.pages) {
      pagination += this?.pagination?.count + "/" + this?.pagination?.count;
    } else {
      pagination += "0/0";
    }
    return pagination;
  }
}
