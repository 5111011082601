<div *ngIf="show" style="
    position: fixed;
    z-index: 9999999999999;
    width: 100%;
    height: 100%;
    background: #8080803b;
  ">
  <div class="row" style="
      width: 90px;
      height: 90px;
      background: aliceblue;
      margin: 0 auto;
      position: relative;
      top: 40%;
      border-radius: 10px;
    ">
    <div style="text-align: center" class="col-sm-12">
      <img style="margin-top: 10px" width="50" height="50" src="assets/view-loader.gif" />
      <h5>{{ "loading" | translate }}</h5>
    </div>
  </div>
</div>
