import { Component, OnInit } from "@angular/core";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import * as moment from "moment";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  Form: FormGroup;
  setPassword: FormGroup;
  failureAlert: boolean;
  alertMsg: any = "";
  iqama_image: any;
  image: any;
  image_status: any = false;
  fund_allocated: any;
  Filepath: any;
  currentDate = new Date();
  iqama_image_new: any;

  constructor(
    public tr: TranslationService,
    protected formbuilder: FormBuilder,
    protected dialog: MatDialog,
    protected mainService: ApiService,
    public dec_enc: DecEncService,
    public prService: ProfileService,
    protected alertSrv: AlertService
  ) {
    this.Filepath = this.mainService.file_url;
    this.Form = this.formbuilder.group({
      name: [
        null,
        [Validators.required, Validators.pattern("[a-zA-Z]+[a-zA-Z ]*")],
      ],
      email: [null, [Validators.required, Validators.email]],
      iqama_id: [null, [Validators.required]],
      iqama_expiry: [null, [Validators.required]],
    });

    this.setPassword = this.formbuilder.group({
      old_password: [null, [Validators.required]],
      new_password: [null, [Validators.required]],
      confirm_new_password: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getProfile();
  }

  callAgain() {
    this.Form.get("iqama_expiry").setValue(this.prService.user.iqama_expiry);
  }

  getProfile() {
    let url = `viewProfile`;
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          this.Form.get("name").setValue(result?.user?.name);
          this.Form.get("email").setValue(result?.user?.email);
          this.Form.get("iqama_id").setValue(result?.user?.iqama_id);
          this.Form.get("iqama_expiry").setValue(result?.user?.iqama_expiry);
          this.image = result?.user?.iqama_image;
          this.iqama_image_new = this.Filepath + this.image;
          this.prService.user = result.user;
          return;
        }
      },
      (error) => {
        this.alertSrv.alert("Error", this.tr.translation.serverError);
      }
    );
  }

  updateProfile() {
    let expiryDate = moment(this.Form.get("iqama_expiry").value).format(
      "YYYY-MM-DD"
    );
    this.Form.get("iqama_expiry").setValue(expiryDate);
    let payload = this.dec_enc.encryption(this.Form.value);
    let url = "updateProfile2";
    let formData = new FormData();
    if (this.image_status) {
      formData.append("iqama_image", this.iqama_image);
    }
    formData.append("enc_payload", payload);
    this.mainService.postData(url, formData).then(
      (result) => {
        if (result.statusCode == 200) {
          this.alertSrv.alert(
            "Success",
            "Profile has been updated successfully"
          );
          this.getProfile();
          return;
        }
        this.alertSrv.alert("Error", result.message);
      },
      (error) => {
        this.alertSrv.alert("Error", "Server error");
      }
    );
  }

  updatePassword() {
    if (
      this.setPassword.get("new_password").value ==
      this.setPassword.get("confirm_new_password").value
    ) {
      let enc_payload = this.dec_enc.encryption(this.setPassword.value);
      this.mainService
        .postData("updatePassword", { enc_payload })
        .then((res) => {
          if (res.statusCode == 200) {
            this.setPassword.reset();
            this.alertSrv.alert(
              "Success",
              "Password has been updated successfully"
            );
            return;
          } else if (res?.error?.statusCode == 410) {
            this.alertSrv.alertAr("Error", res.error);
          } else {
            this.alertSrv.alertAr("Error", res);
          }
        });
    } else {
      this.alertSrv.alert(
        "Error",
        "New password and confirm password mismatched"
      );
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.iqama_image = file;
      this.image_status = true;
    }
  }

  getFile(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0] as File;
      this.iqama_image = file;
      this.iqama_image_new = file;
      this.image_status = true;
      const reader = new FileReader();
      reader.onload = (e) => (this.iqama_image_new = reader.result);
      reader.readAsDataURL(file);
    }
  }

  viewImage(path) {
    if (path != null && path != "" && path != "null") {
      window.open(this.Filepath + path, "_blank");
    } else {
      this.alertSrv.alert("Error", "There is no Image");
    }
  }

  getFieldValue(field) {
    if (this.Form.get(field).touched) {
      if (this.Form.get(field).invalid) {
        return true;
      }
    }
    return false;
  }

  checkValidations() {
    if (this.Form.invalid) {
      this.Form.markAllAsTouched();
      return false;
    }
    return true;
  }

  getErrorMessage(control, fieldName, minVal?, maxVal?) {
    if (this.Form.get(control).invalid) {
      if (this.Form.get(control).hasError("required")) {
        return `${fieldName} is a required field.`;
      }
      if (this.Form.get(control).hasError("minlength")) {
        return `Minimum length for ${fieldName} is ${minVal} characters.`;
      }
      if (this.Form.get(control).hasError("maxlength")) {
        return `Maximum length for ${fieldName} is ${maxVal} characters.`;
      }
      if (this.Form.get(control).hasError("email")) {
        return `${fieldName} should be in valid email format.`;
      }
      if (control == "name" && this.Form.get(control).hasError("pattern")) {
        return `${fieldName} should be letters only.`;
      }
      if (control == "phone" && this.Form.get(control).hasError("pattern")) {
        return `${fieldName} should be numbers only.`;
      }
    }
  }
  getFieldValue1(field) {
    if (this.setPassword.get(field).touched) {
      if (this.setPassword.get(field).invalid) {
        return true;
      }
    }
    return false;
  }

  checkValidations1() {
    if (this.setPassword.invalid) {
      this.setPassword.markAllAsTouched();
      return false;
    }
    return true;
  }

  getErrorMessage1(control, fieldName, minVal?, maxVal?) {
    if (this.setPassword.get(control).invalid) {
      if (this.setPassword.get(control).hasError("required")) {
        return `${fieldName} is a required field.`;
      }
      if (this.setPassword.get(control).hasError("minlength")) {
        return `Minimum length for ${fieldName} is ${minVal} characters.`;
      }
      if (this.setPassword.get(control).hasError("maxlength")) {
        return `Maximum length for ${fieldName} is ${maxVal} characters.`;
      }
      if (this.setPassword.get(control).hasError("email")) {
        return `${fieldName} should be in valid email format.`;
      }
      if (
        control == "name" &&
        this.setPassword.get(control).hasError("pattern")
      ) {
        return `${fieldName} should be letters only.`;
      }
      if (
        control == "phone" &&
        this.setPassword.get(control).hasError("pattern")
      ) {
        return `${fieldName} should be numbers only.`;
      }
    }
  }
}
