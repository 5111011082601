<!-- Toggle Button and Menu for mobile view -->
<div fxLayoutAlign="baseline" fxHide.gt-xs [dir]="direction">
    <button type="button" mat-icon-button fxFlexOffset="2" (click)="drawer.toggle(); toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/thrift_logo.png" alt="Thriftplan Logo" width="100px" fxFlexOffset="24" />
</div>

<!-- Icon for expanding/collapsing menu -->
<mat-icon *ngIf="showHideSideMenu" fxHide.xs [ngClass]="[
    isArabic && isExpanded ? 'collapse-chevron-arabic' : '',
    isArabic && !isExpanded ? 'expand-chevron-arabic' : '',
    !isArabic && !isExpanded ? 'expand-chevron' : '',
    !isArabic && isExpanded ? 'collapse-chevron' : ''
  ]" (click)="isExpanded = !isExpanded" class="menu-expander">
    {{
    !isArabic && isExpanded
    ? "arrow_right"
    : !isArabic && !isExpanded
    ? "arrow_left"
    : isArabic && isExpanded
    ? "arrow_left"
    : "arrow_right"
    }}
</mat-icon>

<mat-drawer-container class="example-container" [dir]="direction" #container (backdropClick)="toggle('over')">
    <mat-drawer class="example-sidenav" #drawer [mode]="mode" [opened]="openSidenav" style="overflow-x: hidden">
        <mat-nav-list style="
        display: flex;
        flex-direction: column;
        height: 97%;
        justify-content: space-between;
      " [ngClass]="[
        isArabic && isExpanded ? 'collapse-arabic' : '',
        isArabic && !isExpanded ? 'expand-arabic' : '',
        !isArabic && isExpanded ? 'collapse-nav' : '',
        !isArabic && !isExpanded ? 'expand-nav' : ''
      ]">
            <div>
                <mat-list-item style="
            margin-bottom: 20px;
            height: 120px;
            background: url('../../../assets/logos/logo_bg.svg') no-repeat;
            background-position: top 0px right 0px;
          ">
                    <!-- <img src="../../../assets/tp-logo.svg" />
                    <img src="assets/thrift_side_menu.png" style="margin-inline: 15px; width: 115px;"> -->
                    <img src="assets/thriftplan-high-res.png" style="width: 190px;">
                    <!-- <h1
            [ngClass]="isArabic ? 'mr-20' : ''"
            style="padding-left: 15px; margin-bottom: 5px !important"
          >
            <span style="font-weight: bold; color: #213060">thrift</span>
            <span style="color: #999999">plan</span>
          </h1> -->
                </mat-list-item>

                <div *ngIf="showHideSideMenu">
                    <ng-container *ngFor="let item of menus">
                        <mat-list-item class="side-item" [routerLink]="item.routerLink" routerLinkActive="active">
                            <mat-icon matTooltip="{{ item.label | translate }}" [matTooltipDisabled]="!isExpanded"
                                mat-list-icon>{{ item.icon }}</mat-icon>
                            <h4 [style.text-align]="isArabic ? 'right' : 'left'" style="width: 156px; text-overflow: ellipsis" mat-line>
                                {{ item.label | translate }}
                            </h4>
                        </mat-list-item>
                    </ng-container>
                </div>
            </div>

            <div [style]="isExpanded ? 'width:26%' : 'width:100%'" [ngClass]="[
          isArabic && isExpanded ? 'collapse-arabic' : '',
          isArabic && !isExpanded ? 'expand-arabic' : '',
          !isArabic && isExpanded ? 'collapse-nav' : '',
          !isArabic && !isExpanded ? 'expand-nav' : ''
        ]">
                <!-- support -->
                <div *ngIf="showHideSideMenu">
                    <mat-list-item class="nav-bottom mb-10 mt-10 support" routerLink="/main/help-support"
                        routerLinkActive="active">
                        <mat-icon matTooltip="{{ 'Support' | translate }}" [matTooltipDisabled]="!isExpanded" [style]="
                isArabic
                  ? 'color: #213060; font-size: 20px;padding:0'
                  : 'color: #213060; font-size: 20px;'
              " mat-list-icon>headset_mic</mat-icon>
                        <h4 style="color: #213060" mat-line>{{ "Support" | translate }}</h4>
                    </mat-list-item>
                </div>

                <!-- logout -->
                <mat-list-item (click)="logout()" class="nav-bottom logout">
                    <mat-icon matTooltip="{{ 'Logout' | translate }}" [matTooltipDisabled]="!isExpanded" [style]="
              isArabic
                ? 'color: #EE5D50; font-size: 20px;padding:0'
                : 'color: #EE5D50; font-size: 20px;'
            " mat-list-icon>logout</mat-icon>
                    <h4 style="color: #ee5d50" mat-line>{{ "Logout" | translate }}</h4>
                </mat-list-item>
            </div>
        </mat-nav-list>
    </mat-drawer>

    <div [ngClass]="[
      isArabic && isExpanded ? 'content-collapse-arabic' : '',
      isArabic && !isExpanded ? 'content-expand-arabic' : '',
      !isArabic && isExpanded ? 'content-collapse' : '',
      !isArabic && !isExpanded ? 'content-expand' : ''
    ]" [ngClass.xs]="{ '': !isArabic && !isExpanded }">
        <div id="top-bar" class="toolbar" style="padding: 10px 25px 20px 25px" fxLayout="row wrap">
            <div *ngIf="false" fxFlex="40" fxFlex.xs="100" fxFlex.sm="100"
                style="display: flex; align-items: center; margin-inline: 10px">
                <div style="
            border-radius: 30px;
            padding: 8px 15px;
            background-color: white;
            margin: 15px;
            color: #213060;
            font-size: 12px;
          ">
                    <b>Notifications</b>: Lorem Ipsum is simply dummy text.
                </div>
            </div>

            <div *ngIf="true" class="mt-10" fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column center">
                <h5 style="margin: 0; color: #707eae; text-align: start">
                    {{ "Home" | translate }} / {{ routeName | translate }}
                </h5>
                <h1 style="margin: 0; color: #213060; text-align: start">
                    {{ routeName | translate }}
                </h1>
            </div>

            <div fxLayout="row wrap" fxLayoutGap="10px" fxFlex.xs="100" fxLayoutAlign="end center"
                fxLayoutAlign.xs="center" fxFlex>
                <app-translation-new class="mt-10"></app-translation-new>

                <div fxFlex="120px" class="mt-10" fxLayoutAlign="space-between center" style="
            border-radius: 30px;
            padding: 10px;
            background-color: white;
            margin: 5px;
            color: #213060;
            box-shadow: 14px 17px 40px 4px #7090b014;
          ">
                    <div *ngIf="showHideSideMenu" class="demo-section" [matBadge]="notificationsCount"
                        [matBadgeHidden]="notificationsCount == 0">
                        <img (click)="countValue()" src="../../../assets/ic_notification.svg"
                            style="width: 16px; height: 16px" />
                    </div>
                    <div>
                        <img src="../../../assets/ic_settings.svg" [matMenuTriggerFor]="menu"
                            style="width: 16px; height: 16px" />
                        <mat-menu #menu="matMenu" style="border-radius: 20px !important">
                            <button *ngIf="showHideSideMenu" style="outline: none" (click)="myProfile()" mat-menu-item>
                                {{ "My Detailed Information" | translate }}
                            </button>
                            <button style="outline: none" (click)="logout()" mat-menu-item>
                                {{ "Logout" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                    <mat-icon (click)="profile()" mat-list-icon #tooltip="matTooltip" [matTooltip]="employeeName" style="
              cursor: pointer;
              width: 22px;
              height: 16px;
              line-height: 17px;
              font-size: 20px;
              color: #a3aed0;
            ">
                        person_outline</mat-icon>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>