<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <div class="profile-card">
    <div class="card-set">
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxFlex="100" class="tableHeading">
          <h2 style="margin: 0; font-weight: bold; color: #213060">
            {{ "Update Profile" | translate }}
          </h2>
        </div>
      </div>
      <form [formGroup]="Form">
        <div class="mt-10" fxLayout="row wrap" fxLayoutAlign="space-between">
          <div
            class="form-field"
            fxLayout="row wrap"
            fxFlex="100"
            fxLayoutAlign="space-between"
          >
            <mat-form-field
              fxFlex="24"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "name" | translate }}</mat-label>
              <input
                autocomplete="off"
                readonly
                type="text"
                matInput
                formControlName="name"
              />
            </mat-form-field>
            <mat-form-field
              fxFlex="24"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "email" | translate }}</mat-label>
              <input
                autocomplete="off"
                readonly
                type="text"
                matInput
                formControlName="email"
              />
            </mat-form-field>
            <mat-form-field
              fxFlex="24"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "Iqama_Id_National_Id" | translate }}</mat-label>
              <input
                autocomplete="off"
                readonly
                type="text"
                matInput
                formControlName="iqama_id"
              />
            </mat-form-field>
            <mat-form-field
              fxFlex="24"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{
                "Iqama/ National ID expiry date" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="iqama_expiry"
                [matDatepicker]="picker1"
                [min]="currentDate"
                readonly
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>

              <mat-icon
                class="mat-icon-error"
                *ngIf="getFieldValue('iqama_expiry')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage('iqama_expiry', 'Iqama Expiry Date')
                "
                [matTooltipDisabled]="!getFieldValue('iqama_expiry')"
                matTooltipPosition="above"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>
          </div>

          <div
            class="iqama-id mt-20"
            fxLayout="row wrap"
            style="min-height: 162px; border-radius: 20px"
            ngStyle.xs="min-height:280px;"
            fxLayoutAlign="space-between"
          >
            <input
              style="display: none"
              type="file"
              #attack
              (change)="getFile($event)"
              accept=".png, .jpg, .jpeg"
            />
            <div
              class="image-holder"
              fxFlex="49"
              fxFlex.xs="100"
              fxFlex.sm="100"
            >
              <button mat-button class="upload-button" (click)="attack.click()">
                <ng-container *ngIf="!iqama_image_new">
                  <mat-icon class="icon">upload</mat-icon>
                  <h2>{{ "Upload Files" | translate }}</h2>
                  <h5>
                    {{ "PNG, JPG and GIF files are allowed" | translate }}
                  </h5>
                </ng-container>
                <ng-container *ngIf="iqama_image_new">
                  <img class="upload-image" [src]="iqama_image_new" />
                </ng-container>
              </button>
            </div>
            <div
              class="button-text"
              ngStyle.sm="margin-top: 20px;"
              ngStyle.xs="margin-top: 20px;"
              fxFlex="49"
              fxFlex.xs="100"
              fxFlex.sm="100"
            >
              <h2>{{ "Iqama/National Id" | translate }}</h2>
              <h5>{{ "Click to upload the scanned copy" | translate }}</h5>
            </div>
          </div>
          <div class="button-container mt-20" fxLayoutAlign="end" fxFlex="100">
            <button class="btn btn-ok" (click)="updateProfile()">
              {{ "Submit" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="card-set mt-5 mb-20">
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxFlex="100" class="tableHeading">
          <h2 style="margin: 0; font-weight: bold; color: #213060">
            {{ "Update password" | translate }}
          </h2>
        </div>
      </div>
      <form [formGroup]="setPassword">
        <div
          class="mt-10"
          fxLayout="row wrap"
          fxLayoutAlign="space-between start"
        >
          <div fxLayout="row wrap" fxLayoutAlign="space-between" fxFlex="100">
            <mat-form-field
              fxFlex="32"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "EnterCurrentPassword" | translate }}</mat-label>
              <input
                autocomplete="off"
                type="password"
                matInput
                formControlName="old_password"
              />
              <mat-icon
                *ngIf="getFieldValue1('old_password')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage1('old_password', 'Old Password') | translate
                "
                [matTooltipDisabled]="!getFieldValue1('old_password')"
                matTooltipPosition="above"
                style="color: #213060"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>

            <mat-form-field
              fxFlex="32"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "EnterNewPassword" | translate }}</mat-label>
              <input
                autocomplete="off"
                type="password"
                matInput
                formControlName="new_password"
              />
              <mat-icon
                *ngIf="getFieldValue1('new_password')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage1('new_password', 'New Password') | translate
                "
                [matTooltipDisabled]="!getFieldValue1('new_password')"
                matTooltipPosition="above"
                style="color: #213060"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>

            <mat-form-field
              fxFlex="32"
              fxFlex.xs="100"
              fxFlex.sm="100"
              class="input-field mt-20"
              fxFlexAlign="center"
              appearance="outline"
            >
              <mat-label>{{ "ConfirmNewPassword" | translate }}</mat-label>
              <input
                autocomplete="off"
                type="password"
                matInput
                formControlName="confirm_new_password"
              />
              <mat-icon
                *ngIf="getFieldValue1('confirm_new_password')"
                #tooltip="matTooltip"
                [matTooltip]="
                  getErrorMessage1('confirm_new_password', 'Confirm Password')
                    | translate
                "
                [matTooltipDisabled]="!getFieldValue1('confirm_new_password')"
                matTooltipPosition="above"
                style="color: #213060"
                matSuffix
                >info
              </mat-icon>
            </mat-form-field>
          </div>

          <div
            class="button-container mt-20"
            fxLayout="row wrap"
            fxLayoutAlign="end"
            fxFlex="100"
          >
            <button
              class="btn btn-ok"
              (click)="updatePassword()"
              [disabled]="setPassword.invalid"
            >
              {{ "Submit" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
