import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf, never } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { appConfig } from "src/config";
import { DecEncService } from "./dec-enc";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  headers: HttpHeaders;
  options: any;
  public user: any;

  constructor(private http: HttpClient, public dec_enc: DecEncService) {}

  loadProfile(): Observable<any> {
    if (this.user) return;
    let token = localStorage.getItem("employee_token");
    let url = appConfig.base_url + "viewProfile";
    if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    this.options = {
      headers: this.headers,
      observe: "response",
    };
    return this.http.post(url, {}, this.options).pipe(
      tap((data: any) => {
        let resp = data.body.data;
        let result = this.dec_enc.decryption(resp);
        this.user = result.user;
      }),
      catchError((e) => {
        if (e.status == "401") {
          localStorage.clear();
          window.location.reload();
        }
        const data = null;
        if (!data) {
          return never();
        } else {
          return observableOf(e);
        }
      })
    );
  }
}
