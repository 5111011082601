import { Component, OnInit, ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import * as moment from "moment";
import { FormControl, FormGroup } from "@angular/forms";
import { ChartDataSets } from "chart.js";
import { Label, Color, BaseChartDirective } from "ng2-charts";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";
import { CongratulationsDialogComponent } from "../congratulations-dialog/congratulations-dialog.component";

@Component({
    selector: "app-manage-portfolio",
    templateUrl: "./manage-portfolio.component.html",
    styleUrls: ["./manage-portfolio.component.scss"],
})
export class ManagePortfolioComponent implements OnInit
{
    highcharts = Highcharts;
    list: any = [];
    items: any = [];
    employee_contribution: any = 0.0;
    employer_contribution: any = 0.0;
    profit: any = 0.0;
    vested: any = 0.0;
    unvested: any = 0.0;
    total: any = 0.0;
    data: any = [];
    profit_result: any = [];
    date: any = new Date().getFullYear();
    newlist: any = [];
    profitAmount: any = [];
    chartDate: any = [];
    Form: FormGroup;
    minDate: any;
    start_date: any;
    end_date: any;
    currentDate = new Date();
    calendar: String = "assets/ic_event_24px.svg";
    isDateDisabled: boolean = true;
    totalPortfolio: number = 0;
    public lineChartData: ChartDataSets[] = [{ data: [], label: "Profit/Loss" }];
    lineChartLabels: Label[] = this.chartDate;
    employeeHeader = {
        fund_name: "Portfolio",
        risk_type: "Risk Type",
        asset_name: "Fund",
        availble_units: "No of Units",
        employee_invested_amount: "Invested (SAR)"
    };
    companyHeader = {
        fund_name: "Portfolio",
        risk_type: "Risk Type",
        asset_name: "Fund",
        company_availble_units: "No of Units",
        company_invested_amount: "Invested (SAR)"
    };

    columnTypesEmployee = {
        availble_units: 'number-5',
        employee_invested_amount: 'number-4',
    };
    columnTypesCompany = {
        company_availble_units: 'number-5',
        company_invested_amount: 'number-4',
    };
    public lineChartOptions = {
        responsive: true,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            // return Number(value).toFixed(4);
                            return Number(value).toLocaleString(undefined, {minimumFractionDigits: 4})
                        }
                    }
                },
            ],
        },
        plugins: {
            tooltip: {
                backgroundColor: "#213060",
                ticks: {
                    callback: function (value, index, values) {
                        return Number(value).toFixed(4);
                    }
                },
                callbacks: {
                    label: function(tooltipItem, data) {
                        return Number(data).toFixed(4);
                    }
                }
            },
            callbacks: {
                label: function(tooltipItem, data) {
                    return Number(data).toFixed(4);
                }
            }
        
        },
    };
    public lineChartLegend = false;
    lineChartType = "line";
    lineChartColors: Color[] = [
        {
            borderColor: "#213060",
            backgroundColor: "rgba(43, 84, 123, 0.1)",
        },
    ];
    // line chart settings end
    // pie chart settings
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    portfolioPieChartLabels: Label[] = [
        ["Company Contribution"],
        ["Employee Contribution"],
        " Profit",
    ];
    portfolioPieChartData = [
        {
            data: [],
            backgroundColor: ["#213060", "#79BBBF", "#CCCCCC"],
        },
    ];

    vestingPieChartLabels: Label[] = [[" Vested"], " Unvested"];
    vestingPieChartData = [
        {
            data: [],
            backgroundColor: ["#213060", "#79BBBF", "#CCCCCC"],
        },
    ];

    public pieChartLegend = false;
    isArabic = false;

    constructor(
        public tr: TranslationService,
        public route: Router,
        public dec_enc: DecEncService,
        protected mainService: ApiService,
        public dialog: MatDialog,
        protected alertService: AlertService,
        protected profileService: ProfileService
    )
    {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        this.Form = new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        });
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 74, 0, 1);
    }

    ngOnInit(): void
    {
        this.Form.controls["start"].valueChanges.subscribe((value) =>
        {
            if (value != null) {
                this.isDateDisabled = false;
                this.minDate = new Date(value);
            }
        });
        this.getPortfolio();
        this.getProfit();

        // console.log('profile', this.profileService.user)
        // if (this.profileService.user.welcome_employee) {
        //     let dialogRef = this.dialog.open(CongratulationsDialogComponent, 
        //     {   
        //         autoFocus: false, 
        //         width: '40%', 
        //         height: 'auto', 
        //         disableClose: true, 
        //         hasBackdrop: true, 
        //         backdropClass: 'no-access-bg', 
        //         panelClass: !this.isArabic ? 'english-lang' : 'arabic-lang' 
        //     });
        //     dialogRef.afterClosed().subscribe(res => {
        //         this.getProfile();
        //     })
        // }
    }

    getProfile()
    {
        let url = `viewProfile`;
        this.mainService.postData(url, {}).then(res =>
        {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.profileService.user = result.user;
                return;
            }
        },
            error =>
            {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }

    dashboardData: any;

    getPortfolio()
    {
        this.mainService.postData("home/dashboard", {}).then(
            (res) =>
            {
                if (res.statusCode == 200) {
                    let result = this.dec_enc.decryption(res.data);
                    console.log('result', result);
                    this.dashboardData = result;
                    if (this.dashboardData.employee_assets.length > 0)
                    {
                        this.dashboardData.employee_assets.forEach(item =>
                        {
                            item['risk_type'] = item['risk_type'].charAt(0).toUpperCase() + item['risk_type'].slice(1);
                            item['availble_units'] = !item['availble_units'] || item['availble_units'] == 'NaN' ? 0 : Number(item['availble_units']);
                            item['employee_invested_amount'] = !item['employee_invested_amount'] || item['employee_invested_amount'] == 'NaN' ? 0 : Number(item['employee_invested_amount']);
                        });
                    }
                    if (this.dashboardData.company_assets.length > 0)
                    {
                        this.dashboardData.company_assets.forEach(item =>
                        {
                            item['risk_type'] = item['risk_type'].charAt(0).toUpperCase() + item['risk_type'].slice(1);
                            item['company_availble_units'] = !item['company_availble_units'] || item['company_availble_units'] == 'NaN' ? 0 : Number(item['company_availble_units']);
                            item['company_invested_amount'] = !item['company_invested_amount'] || item['company_invested_amount'] == 'NaN' ? 0 : Number(item['company_invested_amount']);
                        });
                    }
                    this.portfolioBreakdownChart();
                    this.vestingChart();
                    return;
                } else {
                    this.alertService.alertAr("Error", res);
                }
            },
            (error) =>
            {
                this.alertService.alert("Error", this.tr.translation.serverError);
            }
        );
    }

    getProfit()
    {
        this.profitAmount = [];
        this.chartDate = [];
        let enc_payload = this.dec_enc.encryption({
            from_date: this.start_date,
            to_date: this.end_date,
        });
        let url = "home/portfolio/new_assets_profits";
        this.mainService.postData(url, { enc_payload }).then(
            (res) =>
            {
                if (res.statusCode == 200) {
                    let result = this.dec_enc.decryption(res.data);
                    this.profit_result = result.assets_profits_aggregate;
                    if (result.assets_profits_aggregate.length > 0) {
                        result.assets_profits_aggregate.forEach((element) =>
                        {
                            element.profit_amount = isNaN(Number(element.profit_amount)) ? Number(0).toFixed(4) : Number(element.profit_amount).toFixed(4);
                            this.profitAmount.push(element.profit_amount);
                            this.chartDate.push(
                                moment(new Date(element.created_at)).format("YYYY-MM-DD")
                            );
                        });
                    } else {
                        this.profitAmount = [];
                        this.chartDate = [];
                    }
                    this.contributionLineChart();
                    return;
                } else {
                    this.alertService.alertAr("Message", res);
                }
            },
            (error) =>
            {
                this.alertService.alert("Error", this.tr.translation.serverError);
            }
        );
    }

    dateChange(): void
    {
        this.start_date = this.Form.get("start").value
            ? moment(this.Form.get("start").value).format("YYYY-MM-DD")
            : null;
        this.end_date = this.Form.get("end").value
            ? moment(this.Form.get("end").value).format("YYYY-MM-DD")
            : null;
        if (this.start_date && this.end_date) {
            this.start_date = this.start_date;
            this.end_date = this.end_date;
            this.getProfit();
        }
    }

    portfolioBreakdownChart()
    {
        let data = [];
        data.push(this.dashboardData.summary.company_contribution);
        data.push(this.dashboardData.summary.employee_contribution);
        data.push(this.dashboardData.summary.profit);
        this.portfolioPieChartData[0].data = data;
        this.chart.update();
    }

    vestingChart()
    {
        let data = [];
        data.push(this.dashboardData.summary.total_vested);
        data.push(this.dashboardData.summary.total_unvested);
        this.vestingPieChartData[0].data = data;
        this.chart.update();
    }

    contributionLineChart()
    {
        this.lineChartLabels = this.chartDate;
        this.lineChartData[0].data = this.profitAmount;
        this.chart.update();
    }
}
