<main>
    <section class="image" style="margin-bottom: 15px;">
        <img src="assets/ic_done.svg">
    </section>

    <section class="done">
        <h1>{{'Done!' | translate}}</h1>
    </section>

    <section class="message">
        <h4 style="text-align: center; color: #243656;">{{'Please wait for your administrator to finalize your account set up, we will notify you once this is complete.' | translate}}</h4>
    </section>

    <section class="btn">
        <button (click)="closeDialog()" style="width: 180px; font-weight: bold; border-radius: 20px; background-color: #213060; color: white;" mat-raised-button class="btnLogin">{{'Ok' | translate}}</button>
    </section>
</main>