import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit
{
    message = '';
    message_ar = '';
    constructor(protected router: Router)
    {
        this.message = localStorage.getItem('maintenance_message');
        this.message_ar = localStorage.getItem('maintenance_message_ar');
    }

    ngOnInit(): void
    {

    }

    handleOK()
    {
        if (localStorage.getItem('isLoggedIn') == '1')
        {
            localStorage.clear();
            window.location.reload();
        }
        else
        {
            this.router.navigateByUrl('auth/login');
        }
    }
}
