<div>
    <mat-card>
        <div style="font-size: 10px" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10">
            <div class="tableHeading" fxFlex="30" fxFlex.sm="30" fxFlex.xs="100" fxFlexAlign="center">
                <h2 style="margin: 0; font-weight: bold; color: #213060">
                    {{ headerProps?.heading | translate }}
                </h2>
            </div>
            <div fxFlex="69" fxFlex.sm="69" fxFlex.xs="100" style="
          text-align: end;
          justify-content: end;
          display: flex;
          align-items: center;
        ">
                <ng-container *ngIf="headerProps?.hasButton">
                    <button class="btn-action-header" *ngFor="let item of headerProps?.ActionButtons"
                        (click)="onTableHeaderButton(item)" [ngStyle]="
              item.visibility ? { display: 'flex' } : { display: 'none' }
            " [disabled]="item.disabled" class="btn-table-action" mat-raised-button
                        [ngClass]="{ 'btn-table-action-selected': item.selected }">
                        <mat-icon>{{ item.icon }}</mat-icon>
                        <span fxHide.xs fxHide.sm fxHide.md>{{
                            item.label | translate
                            }}</span>
                    </button>
                </ng-container>
                <ng-container *ngIf="headerProps?.filterArray.length > 0">
                    <mat-form-field *ngFor="let filter of headerProps?.filterArray"
                        style="background-color: white; border-radius: 50px; z-index: 100" class="input-field"
                        appearance="outline" style="margin-inline: 2px">
                        <mat-label>{{ filter.label | translate }}</mat-label>
                        <mat-select [(ngModel)]="filter.selected" (selectionChange)="onChangeFilters(filter)">
                            <mat-option *ngFor="let option of filter.options" [value]="option?.value">
                                {{ option?.label | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <div *ngIf="headerProps?.filterArray.length > 0" style="width: 8px"></div>
                <ng-container *ngIf="headerProps?.hasSearch">
                    <mat-form-field class="input-field" appearance="outline">
                        <mat-label>{{ searchHeading | translate }}</mat-label>
                        <input matInput [(ngModel)]="search" (keyup)="onSearch()" />
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="headerProps?.hasMoreLinks">
                    <div>
                        <button [matMenuTriggerFor]="menuHeader" style="
                border-radius: 10px;
                background-color: #f7f8f9;
                transform: scale(0.9);
              " mat-raised-button>
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu class="action-menu" #menuHeader="matMenu" xPosition="before"
                            style="padding-inline: 10px">
                            <button class="btn-action" style="outline: none" mat-menu-item *ngFor="let item of actions"
                                [ngStyle]="
                  item.visibility ? { display: 'flex' } : { display: 'none' }
                " (click)="onTableHeaderButton(item)">
                                <mat-icon class="action-icon" *ngIf="item.icon"
                                    [style.background-color]="item.background" [style.color]="item.color"
                                    style="border-radius: 50%; color: white">{{ item.icon }}</mat-icon>
                                <img style="width: 16px" *ngIf="item.source" [src]="item.source" />
                                <span style="font-size: 12px">{{
                                    item.label | translate
                                    }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </ng-container>
            </div>
        </div>

        <table matSort matSortActive="position" matSortDirection="asc" matSortDisableClear mat-table
            [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-10">
            <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(colHeader)">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>
                    <div style="display: flex; align-items: center; color: #a3aed0">
                        <div style="margin-right: 5px">
                            {{ colHeader[tableData] | translate }}
                        </div>
                        <mat-icon *ngIf="colHeader[tableData] != ''">expand_more</mat-icon>
                    </div>

                    <!-- Checkbox for the top column -->
                    <mat-checkbox class="example-margin" *ngIf="colHeader[tableData] == ''" color="primary"
                        (change)="checkBoxAll($event.checked)" [(ngModel)]="headerCheckBoxValue"
                        [checked]="isChecked()">
                    </mat-checkbox>
                </th>

                <!-- Checkboxes for data rows -->
                <ng-container *ngIf="colHeader[tableData] == ''">
                    <td style="padding-right: 24px" mat-cell *matCellDef="let row">
                        <mat-checkbox class="example-margin" color="primary" (click)="$event.stopPropagation()"
                            (change)="checkBox($event, row)" [checked]="row['checked'] == true">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Normal column -->
                <ng-container *ngIf="
            columnTypes[tableData] === null ||
            columnTypes[tableData] === undefined
          ">
                    <td style="font-weight: bold; padding-right: 15px" mat-cell *matCellDef="let element">
                        {{ element[tableData] }}
                    </td>
                </ng-container>

                <!-- Column with number format -->
                <ng-container *ngIf="columnTypes[tableData] === 'number'">
                    <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element">
                        <!-- {{ element[tableData] | number : "1.2-2" }} -->
                        <span style="font-size: 14px;">{{element[tableData] | number:'1.2-2' | slice:0:-2}}</span>
                        <span style="font-size: 11px;">{{element[tableData] | number:'1.2-2' | slice:-2}}</span>
                    </td>
                </ng-container>

                <!-- Column with number format 4 decimal -->
                <ng-container *ngIf="columnTypes[tableData] === 'number-4'">
                    <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element">
                        <!-- {{ element[tableData] | number : "1.4-4" }} -->

                        <span style="font-size: 14px;">{{element[tableData] | number:'1.4-4' | slice:0:-4}}</span>
                        <span style="font-size: 11px;">{{element[tableData] | number:'1.4-4' | slice:-4}}</span>
                    </td>
                </ng-container>

                <!-- Column with number format 5 decimal -->
                <ng-container *ngIf="columnTypes[tableData] === 'number-5'">
                    <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element">
                        <!-- {{ element[tableData] | number : "1.5-5" }} -->

                        <span style="font-size: 14px;">{{element[tableData] | number:'1.5-5' | slice:0:-5}}</span>
                        <span style="font-size: 11px;">{{element[tableData] | number:'1.5-5' | slice:-5}}</span>
                    </td>
                </ng-container>

                <!-- Column with time format -->
                <ng-container *ngIf="columnTypes[tableData] === 'time'">
                    <td class="td-email" mat-cell *matCellDef="let element">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <mat-icon style="font-size: 22px">watch_later</mat-icon>
                            <div>{{ element[tableData] | formatTime | translate }}</div>
                        </div>
                    </td>
                </ng-container>

                <!-- Column with profile image -->
                <ng-container *ngIf="columnTypes[tableData] === 'image'">
                    <td style="font-weight: bold" class="td-icon" mat-cell *matCellDef="let element">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <img class="table-icon" src="../../assets/Avatar.png" />
                            <div>{{ element[tableData] }}</div>
                        </div>
                    </td>
                </ng-container>

                <!-- Column with mail icon -->
                <ng-container *ngIf="columnTypes[tableData] === 'email'">
                    <td class="td-email" mat-cell *matCellDef="let element">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <img class="table-icon" style="height: 11px" src="../../assets/email.png" />
                            <div>
                                <u>{{ element[tableData] }}</u>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Column with calender icon -->
                <ng-container *ngIf="columnTypes[tableData] === 'date'">
                    <td class="td-email" mat-cell *matCellDef="let element">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <img class="table-icon" style="height: 14px; cursor: default"
                                src="../../assets/images/calendar.png" />
                            <div>{{ element[tableData] }}</div>
                        </div>
                    </td>
                </ng-container>

                <!-- Column with status color -->
                <ng-container *ngIf="columnTypes[tableData] === 'statusToShow'">
                    <td class="td-email" mat-cell *matCellDef="let element" [ngStyle]="{
              color:
                element[tableData] == 'Pending'
                  ? '#E07B25'
                  : element[tableData] == 'Completed'
                  ? '#00C435'
                  : element[tableData] == 'Rejected'
                  ? '#E80F1F'
                  : element[tableData] == 'Under Process'
                  ? '#E07B25'
                  : element[tableData] == 'Declined'
                  ? '#E80F1F'
                  : '#E80F1F'
            }">
                        <div style="
                display: flex;
                flex-direction: row;
                justify-content: senter;
                align-items: center;
                font-weight: bold;
                gap: 5px;
                line-height: 40px;
              ">
                            <div style="width: 15px; height: 15px; border-radius: 50px" [ngStyle]="{
                  'background-color':
                    element[tableData] == 'Pending'
                      ? '#E07B25'
                      : element[tableData] == 'Completed'
                      ? '#00C435'
                      : element[tableData] == 'Rejected'
                      ? '#E80F1F'
                      : element[tableData] == 'Under Process'
                      ? '#E07B25'
                      : element[tableData] == 'Declined'
                      ? '#E80F1F'
                      : '#E80F1F'
                }"></div>
                            {{ element[tableData] | translate }}
                        </div>
                    </td>
                </ng-container>
                <ng-container *ngIf="columnTypes[tableData] === 'statusToPaidOFF'">
                    <td class="td-email" style="font-size: 13px; font-weight: bold" mat-cell *matCellDef="let element"
                        [ngStyle]="{
              color:
                element[tableData] == 'Pending'
                  ? '#E07B25'
                  : element[tableData] == 'PaidOff'
                  ? '#00C435'
                  : '#E07B25'
            }">
                        {{ element[tableData] | translate }}
                    </td>
                </ng-container>

                <!-- Column with error indicator -->
                <ng-container *ngIf="columnTypes[tableData] === 'errorColumn'">
                    <td mat-cell *matCellDef="let element">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <mat-icon class="error-action-icon" [ngStyle]="{
                  'background-color':
                    element[tableData] == 'Verified' ||
                    element[tableData] == 'Accepted'
                      ? '#05CD99'
                      : '#EE5D50'
                }">
                                {{
                                element[tableData] == "Verified" ||
                                element[tableData] == "Accepted"
                                ? "done"
                                : "close"
                                }}</mat-icon>
                            <span matTooltipClass="mat-tooltip-class-here" [matTooltip]="element[tableData]"
                                [matTooltipDisabled]="
                  element[tableData] == 'Verified' ||
                  element[tableData] == 'Accepted'
                " style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 140px;
                ">{{ element[tableData] }}</span>
                        </div>
                    </td>
                </ng-container>

                <!-- Actions Menu column -->
                <ng-container *ngIf="columnTypes[tableData] === 'actionsMenu'">
                    <td mat-cell *matCellDef="let element; let index = index">
                        <mat-icon style="height: auto; width: auto" [matMenuTriggerFor]="menu"
                            class="actions">more_horiz
                        </mat-icon>
                        <mat-menu class="action-menu" #menu="matMenu" xPosition="before" style="padding-inline: 10px">
                            <button class="btn-action" style="outline: none" mat-menu-item *ngFor="let item of actions"
                                [ngStyle]="
                  item.visibility &&
                  element &&
                  (element[item.type] || !item.isCustom)
                    ? { display: 'flex' }
                    : { display: 'none' }
                " (click)="onRowActionClicked(element, item, index)">
                                <mat-icon class="btn-action-icon" *ngIf="item.icon"
                                    [style.background-color]="item.background" [style.color]="item.color">{{ item.icon
                                    }}</mat-icon>
                                <img class="btn-action-image" *ngIf="item.source" [src]="item.source" />
                                <span class="btn-action-text" [style.color]="item.color">{{
                                    item.label | translate
                                    }}</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <!-- Actions Seperate column -->
                <ng-container *ngIf="columnTypes[tableData] === 'actionsSeperate'">
                    <td mat-cell *matCellDef="let element; let index = index" style="display: flex; margin-left: 5px">
                        <button class="btn-action" *ngFor="let item of actions" [ngStyle]="
                item.visibility &&
                element &&
                (element[item.type] || !item.isCustom)
                  ? { display: 'flex' }
                  : { display: 'none' }
              " (click)="onRowActionClicked(element, item, index)" [style.background-color]="item.background">
                            <mat-icon class="btn-action-icon" *ngIf="item.icon"
                                [style.background-color]="item.background" [style.color]="item.color">{{ item.icon
                                }}</mat-icon>
                            <img class="btn-action-image" *ngIf="item.source" [src]="item.source" />
                            <span class="btn-action-text" [style.color]="item.color">{{
                                item.label | translate
                                }}</span>
                        </button>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container *ngIf="isExpandable" matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="objectKeys(colHeader).length - 1">
                    <div class="example-element-detail" [@detailExpand]="
              element == expandedElement || allRowsExpanded
                ? 'expanded'
                : 'collapsed'
            ">
                        <table style="width: 100%">
                            <tr>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Date of Birth
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Nationality
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Gender
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    ID Expiry Date
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Address
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Designation
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Dual Nationality
                                </th>
                                <th style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                  ">
                                    Group
                                </th>
                            </tr>
                            <mat-divider *ngIf="element == expandedElement"></mat-divider>
                            <tr>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.date_of_birth }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.nationality }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.gender }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.national_id_or_iqama_expiry }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.address }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.designation }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.dual_nationality_holder }}
                                </td>
                                <td style="font-weight: bold; font-size: 11px">
                                    {{ element.group }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="objectKeys(colHeader)"></tr>
            <ng-container *ngIf="!isExpandable">
                <tr mat-row *matRowDef="let row; columns: objectKeys(colHeader)"></tr>
            </ng-container>
            <ng-container *ngIf="isExpandable">
                <tr mat-row *matRowDef="
            let row;
            columns: objectKeys(colHeader);
            let i = dataIndex
          " class="example-element-row" [class.example-expanded-row]="expandedElement === row">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </ng-container>
        </table>

        <div class="norecord" *ngIf="dataSource.length === 0">
            {{ "No records found" | translate }}
        </div>
    </mat-card>

    <tp-pagination class="mt-20" (setPage)="setPagination($event)" [pagination]="pagination"></tp-pagination>
</div>