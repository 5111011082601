import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  pushMsgRefresh = new Subject<any>();
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  fbConf = {
    apiKey: "AIzaSyDl2iwPtjhVM38VFhB9ft4S1O6utYoY7iA",
    authDomain: "thriftplan-a9ceb.firebaseapp.com",
    projectId: "thriftplan-a9ceb",
    storageBucket: "thriftplan-a9ceb.appspot.com",
    messagingSenderId: "924032047378",
    appId: "1:924032047378:web:60be70f08c02d06236bbcb",
    measurementId: "G-2HCNY7SPCY",
  };
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  constructor() {}

  init() {
    if (!firebase.apps.length) firebase.initializeApp(this.fbConf);
  }

  getPermission() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(function () {
        navigator.serviceWorker
          .register("firebase-messaging-sw.js")
          .then(function (registration) {
            messaging.useServiceWorker(registration);
            messaging
              .getToken()
              .then((currentToken) => {
                if (currentToken) {
                  localStorage.removeItem("deviceToken");
                  localStorage.setItem("deviceToken", currentToken);
                } else {
                  // Show permission request.
                }
              })
              .catch((err) => {});
          });
      })
      .catch(function (err) {
        return false;
      });
  }

  receiveMessage() {
    this.init();
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      this.pushMsgRefresh.next(payload);
    });
    // messaging.onBac
  }
}
