import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { VideoDialog } from "../video-dialog/video-dialog";

@Component({
  selector: "app-lessons",
  templateUrl: "./lessons.component.html",
  styleUrls: ["./lessons.component.scss"],
})
export class LessonsComponent implements OnInit {
  list: any = [];
  course: any;
  searchTimer: any;
  search: any;
  sort_by: any = null;
  colHeader = {
    id: "Chapter ID",
    title: "Title",
    duration: "Duration",
    viewed: "Viewed",
    actions: "Actions",
  };
  columnTypes = {
    created_at: "date",
    revised_on: "date",
    duration: "time",
    actions: "actionsSeperate",
  };
  headingData: string = "Courses";
  searchHeading: string = "Search by Course ID";
  headerProps = {
    heading: "Course Chapters",
    hasSearch: false,
    hasButton: false,
    filterArray: [],
  };
  actions = [
    {
      label: "View Video",
      type: "output",
      visibility: true,
      isCustom: false,
      background: "#4318FF0D",
      source: "assets/table-view.svg",
    },
  ];
  paginations = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0,
  };
  id: any;

  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    public route: Router,
    protected mainService: ApiService,
    public dec_enc: DecEncService,
    protected _route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this._route.params.subscribe((params) => {
      this.id = params["id"];
    });
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    let count = 1;
    if (this.paginations.per_page != null) {
      count = this.paginations.per_page * (this.paginations.page - 1) + 1;
    }
    let data = {
      id: this.id,
    };
    let enc_payload = this.dec_enc.encryption(data);
    let url = `academy/courses`;
    this.mainService
      .postData(url, { enc_payload })
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data;
          let result = this.dec_enc.decryption(data);
          this.paginations = result.pagination;
          this.course = result.courses[0];
          this.list = result.courses[0]?.lessons;
          this.afterList();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  onLike() {
    let data = {
      course_id: this.course.id,
      liked: this.course.course_user_likes.length > 0 ? "0" : "1",
    };
    let enc_payload = this.dec_enc.encryption(data);
    this.mainService
      .postData("academy/courses/like", { enc_payload })
      .then((res) => {
        if (res.statusCode == 200) {
          this.getList();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  actionClicked(event) {
    if (event.action.label == "View Video") {
      let dialogRef = this.dialog.open(VideoDialog, {
        autoFocus: true,
        hasBackdrop: false,
        maxHeight: "90vh",
        width: "95%",
      });
      const params = new URL(event.element.link).searchParams;
      dialogRef.componentInstance.currentVideoId = params.get("v");
      dialogRef.componentInstance.course_id = event.element.course_id;
      dialogRef.componentInstance.lesson_id = event.element.id;

      dialogRef.afterClosed().subscribe((res) => {
        this.getList();
      });
    }
  }

  afterList() {
    this.list.forEach((item) => {
      item["View"] = true;
      item["viewed"] = item?.course_user_lessons?.length > 0 ? "Yes" : "No";
    });
  }

  setPage(page) {
    this.paginations.page = page;
    this.getList();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getList();
    }, 800);
  }

  onFilters(event) {
    this.sort_by = event.selected;
    this.getList();
  }
}
