import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-risk-servay",
  templateUrl: "./risk-servay.component.html",
  styleUrls: ["./risk-servay.component.scss"],
})
export class RiskServayComponent implements OnInit {
  question: any;
  answers: any = [];
  status: any;
  change: any = 1;
  total: any;
  list: any = [];
  index: any = 0;
  data: any = {};
  lovs_questions_answers: any = [];
  postAnswer: any = [null];
  risk_type: any;
  allocate: any;
  translation: string;
  titleArabic: boolean = true;
  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    public route: Router,
    protected dialog: MatDialog,
    protected mainService: ApiService,
    public dec_enc: DecEncService
  ) {
  }

  ngOnInit(): void {
    this.tr.selectedApp.subscribe((state) => {
      this.tr.translation = state;
      this.translation = localStorage.getItem("lang");
      this.getall();
      this.onCheckAllocatedFund();
    });
    this.translation = localStorage.getItem("lang");
    this.getall();
    this.onCheckAllocatedFund();
  }

  answer(text, val) {
    this.status = val;
    this.data = {};
    this.data.question_id = this.list[this.index].id;
    this.data.subject_type = this.list[this.index].subject_type;
    if (this.translation == "ar") {
      this.titleArabic = false;
      this.data.weightage =
        this.list[this.index].lovs_survey_question_answers[
          this.status
        ].weightage;
      this.lovs_questions_answers[this.index] = this.data;
      this.data = [];
      this.postAnswer[this.index] = val;
    } else {
      this.titleArabic = true;
      this.data.weightage =
        this.list[this.index].lovs_survey_question_answers[
          this.status
        ].weightage;
      this.lovs_questions_answers[this.index] = this.data;
      this.data = [];
      this.postAnswer[this.index] = val;
    }
  }

  next() {
    this.status = null;
    if (this.index + 1 < this.total) {
      this.index = this.index + 1;
    } else {
      let url = "lovs/submit_question_answers";
      let data = { lovs_questions_answers: this.lovs_questions_answers };
      let enc_payload = this.dec_enc.encryption(data);
      this.mainService
        .postData(url, { enc_payload })
        .then((res) => {
          let data;
          if (res.statusCode == 200) {
            data = this.dec_enc.decryption(res.data);
            this.risk_type = data.recommended_risk;
            localStorage.setItem("employee_recomended_risk", this.risk_type);
            this.onCheckAllocatedFund();
            this.route.navigate(["main/risk-appetite-survey/recomended_fund"]);
            return;
          }
          this.alertService.alertAr("Error", res);
        })
        .catch((error) => {
          this.alertService.alert(
            this.tr.translation.error,
            this.tr.translation.serverError
          );
        });
    }
  }

  previous() {
    this.index = this.index - 1;
    this.status = this.postAnswer.pop();
    this.status = null;
  }

  getall() {
    let url = "lovs/list_survey_questions";
    this.mainService
      .postData(url, {})
      .then((res) => {
        let result;
        if (res.statusCode == 200) {
          let data = res.data;
          result = this.dec_enc.decryption(data);
          if (this.translation == "ar") {
            this.titleArabic = false;
            this.list = result.survey_questions;
            this.total = result.survey_questions.length;
          } else {
            this.titleArabic = true;
            this.list = result.survey_questions;
            this.total = result.survey_questions.length;
          }
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert(
          this.tr.translation.error,
          this.tr.translation.serverError
        );
      });
  }

  onCheckAllocatedFund() {
    let url = "funds/allocated";
    this.mainService
      .postData(url, {})
      .then((res) => {
        let result;
        if (res.statusCode == 200) {
          let data = res.data;
          result = this.dec_enc.decryption(data);
          this.allocate = result.employee_fund;
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert(
          this.tr.translation.error,
          this.tr.translation.serverError
        );
      });
  }
}
