<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-10"
  ngClass.xs="mrl-10"
>
  <mat-card>
    <ng-container>
      <div class="px-5">
        <div class="text-center mt-3">
          <img src="assets/ic_question.png" alt="" style="width: 50px" />
          <h4 class="mt-2">
            {{ "ques" | translate }} {{ index + 1 }}/{{ total }}
          </h4>
          <p *ngIf="titleArabic" class="question mx-1">
            {{ list[index]?.title }}
          </p>
          <p *ngIf="!titleArabic" class="question mx-1">
            {{ list[index]?.title_arb }}
          </p>
        </div>
        <div
          *ngIf="titleArabic"
          fxLayout="row wrap"
          fxLayoutAlign="space-between center"
          class="mt-5"
        >
          <ng-container
            *ngFor="
              let item of list[index]?.lovs_survey_question_answers;
              let i = index
            "
            style="background-color: white"
          >
            <div
              fxFlex="47"
              fxFlex.sm="100"
              fxFlex.xs="100"
              [class.answer-togle]="i === postAnswer[index]"
              class="mt-3 answer"
              (click)="answer(item.option, i)"
            >
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="2" fxFlex.sm="2" fxFlex.xs="2">
                  <span class="round">
                    <span class="number">{{ i + 1 }}</span>
                  </span>
                </div>

                <div
                  fxFlex="95"
                  fxFlex.sm="95"
                  fxFlex.xs="95"
                  style="padding-top: 10px; font-size: 13px; font-weight: 500"
                >
                  {{ item.option }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="!titleArabic"
          fxLayout="row wrap"
          fxLayoutAlign="space-between center"
          class="mt-5"
        >
          <ng-container
            *ngFor="
              let item of list[index]?.lovs_survey_question_answers;
              let i = index
            "
            style="background-color: white"
          >
            <div
              fxFlex="47"
              [class.answer-togle]="i === postAnswer[index]"
              class="mt-3 answer"
              (click)="answer(item.option_arb, i)"
            >
              <div>
                <span class="round">
                  <span class="number">{{ i + 1 }}</span>
                </span>

                {{ item.option_arb }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around center" class="mt-4">
        <div fxFlex="30">
          <button
            class="btn btn-ok-sec"
            (click)="previous()"
            [ngClass]="index == 0 ? 'button-disabled' : 'button'"
            [disabled]="index == 0"
          >
            {{ "previous" | translate }}
          </button>
        </div>
        <div fxFlex="30" class="text-right">
          <button
            (click)="next()"
            [disabled]="status == null"
            class="btn btn-ok"
          >
            {{ "next" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>
