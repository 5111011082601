<div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="end start"
    style="margin-bottom: 10px"
  >
    <mat-icon style="height: 20px; cursor: pointer" (click)="onClose()"
      >close</mat-icon
    >
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12" style="min-height: 450px">
      <div *ngIf="loading" style="z-index: 9999999999999; margin: 0 auto">
        <div
          class="row"
          style="
            width: 142px;
            height: 118px;
            background: aliceblue;
            margin: 0 auto;
            position: relative;
            top: 40%;
            border-radius: 10px;
          "
        >
          <div style="text-align: center" class="col-sm-12">
            <img
              style="margin-top: 10px"
              width="50"
              height="50"
              src="assets/view-loader.gif"
            />
            <h5>{{tr.translation.loading}}</h5>
          </div>
        </div>
      </div>
      <youtube-player
        width="100%"
        height="100%"
        *ngIf="!loading"
        [videoId]="currentVideoId"
        [playerVars]="playerVars"
        (ready)="savePlayer($event)"
        (change)="onStateChange($event)"
      >
      </youtube-player>
    </div>
  </div>
</div>
