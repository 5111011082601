import { Injectable } from "@angular/core";

@Injectable()
export class DecEncService {
  key: any;
  constructor() {
    this.key = localStorage.getItem("employee_password_token");
  }

  encryption(data) {
    let password = this.key?.toString();
    let phrase = JSON.stringify(data);
    let result;
    try {
      result = CryptoJS.AES.encrypt(phrase, password).toString().trim() || "{}";
    } catch (e) {
      ("{}");
    }
    return result;
  }

  encryptionAuth(data) {
    let key = localStorage.getItem("password_token");
    let password = key?.toString();
    let phrase = JSON.stringify(data);
    let result;
    try {
      result = CryptoJS.AES.encrypt(phrase, password).toString().trim() || "{}";
    } catch (e) {
      ("{}");
    }
    return result;
  }

  decryption(data) {
    let password = this.key;
    let Data = data;
    let result;
    try {
      result = JSON.parse(
        CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(Data, password))
          .toString()
          .trim() || "{}"
      );
    } catch (e) {
      return "{}";
    }
    return result;
  }

  decryptionAuth(data) {
    let key = localStorage.getItem("password_token");
    let password = key;
    let Data = data;
    let result;
    try {
      result = JSON.parse(
        CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(Data, password))
          .toString()
          .trim() || "{}"
      );
    } catch (e) {
      return "{}";
    }
    return result;
  }
}
