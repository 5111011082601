<div
  class="main pt-1 mrl-20 mt-30 mb-20"
  ngClass.sm="mrl-20"
  ngClass.xs="mrl-20"
>
  <div fxLayout="row wrap">
    <app-table
      fxFlex="100"
      [searchHeading]="searchHeading"
      [headerProps]="headerProps"
      [headingData]="headingData"
      (searchData)="searchData($event)"
      [dataSource]="list"
      [columnTypes]="columnTypes"
      [colHeader]="colHeader"
      [actions]="actions"
      (setPage)="setPage($event)"
      [pagination]="paginations"
      (actionClicked)="actionClicked($event)"
      (emitFilters)="onFilters($event)"
    >
    </app-table>
  </div>
</div>
