import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertDialog } from "src/app/lib/alert-dialog/alert.dialog";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
  selector: "app-risk-screen",
  templateUrl: "./risk-screen.component.html",
  styleUrls: ["./risk-screen.component.scss"],
})
export class RiskScreenComponent implements OnInit {
  risk: any = "Low";
  list: any = [];
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  recomended_risk: any;
  select_fund: any;
  allocatedFund: any;
  constructor(
    public tr: TranslationService,
    protected alertSrv: AlertService,
    public route: Router,
    public dec_enc: DecEncService,
    protected mainService: ApiService,
    public paginationService: PaginationService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getall(1);
    this.onCheckAllocatedFund();
  }

  onSelectFund(
    id,
    risk // this.allocatedFund.
  ) {
    if (this.allocatedFund && this.allocatedFund.fund_id != id) {
      let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
      let cm = dialogRef.componentInstance;
      cm.methodName = "funds/re_allocate";
      cm.heading = this.tr.translation.alert;
      cm.message = "Are you sure you want to change your risk profile?";
      cm.submitButtonText = this.tr.translation.yes;
      cm.cancelButtonText = this.tr.translation.no;
      cm.risk = true;
      cm.type = "ask";
      cm.fund_id = id;
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          let url = "funds/allocate";
          let enc_payload = this.dec_enc.encryption({
            fund_id: this.allocatedFund.fund_id,
          });
          this.mainService.postData(url, { enc_payload });
          this.route.navigate(["main/manage_portfolio"]);
          window.location.reload();
        }
      });
    } else if (this.allocatedFund && this.allocatedFund.filled_as != "manual") {
      let url = "funds/allocate";
      let enc_payload = this.dec_enc.encryption({ fund_id: id });
      this.mainService.postData(url, { enc_payload });
      this.alertSrv.alert("Success", "Your fund is selected successfully");
      this.route.navigate(["main/manage_portfolio"]);
      window.location.reload();
    } else {
      let url = "funds/allocate";
      let enc_payload = this.dec_enc.encryption({ fund_id: id });
      this.mainService.postData(url, { enc_payload }).then(
        (res) => {
          if (res.statusCode == 200) {
            let result;
            let data = res.data;
            result = this.dec_enc.decryption(data);
            this.alertSrv.alert(
              "Success",
              "Your fund is selected successfully"
            );
            this.route.navigate(["main/manage_portfolio"]);
            window.location.reload();
          } else {
            this.alertSrv.alertAr("Error", res);
          }
        },
        (error) => {
          this.alertSrv.alertAr("Error", this.tr.translation.serverError);
        }
      );
    }
  }

  getall(index: any) {
    let url = "funds/available?per_page=" + this.perPage + "&page=" + index;
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          let result;
          let data = res.data;
          result = this.dec_enc.decryption(data);
          this.list = result.funds;
          this.count = result.pagination.count;
          this.currentPage = result.pagination.page;
          this.pages = this.paginationService.setPagination(
            result.pagination.count,
            this.currentPage,
            this.perPage
          );
          this.totalPages = this.pages.totalPages;
        }
      },
      (error) => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = this.tr.translation.error;
        cm.message = this.tr.translation.serverError;
        cm.cancelButtonText = this.tr.translation.okay;
        cm.type = "error";
      }
    );
  }

  onCheckAllocatedFund() {
    let url = "funds/allocated";
    this.mainService.postData(url, {}).then(
      (res) => {
        if (res.statusCode == 200) {
          let result;
          let data = res.data;
          result = this.dec_enc.decryption(data);
          this.recomended_risk = result.recommendation.recommended;
          this.select_fund = result?.employee_fund?.fund?.id;
          this.allocatedFund = result?.employee_fund;
        }
      },
      (error) => {
        this.alertSrv.alertAr("Error", this.tr.translation.serverError);
      }
    );
  }
}
