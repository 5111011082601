import { Component, OnInit } from "@angular/core";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { DatePipe } from "@angular/common";
import { AlertService } from "src/app/services/alert.service";

@Component({
    selector: "app-contribution-view",
    templateUrl: "./contribution-view.component.html",
    styleUrls: ["./contribution-view.component.scss"],
})
export class ContributionViewComponent implements OnInit
{
    Form: FormGroup;
    alertMsg: any = "";
    sub: Subscription;
    id: any;

    constructor(
        public datepipe: DatePipe,
        public tr: TranslationService,
        public alertService: AlertService,
        protected formbuilder: FormBuilder,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        protected mainService: ApiService,
        protected dec_enc: DecEncService
    )
    {
        this._route.params.subscribe((params) =>
        {
            this.id = params["id"];
        });
        this.Form = this.formbuilder.group({
            date: ["", [Validators.required]],
            employee_contribution_amount: ["0.0000", [Validators.required]],
            employer_contribution_amount: [
                "0.0000",
                [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)],
            ],
            vested_amount: [
                "0.0000",
                [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)],
            ],
            un_vested_amount: [
                "0.0000",
                [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)],
            ],
            status: ["", [Validators.required]],
            active: ["1", [Validators.required]],
            employee_name: ["", [Validators.required]],
        });
    }

    ngOnInit(): void
    {
        this.getContribution();
    }

    ngOnDestroy(): void { }

    getContribution()
    {
        this.mainService.postData("contributions", {}).then((res) => {
            let result;
            if (res.statusCode == 200) {
                result = this.dec_enc.decryption(res.data);
                this.setContributionValues(result.contributions);
                return;
            }
            this.alertService.alertAr("Error", res);
        })
        .catch((error) =>
        {
            this.alertService.alert("Error", this.tr.translation.serverError);
        });
    }

    setContributionValues(list)
    {
        list.forEach((element) =>
        {
            if (element.id == this.id) {
                if (element.status == 1) {
                    element.statusEnAr = "Accepted";
                }
                if (element.status == 2) {
                    element.statusEnAr = "Declined";
                }
                if (element.status == 0) {
                    element.statusEnAr = "Pending";
                }
                let date = new Date(element.created_at);
                element.employee_contribution_amount = isNaN(Number(element?.employee_contribution_amount)) ? Number(0).toFixed(4) : Number(element?.employee_contribution_amount).toFixed(4);
                element.employer_contribution_amount = isNaN(Number(element?.employer_contribution_amount)) ? Number(0).toFixed(4) : Number(element?.employer_contribution_amount).toFixed(4);
                element.vested_amount = isNaN(Number(element?.vested_amount)) ? Number(0).toFixed(4) : Number(element?.vested_amount).toFixed(4);
                element.un_vested_amount = isNaN(Number(element?.un_vested_amount)) ? Number(0).toFixed(4) : Number(element?.un_vested_amount).toFixed(4);
                this.Form.patchValue(element);
                console.log('detail', element)
                this.Form.get("employee_name").setValue(
                    element?.employer_employee?.user?.name
                );
                this.Form.get("date").setValue(
                    this.datepipe.transform(date, "dd-MM-yyyy")
                );
                this.Form.get("status").setValue(element?.statusEnAr);
            }
        });
    }

    back()
    {
        window.history.back();
    }
}
