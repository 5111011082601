import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
    selector: "app-tp-notifications",
    templateUrl: "./tp-notifications.component.html",
    styleUrls: ["./tp-notifications.component.scss"],
})
export class TpNotificationsComponent implements OnInit
{
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    key: any;
    NotificationIds: any[] = [];
    pagination = {
        page: 1,
        pages: null,
        per_page: null,
        count: 0,
    };

    constructor(
        public tr: TranslationService,
        public route: Router,
        protected mainService: ApiService,
        protected dec_enc: DecEncService,
        protected paginationService: PaginationService,
        protected dialog: MatDialog,
        protected alertService: AlertService
    )
    {
        this.key = localStorage.getItem("password_token");
    }

    ngOnInit(): void
    {
        this.getNotifications();
    }

    getNotifications()
    {
        let count = 1;
        if (this.pagination.per_page != null) {
            count = this.pagination.per_page * (this.pagination.page - 1) + 1;
        }
        let url = `home/notifications?per_page=10&sort_order=asc&page=${this.pagination.page}`;
        this.mainService
            .postData(url, {})
            .then((res) =>
            {
                if (res.statusCode == 200) {
                    let result = this.dec_enc.decryption(res.data);
                    this.list = result.notifications;
                    this.pagination = result.pagination;
                    this.countValue();
                    return;
                }
                this.alertService.alertAr("Error", res);
            })
            .catch((error) =>
            {
                this.alertService.alert("Error", error.message);
            });
    }

    setPage(page: any)
    {
        this.currentPage = page;
        this.getNotifications();
    }

    countValue()
    {
        this.NotificationIds = [];
        this.list.forEach((element) =>
        {
            this.NotificationIds.push(element.id);
        });
        let data = {
            ids: this.NotificationIds.join(","),
            type: "notifications",
        };
        let payload = this.dec_enc.encryption(data);
        this.mainService
            .postData("home/read_notifications", { enc_payload: payload })
            .then((res) =>
            {
                if ((res.statusCode = 200)) {
                    this.tr.setApiOnCall(1);
                    this.NotificationIds = [];
                    return;
                }
                this.alertService.alertAr("Error", res);
            })
            .catch((error) =>
            {
                this.alertService.alert("Error", error.message);
            });
    }

    markAsRead()
    {
        let body = {
            type: 'notifications'
        };
        let payload = this.dec_enc.encryption(body);
        this.mainService.readNotifications(`read_all_notifications`, { enc_payload: payload }).then(response =>
        {
            if (response.statusCode == 200) {
                this.mainService.setApiOnCall(1);
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.toastSuccess('النجاح', 'تم تمييز جميع الإشعارات كمقروءة.');
                }
                else {
                    this.alertService.toastSuccess('Success', 'All announcements have been marked as read.');
                }
                // this.alertService.toastSuccess('Success', 'All notifications have been marked as read.');
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }
}
