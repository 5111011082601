<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <mat-card>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-top: 20px">
            <div fxFlex="48" fxFlex.sm="100" fxFlex.md="100" class="card-set tableHeading" style="margin-left: 25px">
                <form [formGroup]="Form" style="margin-right: 10px">
                    <div class="form-field">

                        <mat-form-field fxFlex="65" fxFlex.xs="100" fxFlex.sm="100" style="width: 100%"
                            class="input-field mt-10" fxFlexAlign="center" appearance="outline">
                            <mat-label>{{ "Salary" | translate }}</mat-label>

                            <div style="font-weight: bold; color: #79C0C2;" [ngClass]="editModes.salary ? 'hide' : 'show'"
                                [style.color]="salary || salary == 0 ? 'black' : 'transparent'"
                                (click)="setMode('salary', true)" (focus)="setMode('salary', true)">
                                <!-- {{salary | number:'1.4-4'}} -->
                                <span>{{salary | number:'1.4-4' | slice:0:-4}}</span>
                                <span style="font-size: 11px;">{{salary | number:'1.4-4' | slice:-4}}</span>
                            </div>

                            <input autocomplete="off" type="text" matInput formControlName="salary" [(ngModel)]="salary"
                            [ngClass]="editModes.salary == true ? 'show' : 'hide-input'"
                            (focusout)="setMode('salary', false)" (focus)="setMode('salary', true)">
                        </mat-form-field>

                    </div>
                    <div class="form-field mt-20">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"
                            style="font-size: 18px; font-weight: 600">
                            <h4 fxFlex="53" fxFlex.xs="100" fxFlex.sm="100">
                                {{ "monthlyContribution" | translate }}
                            </h4>
                            <h4 fxFlex>{{ MatchingContribution }}%</h4>
                        </div>

                        <input type="range" class="slider" id="volume" [disabled]="Form.invalid"
                            (change)="Contribution($event)" min="1" max="100" value="0" />
                    </div>
                    <div class="form-field" style="margin-top: 20px">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"
                            style="font-size: 18px; font-weight: 600">
                            <h4 fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
                                {{ "Risk_type" | translate }}
                            </h4>
                            <h4 fxFlex>{{ mainRisk | translate }}</h4>
                        </div>
                        <input type="range" class="slider" id="volume" [disabled]="Form.invalid" (change)="Risk($event)"
                            min="0" value="0" max="100" step="50" list="tickmarks" />
                        <datalist id="tickmarks">
                            <option value="0" label="{{'Low' | translate}}"></option>
                            <option value="50" label="{{'Medium' | translate}}"></option>
                            <option value="100" label="{{'High' | translate}}"></option>
                        </datalist>
                    </div>
                </form>
            </div>
            <div fxFlex="48" fxFlex.sm="100" fxFlex.md="100" class="card-set">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-top: 20px">
                    <div fxFlex="48" fxFlex.sm="50">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between top">
                            <div fxFlex="8" style="height: 22px; background-color: #8ebfe8"></div>
                            <h4 fxFlex="90">{{ "AccumulativeReturn" | translate }}</h4>
                        </div>
                    </div>
                    <h4 fxFlex="48" fxFlex.sm="50" style="font-size: 14px; font-weight: 600">
                        {{ "SAR" | translate }} {{ tr.numberFormatter(listSum?.sum_ebvm) }}
                    </h4>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-top: 20px">
                    <div fxFlex="48" fxFlex.sm="50">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between top">
                            <div fxFlex="8" style="height: 22px; background-color: #c5daec"></div>
                            <h4 fxFlex="90">{{ "Vested_return" | translate }}</h4>
                        </div>
                    </div>
                    <h4 fxFlex="48" fxFlex.sm="50" style="font-size: 14px; font-weight: 600">
                        {{ "SAR" | translate }}{{ tr.numberFormatter(listSum?.sum_ecvm) }}
                    </h4>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-top: 20px">
                    <div fxFlex="48" fxFlex.sm="50">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between top">
                            <div fxFlex="8" style="height: 22px; background-color: #5391d9"></div>
                            <h4 fxFlex="90">
                                {{ "Total_Employee_Contribution" | translate }}
                            </h4>
                        </div>
                    </div>
                    <h4 fxFlex="48" fxFlex.sm="50" style="font-size: 14px; font-weight: 600">
                        {{ "SAR" | translate }}
                        {{ tr.numberFormatter(listSum?.employee_contribution) }}
                    </h4>
                </div>
            </div>
        </div>

        <div class="mt-3 mb-4"></div>
        <div id="container"></div>
    </mat-card>
    <div style="
      color: #ff707a;
      background-color: #ffc2c2;
      font-size: medium;
      font-weight: 500;
      text-align: center;
    " class="mb-2 mt-4">
        {{ "calcutaorend" | translate }}
    </div>
</div>