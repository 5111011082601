import { OnInit, Component, AfterViewInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { DecEncService } from "src/app/services/dec-enc";
import { Router } from "@angular/router";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";
import { AlertDialog } from "src/app/lib/alert-dialog/alert.dialog";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "video-dialog",
  templateUrl: "./video-dialog.html",
  styleUrls: ["./video-dialog.scss"],
})
export class VideoDialog implements OnInit, AfterViewInit {
  currentVideoId: string;
  course_id: string;
  lesson_id: string;
  private player;
  public ytEvent;
  playerVars = {
    cc_lang_pref: "en",
    autoplay: 1,
    modestbranding: 1,
    controls: 1,
    disablekb: 1,
    rel: 1,
    showinfo: 1,
    fs: 1,
    playsinline: 1,
    loop: 1,
    allownetworking: 1,
    origin: "http://localhost:4200/",
  };

  loading: boolean = true;

  constructor(
    protected apiService: ApiService,
    public enc_dec: DecEncService,
    protected dialogRef: MatDialogRef<AlertDialog>,
    protected dialog: MatDialog,
    public route: Router,
    public tr: TranslationService,
    protected alertService: AlertService,
    protected profileService: ProfileService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loading = false;
      this.setView();
    }, 2000);
  }

  ngOnInit() {}

  setView() {
    let enc_payload = this.enc_dec.encryption({
      course_id: this.course_id,
      lesson_id: this.lesson_id,
    });
    this.apiService
      .postData("academy/courses/lesson/view", { enc_payload })
      .then(
        (res) => {
          if (res.statusCode == 200) {
            return;
          }
        },
        (error) => {
        }
      );
  }

  savePlayer(player) {
    this.player = player;
  }

  onStateChange(event) {
    this.ytEvent = event.data;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onResponce(): void {
    this.dialogRef.close(true);
  }

  onClose() {
    if (!this.loading) {
      this.dialogRef.close(true);
    }
  }
}
