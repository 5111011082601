<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxLayout="column" fxFlex="59">
        <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs" />
        <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
            <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
                <h1>{{ "Welcome to the future of employee benefits" | translate }}</h1>
            </span>
        </span>
    </div>

    <div class="login-form" ngClass.sm="login-form-sm" ngClass.xs="login-form-xs" fxLayout="column"
        fxLayoutAlign="center center" style="background: #f7f8f9">
        <img src="assets/thrift_logo.png" alt="Thriftplan Logo" width="200px" fxFlexOffset="-8" fxFlexOffset.xs="0" fxFlexOffset.sm="-8" />
        <h1 fxFlexOffset="3" style="font-weight: 600; font-size: 25px; margin-bottom: 0">
            {{ "Login as Employee" | translate }}
        </h1>
        <h6 style="margin-top: 5px">
            {{
            showOtpComponent
            ? ("Please enter your 6 digit code here to login" | translate)
            : ("Enter your details to get started" | translate)
            }}
        </h6>

        <form fxFlexOffset="-2" ngClass.xs="form-xs" ngClass.sm="form-sm" *ngIf="showCredentials" [formGroup]="Form"
            fxLayout="column">
            <mat-form-field fxFlexOffset="8" style="width: 100%" class="input-field" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{ "Username" | translate }}</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="adminUsername" />
                <mat-icon *ngIf="getFieldValue('adminUsername')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('adminUsername', 'Username')"
                    [matTooltipDisabled]="!getFieldValue('adminUsername')" matTooltipPosition="above"
                    style="color: #213060" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field style="margin-block: 10px; width: 100%" class="input-field" fxFlexAlign="center"
                appearance="outline">
                <mat-label>{{ "Password" | translate }}</mat-label>
                <input autocomplete="off" [type]="showPassword ? 'text' : 'password'" matInput
                    formControlName="adminPassword" />
                <mat-icon *ngIf="!getFieldValue('adminPassword')" matSuffix (click)="togglePasswordVisibility()"
                    style="color: #213060" #tooltip="matTooltip" [matTooltip]="
            showPassword
              ? ('Hide Password' | translate)
              : ('Show password' | translate)
          ">
                    {{ showPassword ? "visibility" : "visibility_off" }}
                </mat-icon>
                <mat-icon *ngIf="getFieldValue('adminPassword')" #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('adminPassword', 'Password')"
                    [matTooltipDisabled]="!getFieldValue('adminPassword')" matTooltipPosition="above"
                    style="color: #213060" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <div style="display: flex; flex-direction: column-reverse">
                <div fxFlexOffset="5" style="width: 100%" fxLayout="row" fxLayoutAlign="end">
                    <button style="width: 120px; font-weight: bold" (click)="login()" class="btnLogin"
                        mat-raised-button>
                        {{ "Login" | translate }}<mat-icon>arrow_forward</mat-icon>
                    </button>
                </div>
                <div style="width: 100%" fxFlexAlign="space-between" fxLayoutAlign="start baseline">
                    <button (click)="navigateToForgot()" mat-button style="
              padding: 0;
              border: none;
              outline: none;
              color: #213060;
              font-size: 11px;
              font-weight: bold;
              margin-right: 10px;
            ">
                        {{ "ForgotPassword" | translate }}?
                    </button>
                </div>
            </div>
        </form>
        <ng-container *ngIf="showOtpComponent">
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
            </ng-otp-input>
            <div style="margin-top: 10px">{{ timerValue }}</div>
            <button (click)="resendOTP()" mat-button style="padding: 0; border: none; outline: none"
                [ngStyle]="timerRunning?{'color': 'red'} : {'color': '#212529'}">
                {{ "ResendOTP" | translate }}
            </button>
        </ng-container>
        <div class="footer" ngClass.sm="footer-sm" ngClass.xs="footer-xs" fxLayoutAlign="space-between baseline">
            <p>{{ "2023 Thriftplan. All Rights Reserved" | translate }}</p>
        </div>
    </div>
</div>