import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslationService } from "../translation/translation.service";
import { ApiLoaderService } from "./api-loader.service";
import { LoaderState } from "./loader";

@Component({
  selector: "api-loader",
  templateUrl: "./api-loader.component.html",
  styleUrls: ["./api-loader.component.css"],
})
export class ApiLoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;

  constructor(
    private loaderService: ApiLoaderService,
    private _changeDetectorRef: ChangeDetectorRef,
    public tr: TranslationService
  ) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
