<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxLayout="column" fxFlex="59" ngClass.sm="d-none" ngClass.xs="d-none">
        <img src="assets/back-img.png" alt="Back Ground Image" class="img" ngClass.sm="img-sm" ngClass.xs="img-xs" />
        <span class="back-img" ngClass.sm="back-img-sm" ngClass.xs="back-img-xs" fxLayoutAlign="center">
            <span fxLayout="column" fxFlexOffset="8" fxFlexOffset.sm="0" fxLayoutGap="50">
                <h1>{{ "Welcome to the future of employee benefits" | translate }}</h1>
            </span>
        </span>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-between" style="background: white; flex-grow: 1;">

        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 15%;">
            <img src="assets/thrift_logo.png" alt="Thriftplan Logo" width="200px"/>
            <p style="color: #999999;">{{'Transforming Social Security in MENA' | translate}}</p>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
            <h3 style="font-weight: 600; color: #455560; font-size: 20px; margin: 0;">{{'Welcome!' | translate}}</h3>
            <h3 [innerHTML]="'Your employer companyName has invited you to complete self onboarding process' | translate: {companyName: companyName}" *ngIf="companyName" style="color: #999999; text-align: center; width: 80%;"></h3>
        </div>

        <div fxLayoutAlign="center center" style="margin-bottom: 10%;">
            <button (click)="getStarted()" style="width: 180px; font-weight: bold; border-radius: 20px;" mat-raised-button class="btnLogin">{{'Get Started' | translate}}</button>
        </div>

    </div>
</div>