<div class="main" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
  <div fxLayout="column" fxFlex="59">
    <img
      src="assets/back-img.png"
      alt="Back Ground Image"
      class="img"
      ngClass.sm="img-sm"
      ngClass.xs="img-xs"
    />
    <span
      class="back-img"
      ngClass.sm="back-img-sm"
      ngClass.xs="back-img-xs"
      fxLayoutAlign="center"
    >
      <span
        fxLayout="column"
        fxFlexOffset="8"
        fxFlexOffset.sm="0"
        fxLayoutGap="50"
        class="tableHeading"
      >
        <h1>{{ "Welcome to the future of employee benefits" | translate }}</h1>
      </span>
    </span>
  </div>

  <div
    class="login-form"
    ngClass.sm="login-form-sm"
    ngClass.xs="login-form-xs"
    fxLayout="column"
    fxLayoutAlign="center center"
    style="background: #f7f8f9"
  >
    <img
      src="assets/thrift_logo.png"
      alt="Thriftplan Logo"
      width="200px"
      fxFlexOffset="-16"
      fxFlexOffset.sm="-8"
      fxFlexOffset.xs="8"
    />
    <h1 fxFlexOffset="3">{{ "ForgotPassword" | translate }}</h1>
    <h6 fxFlexOffset="-3" fxFlexOffset.sm="0">
      {{ "passwordRecoverText" | translate }}
    </h6>

    <form
      [formGroup]="Form"
      ngClass.xs="form-xs"
      ngClass.sm="form-sm"
      fxLayout="column"
      fxLayoutGap="10"
      style="margin-block: 20px"
    >
      <mat-form-field
        class="input-field"
        fxFlexAlign="center"
        appearance="outline"
      >
        <mat-label>{{ "EnterEmail" | translate }}</mat-label>
        <input type="text" matInput formControlName="email" />
        <mat-icon
          *ngIf="getFieldValue('email')"
          #tooltip="matTooltip"
          [matTooltip]="getErrorMessage('email', 'Email')"
          [matTooltipDisabled]="!getFieldValue('email')"
          matTooltipPosition="above"
          style="color: #213060"
          matSuffix
          >info
        </mat-icon>
      </mat-form-field>
    </form>

    <div
      style="margin-top: 10px"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <button
        (click)="sendOTP()"
        class="btn-auth-screens"
        ngClass.sm="btnLogin-sm"
        ngClass.xs="btnLogin-xs"
        mat-raised-button
      >
        {{ "SendOTP" | translate }}
      </button>
      <button
        routerLink="../login"
        class="btn-auth-screens"
        ngClass.sm="btnLogin-sm"
        ngClass.xs="btnLogin-xs"
        mat-raised-button
      >
        {{ "Cancel" | translate }}
      </button>
    </div>

    <div
      class="footer"
      ngClass.sm="footer-sm"
      ngClass.xs="footer-xs"
      fxLayoutAlign="space-between baseline"
    >
      <p>{{ "2023 Thriftplan. All Rights Reserved" | translate }}</p>
    </div>
  </div>
</div>
