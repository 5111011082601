import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
  selector: "app-manage-contribution",
  templateUrl: "./manage-contribution.component.html",
  styleUrls: ["./manage-contribution.component.scss"],
})
export class ManageContributionComponent implements OnInit {
  list: any = [];
  searchTimer: any;
  search: any;
  sort_by: any = null;
  colHeader = {
    id: "Contribution ID",
    csv_id: "CSV ID",
    employee_contribution_amount: "My Contribution (SAR)",
    employer_contribution_amount: "Company Contribution (SAR)",
    sum_emp_emr_cont: "Total Contribution (SAR)",
    statusToShow: "Status",
    created_at: "Created Date",
    actions: "Actions",
  };
  columnTypes = {
    employer_contribution_amount: "number-4",
    employee_contribution_amount: "number-4",
    sum_emp_emr_cont: "number-4",
    created_at: "date",
    updated_at: "date",
    statusToShow: "statusToShow",
    actions: "actionsSeperate",
  };
  headingData: string = "Manage Contributions";
  searchHeading: string = "Search by Contributions ID";
  headerProps = {
    heading: "Manage Contributions",
    hasSearch: true,
    hasButton: false,
    ActionButtons: [
      {
        label: "Create Contributions Request",
        route: "manage_withdrawls/add",
        type: "link",
        visibility: true,
        isCustom: false,
        icon: "person_add",
      },
    ],
    filterArray: [
      {
        label: "Sort by",
        type: "filter_list",
        key: "sort_by",
        visibility: true,
        selected: "default",
        options: [
          {
            value: "",
            label: "All",
            key: "1",
          },
          {
            value: "1",
            label: "Completed",
            key: "1",
          },
          {
            value: "0",
            label: "Pending",
            key: "0",
          },
          {
            value: "2",
            label: "Rejected",
            key: "2",
          },
          {
            value: "3",
            label: "UnderProcess",
            key: "3",
          },
        ],
      },
    ],
  };
  actions = [
    {
      label: "View Contributions",
      type: "output",
      visibility: true,
      isCustom: false,
      background: "#4318FF0D",
      source: "assets/table-view.svg",
    },
  ];
  paginations = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0,
  };

  constructor(
    public tr: TranslationService,
    public alertService: AlertService,
    public route: Router,
    protected mainService: ApiService,
    public dec_enc: DecEncService,
    protected paginationService: PaginationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getContributionList();
  }

  view(id) {
    this.route.navigate(["main/manage-contribution/view/" + id]);
  }

  getContributionList() {
    let count = 1;
    if (this.paginations.per_page != null) {
      count = this.paginations.per_page * (this.paginations.page - 1) + 1;
    }
    let data = {
      search: this.search ? this.search : null,
      status: this.sort_by != -1 && this.sort_by != null ? this.sort_by : null,
    };
    let enc_payload = this.dec_enc.encryption(data);
    let url = `contributions?per_page=${this.paginations.per_page}&sort_order=DESC&page=${this.paginations.page}`;
    this.mainService
      .postData(url, { enc_payload })
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data;
          let result = this.dec_enc.decryption(data);
          this.paginations = result.pagination;
          this.list = result.contributions;
          this.afterList();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  actionClicked(event) {
    if (event.action.label == "View Contributions") {
      this.view(event.element.id);
    }
  }

  afterList() {
    this.list.forEach((item) => {
      item["View"] = true;
      item["created_at"] = moment(item["created_at"]).format("DD-MM-YYYY");
      if (item.status == 0) {
        item["statusToShow"] = "Pending";
      }
      if (item.status == 1) {
        item["statusToShow"] = "Completed";
      }
      if (item.status == 2) {
        item["statusToShow"] = "Rejected";
      }
      if (item.status == 3) {
        item["statusToShow"] = "UnderProcess";
      }
    });
  }

  setPage(page) {
    this.paginations.page = page;
    this.getContributionList();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getContributionList();
    }, 800);
  }

  onFilters(event) {
    this.sort_by = event.selected;
    this.getContributionList();
  }
}
