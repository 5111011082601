import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "../../services/api.service";
import { TranslationService } from "../translation/translation.service";

@Component({
  selector: "alert-dialog",
  templateUrl: "./alert.dialog.html",
})
export class AlertDialog implements OnInit {
  isLoading: boolean;
  heading: string = "";
  message: string = "";
  type: "ask" | "success" | "error" | "info";
  cancelButtonText: string;
  submitButtonText: string;
  dataToSubmit: any;
  methodName: any;
  methodType: any;
  showLoading: boolean;
  risk: boolean = false;
  fund_id: any;
  mainService: any;

  constructor(
    protected mainApiService: ApiService,
    public tr: TranslationService,
    protected dialogRef: MatDialogRef<AlertDialog>,
    protected dialog: MatDialog,
    public route: Router,
    private dec_enc: DecEncService
  ) {
    this.isLoading = false;
    this.showLoading = false;
  }

  ngOnInit() {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick() {
    let message = "";
    if (this.methodName == "funds/allocate") {
      message = this.tr.translation.fundSelect;
    } else {
      message = this.tr.translation.fundRequst;
    }
    this.dialogRef.close(true);
    let enc_payload = this.dec_enc.encryption({ fund_id: this.fund_id });
    this.mainApiService.postData(this.methodName, { enc_payload }).then(
      (res) => {
        if (res.statusCode == 200) {
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = this.tr.translation.success;
          cm.message = message;
          cm.cancelButtonText = this.tr.translation.okay;
          cm.type = "success";
          this.mainApiService.view.next(true);
        }
        if (res.statusCode == 208) {
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = this.tr.translation.error;
          cm.message = res.message;
          cm.cancelButtonText = this.tr.translation.okay;
          cm.type = "error";
        }
        if (res.status == 404) {
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = this.tr.translation.error;
          cm.message = this.tr.translation.serverError;
          cm.cancelButtonText = this.tr.translation.okay;
          cm.type = "error";
        }
      },
      (error) => {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = this.tr.translation.error;
        cm.message = this.tr.translation.serverError;
        cm.cancelButtonText = this.tr.translation.okay;
        cm.type = "error";
      }
    );
  }

  onSubmitClick(): void {
    this.isLoading = true;
    if (this.methodType == "get") {
      this.mainApiService.getList(this.methodName).then((result) => {
        if (result.status === 200) {
          this.dialogRef.close(true);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = "Error";
          cm.message = "Internal Server Error";
          cm.cancelButtonText = "Ok";
          cm.type = "error";
        }
      });
    } else if (this.methodType == "delete") {
      this.mainApiService.delete(this.methodName).then((result) => {
        if (result.status === 200) {
          this.dialogRef.close(true);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = "Error";
          cm.message = "Internal Server Error";
          cm.cancelButtonText = "Ok";
          cm.type = "error";
        }
      });
    } else if (this.methodType == "ask") {
      this.dialogRef.close(true);
    } else if (this.methodType == "patch") {
      var formData = new FormData();
      this.mainApiService.patch(this.methodName, formData).then((result) => {
        if (result.status === 200) {
          this.dialogRef.close(true);
          this.isLoading = false;
        } else {
          this.isLoading = false;
          let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
          let cm = dialogRef.componentInstance;
          cm.heading = "Error";
          cm.message = "Internal Server Error";
          cm.cancelButtonText = "Ok";
          cm.type = "error";
        }
      });
    } else {
      this.mainApiService
        .postData(this.methodName, this.dataToSubmit)
        .then((result) => {
          if ((result.status === 200 || result.status === 201) && result.data) {
            this.dialogRef.close(true);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = "Error";
            cm.message = "Internal Server Error";
            cm.cancelButtonText = "Ok";
            cm.type = "error";
          }
        });
    }
  }
}
