import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { RegisterCompleteDialogComponent } from './register-complete-dialog/register-complete-dialog.component';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit
{
    currentIndexOfStepper = 0;
    personalDetailsGroup: FormGroup;
    userDetailsGroup: FormGroup;
    employmentDataGroup: FormGroup;
    @ViewChild('picker1') datePicker1: MatDatepicker<Date>;
    @ViewChild('picker2') datePicker2: MatDatepicker<Date>;
    currentDate = new Date();
    checkboxChecked = false;
    passwordType: string = 'password';
    confirmPasswordType: string = 'password';
    isArabic = false;

    constructor(protected dialog: MatDialog,
        protected router: Router,
        protected alertService: AlertService, 
        protected authService: AuthService)
    {
        this.personalDetailsGroup = new FormGroup({
            name: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]),
            employer_id: new FormControl(authService.employerIdForRegister),
            gender: new FormControl(null),
            date_of_birth: new FormControl(null),
            nationality: new FormControl(null, [Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]),
            iqama_id: new FormControl(null, [Validators.required, Validators.pattern('[0-9]+[0-9]*'), Validators.minLength(10), Validators.maxLength(10) ]),
        })
        this.userDetailsGroup = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(null, [Validators.required, Validators.pattern('[1-9]+[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]),
            password: new FormControl(null, Validators.required),
            confirm_password: new FormControl(null, Validators.required),
        })
        this.employmentDataGroup = new FormGroup({
            position: new FormControl(null),
            monthly_salary: new FormControl(null, Validators.pattern('[1-9]+[0-9]*')),
            employee_since: new FormControl(null),
        })
        this.isArabic = localStorage.getItem("lang") == "ar" ? true : false;
    }

    ngOnInit(): void
    {
        if (!this.authService.employerIdForRegister) {
            this.alertService.alert('Error', 'Please verify your invite again.').then(res => {
                history.back();
            })
        }
    }

    onStepChange(event)
    {
        this.currentIndexOfStepper = event.selectedIndex;
    }

    onNext()
    {
        if (this.currentIndexOfStepper == 0) {
            if (this.personalDetailsGroup.valid) {
                this.currentIndexOfStepper++;
            }
            else {
                this.personalDetailsGroup.markAllAsTouched();
            }
            return
        }
        if (this.currentIndexOfStepper == 1 && (this.userDetailsGroup.get('password').value != this.userDetailsGroup.get('confirm_password').value )) {
            this.alertService.alert('Error', 'Passwords do not match!');
            return;
        }
        if (this.currentIndexOfStepper == 1) {
            if (this.userDetailsGroup.valid) {
                this.currentIndexOfStepper++;
            }
            else {
                this.userDetailsGroup.markAllAsTouched();
            }
            return
        }
        if (this.currentIndexOfStepper == 2)
        {
            let formData = { ...this.personalDetailsGroup.value, ...this.userDetailsGroup.value, ...this.employmentDataGroup.value };
            let payload = {
                enc_password: localStorage.getItem('password_token'),
                enc_payload: this.authService.encryptionAuth(formData)
            }
            this.authService.auth('self-onboarding', payload).then(response => {
                if (response.statusCode == 200) {
                    let dialogRef = this.dialog.open(RegisterCompleteDialogComponent, { autoFocus: false, width: '350px', height: 'auto', hasBackdrop: true, panelClass: this.isArabic ? 'arabic-lang' : 'english-lang' });
                    let cm = dialogRef.componentInstance;
                    dialogRef.afterClosed().subscribe(res => {
                        this.router.navigateByUrl('auth/login');
                    })
                }
                else {
                    this.alertService.alert('Error', localStorage.getItem('lang') == 'ar' ? response.message_ar : response.message, '');
                }
            })
        }
    }

    openDatePicker(picker)
    {
        if (picker == 1) {
            this.datePicker1.open();
        }
        else {
            this.datePicker2.open();
        }
    }

    handlePassword(passwordType)
    {
        if (passwordType == 'password')
        {
            if (this.passwordType == 'text')
            {
                this.passwordType = 'password';
            }
            else
            {
                this.passwordType = 'text';
            }
        }
        else
        {
            if (this.confirmPasswordType == 'text')
            {
                this.confirmPasswordType = 'password';
            }
            else
            {
                this.confirmPasswordType = 'text';
            }
        }
    }

    getFieldValue(form, field) {
        if (form.get(field).touched) {
            if (form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(form, control, message) {
        if (form.get(control).invalid) {
            if (form.get(control).hasError('required'))
            return message;
            if (form.get(control).hasError('pattern'))
            return 'Please type in the correct format.'
            if (form.get(control).hasError('minlength') && control == 'iqama_id')
            return 'National ID/Iqama should be 10 characters long.'
            if (form.get(control).hasError('maxlength') && control == 'iqama_id')
            return 'National ID/Iqama should be 10 characters long.'
            if (form.get(control).hasError('minlength') && control == 'company_phone')
            return 'Mobile Number should be 9 characters long.'
            if (form.get(control).hasError('maxlength') && control == 'company_phone')
            return 'Mobile Number should be 9 characters long.'
            if (form.get(control).hasError('email'))
            return 'Email should be in correct format.'
        }
    }
}
