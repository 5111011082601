import { Injectable, NgModule } from "@angular/core";
import { Routes, RouterModule, Resolve } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthGuard } from "src/guards/auth.guard";
import { MainAuthGuard } from "src/guards/main-auth.guard";
import { RoleGuard } from "src/guards/role.guard";
import { TermsGuard } from "src/guards/terms.guard";
import { ProfileComponent } from "./views/employee/profile/profile.component";
import { ManagePortfolioComponent } from "./views/employee/manage-portfolio/manage-portfolio.component";
import {
  ManageWithdrawlsComponent,
  WithdrawlsFormComponent,
  WithdrawlsViewComponent,
} from "./views/employee/manage-withdrawals";
import { ManageContributionComponent } from "./views/employee/contributions/manage-contribution.component";
import { ContributionViewComponent } from "./views/employee/contributions/contribution-view.component";
import { RiskServayComponent } from "./views/employee/risk-servay/risk-form/risk-servay.component";
import { RiskScreenComponent } from "./views/employee/risk-servay/risk-screen/risk-screen.component";
import { ReallocateFundComponent } from "./views/employee/reallocate-fund/reallocate-fund.component";
import { RiskChartComponent } from "./views/employee/risk-servay/risk-chart/risk-chart.component";
import { ReturnCalculatorComponent } from "./views/employee/return-calculator/return-calculator.component";
import { TpNotificationsComponent } from "./views/employee/tp-notifications/tp-notifications.component";
import { ActivityNotificationsComponent } from "./views/employee/activity-notifications/activity-notifications.component";
import { HelpSupportComponent } from "./views/employee/help-support/help-support.component";
import { LoginComponent } from "./views/auth/login/login.component";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";
import { CreateNewPasswordComponent } from "./views/auth/create-new-password/create-new-password.component";
import { TemplateMainComponent } from "./templates/template-main.component";
import { EmployeeFormNewComponent } from "./views/employee/profile/employee-form-new/employee-form-new.component";
import { TermsComponent } from "./views/employee/terms_conditions/terms.component";
import { ProfileService } from "./services/profile.service";
import { Observable } from "rxjs-compat";
import { AcademyComponent } from "./views/employee/academy/academy.component";
import { LessonsComponent } from "./views/employee/academy/lessons/lessons.component";
import { MaintenanceComponent } from "./views/employee/maintenance/maintenance.component";
import { WelcomeComponent } from "./views/auth/welcome/welcome.component";
import { RegisterComponent } from "./views/auth/register/register.component";

@Injectable()
export class ProfileResolver implements Resolve<any> {
  constructor(private ps: ProfileService) {}

  resolve(): Observable<any> {
    return this.ps.loadProfile();
  }
}

const publicRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "recovery-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: CreateNewPasswordComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "welcome", component: WelcomeComponent },
  { path: "register", component: RegisterComponent },
];
const termsApp: Routes = [
  { path: "", redirectTo: "termsandconditions", pathMatch: "full" },
  { path: "termsandconditions", component: TermsComponent },
];
const mainApp: Routes = [
  { path: "", redirectTo: "manage_portfolio", pathMatch: "full" },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "basic-detail",
    component: EmployeeFormNewComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "manage_portfolio",
    component: ManagePortfolioComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all", "1"] },
  },
  {
    path: "manage_withdrawls",
    component: ManageWithdrawlsComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "manage_withdrawls/add",
    component: WithdrawlsFormComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "manage_withdrawls/view/:id",
    component: WithdrawlsViewComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "notification",
    component: TpNotificationsComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "help-support",
    component: HelpSupportComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "activity-notifications",
    component: ActivityNotificationsComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "manage-contribution",
    component: ManageContributionComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "manage-contribution/view/:id",
    component: ContributionViewComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  { path: "risk-appetite-survey", component: RiskServayComponent },
  {
    path: "risk-appetite-survey/recomended_fund",
    component: RiskScreenComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "risk-appetite-survey/risk-servay",
    component: RiskChartComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "reallocate-funds",
    component: ReallocateFundComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "reallocate-funds/fund-detail/:id",
    component: RiskChartComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "risk-tolerance-calculator",
    component: ReturnCalculatorComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "academy-courses",
    component: AcademyComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "academy-courses/course/:id",
    component: LessonsComponent,
    canActivate: [RoleGuard],
    data: { roles: ["all"] },
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
  },
];

const appRoutes: Routes = [
  { path: "", redirectTo: "/auth/login", pathMatch: "full" },
  {
    path: "auth",
    component: AppComponent,
    children: publicRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: "terms",
    component: AppComponent,
    children: termsApp,
    canActivate: [TermsGuard],
    resolve: {
      data: ProfileResolver,
    },
  },
  {
    path: "main",
    component: TemplateMainComponent,
    children: mainApp,
    canActivate: [MainAuthGuard],
    resolve: {
      data: ProfileResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
  providers: [ProfileResolver],
})
export class AppRoutingModule {}
