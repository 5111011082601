import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class TokenService {
  selectedToken: Subject<any> = new Subject<any>();
  Token: any;

  constructor() {}

  setToken(val) {
    this.Token = val;
    this.selectedToken.next(val);
    localStorage.setItem("employee_token", val);
  }

  getToken() {
    if (this.Token != null) {
      return this.Token;
    } else if (localStorage.getItem("employee_token")) {
      return localStorage.getItem("employee_token");
    }
  }
}
