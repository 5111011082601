<div class="main pt-1 mrl-20 mt-30" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <app-card fxFlex="19" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-balance.png"
            topText="{{ 'Total Portfolio Balance (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.summary?.total_portfolio_balance" [error]="false">
        </app-card>
        <app-card fxFlex="19" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/total-units.png"
            topText="{{ 'Total Contributions (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.summary?.total_contribution" [error]="false">
        </app-card>
        <app-card fxFlex="19" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/employee-cotribution.png"
            topText="{{ 'My Contribution (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.summary?.employee_contribution" [error]="false">
        </app-card>
        <app-card fxFlex="19" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/company-cotribution.png"
            topText="{{ 'Company Contribution (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.summary?.company_contribution" [error]="false">
        </app-card>
        <app-card fxFlex="19" fxFlex.md="32" fxFlex.sm="49" fxFlex.xs="99" imgCustom="assets/new-icons/TotalProfit.png"
            topText="{{ 'Total Profit/Loss (SAR)' | translate }}" [middleTextNumberFour]="dashboardData?.summary?.profit" [error]="false">
        </app-card>
        <div fxFlex.md="32" fxHide.xs fxHide.sm fxHide.lg fxHide.xl fxHide.xxl></div>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <!-- pie chart -->
        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{ "PortfolioBreakdown" | translate }}</h3>
            </div>
            <mat-card id="SpecialClass" ngStyle.xs="top:20%">
                <h4 style="color: #213060">{{ "SAR" | translate }}</h4>
                <h2 style="
            font-weight: 700;
            font-size: 20px;
            color: #213060;
            margin-bottom: 0px;">
                    <!-- {{ dashboardData?.portfolio_breakdown?.total_portfolio_balance ? (dashboardData?.portfolio_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.00' }} -->

                    <span class="number">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
                    <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
                </h2>
                <p style="font-weight: 500; font-size: 10px; color: #a3aed0">
                    {{ "Total_Portfolio_Balance" | translate }}
                </p>
            </mat-card>
            <div style="
          margin-left: 34%;
          height: 24vh;
          position: relative;
          z-index: 9999;
        " ngStyle.sm="margin-left: 40%;height: 13vh;" ngStyle.xs="margin-left: 44%;height: 13vh;">
                <canvas baseChart height="100" [chartType]="'pie'" [datasets]="portfolioPieChartData"
                    [labels]="portfolioPieChartLabels" [legend]="pieChartLegend">
                </canvas>
            </div>
            <mat-card fxLayoutAlign="space-between" fxLayout="row" fxLayoutAlign.sm="space-around center"
                [fxLayoutGap]="'24'" [style]="
          'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);position: relative;z-index: 9999;'
        ">
                <span>
                    <p style="font-size: 10px; color: #a3aed0; margin-bottom: 0px">
                        {{ "Employee Contribution (SAR)" | translate }}
                    </p>
                    <h2 style="font-weight: 700; font-size: 18px">
                        <!-- {{ dashboardData?.portfolio_breakdown?.employee_contribution ? (dashboardData?.portfolio_breakdown?.employee_contribution | number : '1.4-4') : '0.00' }} -->
                        
                        <span class="number">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.employee_contribution | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height: 40px" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px; color: #a3aed0; margin-bottom: 0px">
                        {{ "Company Contribution (SAR)" | translate }}
                    </p>
                    <h2 style="font-weight: 700; font-size: 18px">
                        <!-- {{ dashboardData?.portfolio_breakdown?.company_contribution ? (dashboardData?.portfolio_breakdown?.company_contribution | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.company_contribution | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height: 40px" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px; color: #a3aed0; margin-bottom: 0px">
                        {{ "Total Profit/Loss (SAR)" | translate }}
                    </p>
                    <h2 style="font-weight: 700; font-size: 18px">
                        <!-- {{ dashboardData?.portfolio_breakdown?.profit ? (dashboardData?.portfolio_breakdown?.profit | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.portfolio_breakdown?.profit | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
            </mat-card>
        </mat-card>
        <!-- pie chart end -->

        <!-- pie chart -->
        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{ "VestingBreakdown" | translate }}</h3>
            </div>
            <mat-card id="SpecialClass" ngStyle.xs="top:20%">
                <h4 style="color: #213060">{{ "SAR" | translate }}</h4>
                <h2 style="
            font-weight: 700;
            font-size: 20px;
            color: #213060;
            margin-bottom: 0px;">
                    <!-- {{ dashboardData?.vest_breakdown?.total_portfolio_balance ? (dashboardData?.vest_breakdown?.total_portfolio_balance | number : '1.4-4') : '0.00' }} -->

                    <span class="number">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:0:-4 }}</span> 
                    <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.total_portfolio_balance | number:'1.4-4' | slice:-4 }}</span>
                </h2>
                <p style="font-weight: 500; font-size: 10px; color: #a3aed0">
                    {{ "Total_Portfolio_Balance" | translate }}
                </p>
            </mat-card>
            <div style="
          margin-left: 34%;
          height: 24vh;
          position: relative;
          z-index: 9999;
        " ngStyle.sm="margin-left: 40%;height: 13vh;" ngStyle.xs="margin-left: 44%;height: 13vh;">
                <canvas baseChart height="100" [chartType]="'pie'" [datasets]="vestingPieChartData"
                    [labels]="vestingPieChartLabels" [legend]="pieChartLegend">
                </canvas>
            </div>
            <mat-card fxLayoutAlign="space-around" fxLayout="row" fxLayoutAlign.sm="space-around center"
                [fxLayoutGap]="'24'" [style]="
          'padding: 10px 26px; height: 80px;border-radius: 15px;margin-top:4%; box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12); position: relative;z-index: 9999;position: relative;z-index: 9999;'
        ">
                <span>
                    <p style="font-size: 10px; color: #a3aed0; margin-bottom: 0px">
                        {{ "Vested (SAR)" | translate }}
                    </p>
                    <h2 style="font-weight: 700; font-size: 18px">
                        <!-- {{ dashboardData?.vest_breakdown?.vested ? (dashboardData?.vest_breakdown?.vested | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
                <mat-divider vertical style="height: 40px" fxFlexAlign="center"></mat-divider>
                <span>
                    <p style="font-size: 10px; color: #a3aed0; margin-bottom: 0px">
                        {{ "Unvested (SAR)" | translate }}
                    </p>
                    <h2 style="font-weight: 700; font-size: 18px">
                        <!-- {{ dashboardData?.vest_breakdown?.unvested ? (dashboardData?.vest_breakdown?.unvested | number : '1.4-4') : '0.00' }} -->

                        <span class="number">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:0:-4 }}</span> 
                        <span style="font-size: 14px;">{{ dashboardData?.vest_breakdown?.unvested | number:'1.4-4' | slice:-4 }}</span>
                    </h2>
                </span>
            </mat-card>
        </mat-card>
        <!-- pie chart end -->
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3"
            ngStyle.sm="margin-bottom:20px;" ngStyle.xs="margin-bottom:14px;">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{ "Employee_Contribution_Detail" | translate }}</h3>
            </div>
            <table class="table table-hover table-borderless mt-3" style="position: relative; z-index: 9999">
                <tbody>
                    <tr>
                        <td>
                            <p>{{ "Fund_Name" | translate }}</p>
                            <h3>
                                {{
                                tr.capitalizeFirstLetter(
                                dashboardData?.employee_fund ? dashboardData?.employee_fund?.portfolio : "N/A"
                                )
                                }}
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Risk_type" | translate }}</p>
                            <h3 *ngIf="dashboardData?.employee_fund?.risk_type == 'low'" style="color: #00c435">
                                {{ "low" | translate }}
                            </h3>
                            <h3 *ngIf="dashboardData?.employee_fund?.risk_type == 'medium'" style="color: #e69a5a">
                                {{ "medium" | translate }}
                            </h3>
                            <h3 *ngIf="dashboardData?.employee_fund?.risk_type == 'high'" style="color: #ec4955">
                                {{ "high" | translate }}
                            </h3>
                            <h3 *ngIf="!dashboardData?.employee_fund?.risk_type">N/A</h3>
                        </td>
                        <td>
                            <p>{{ "Investment_Bank" | translate }}</p>
                            <h3>
                                {{
                                tr.capitalizeFirstLetter(
                                dashboardData?.employee_fund
                                ? dashboardData?.employee_fund?.bank_name
                                : "N/A"
                                )
                                }}
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Invested_Amount" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.employee_fund?.invested_amount ? (dashboardData?.employee_fund?.invested_amount | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ "ProfitTotal" | translate }}</p>

                            <h3 *ngIf="dashboardData?.employee_fund?.profit && dashboardData?.employee_fund?.profit > 0"
                                style="color: #00c435">
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                            <h3 *ngIf="dashboardData?.employee_fund?.profit && dashboardData?.employee_fund?.profit <= 0"
                                style="color: red">
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Available_Withdrawal_Amount" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.employee_fund?.available ? (dashboardData?.employee_fund?.available | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.available | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.employee_fund?.available | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Total_Withdrawan_Amount" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.employee_fund?.withdrawn ? (dashboardData?.employee_fund?.withdrawn | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bg-card-bottom"></div>
        </mat-card>

        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3"
            ngStyle.sm="margin-bottom:20px;" ngStyle.xs="margin-bottom:14px;">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{ "Company Contribution Detail" | translate }}</h3>
            </div>
            <table class="table table-hover table-borderless mt-3" style="position: relative; z-index: 9999">
                <tbody>
                    <tr>
                        <td>
                            <p>{{ "Fund_Name" | translate }}</p>
                            <h3>
                                {{
                                tr.capitalizeFirstLetter(
                                dashboardData?.company_fund ? dashboardData?.company_fund?.portfolio : "N/A"
                                )
                                }}
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Risk_type" | translate }}</p>
                            <h3 *ngIf="dashboardData?.company_fund?.risk_type == 'low'" style="color: #00c435">
                                {{ "low" | translate }}
                            </h3>
                            <h3 *ngIf="dashboardData?.company_fund?.risk_type == 'medium'" style="color: #e69a5a">
                                {{ "medium" | translate }}
                            </h3>
                            <h3 *ngIf="dashboardData?.company_fund?.risk_type == 'high'" style="color: #ec4955">
                                {{ "high" | translate }}
                            </h3>
                            <h3 *ngIf="!dashboardData?.company_fund?.risk_type">N/A</h3>
                        </td>
                        <td>
                            <p>{{ "Investment_Bank" | translate }}</p>
                            <h3>
                                {{
                                tr.capitalizeFirstLetter(
                                dashboardData?.company_fund
                                ? dashboardData?.company_fund?.bank_name
                                : "N/A"
                                )
                                }}
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Invested_Amount" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}

                                <!-- {{ dashboardData?.company_fund?.invested_amount ? (dashboardData?.company_fund?.invested_amount | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>{{ "ProfitTotal" | translate }}</p>
                            <h3 *ngIf="dashboardData?.company_fund && dashboardData?.company_fund?.profit > 0"
                                style="color: #00c435">
                                {{ "SAR" | translate }}
                                <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->

                                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                            <h3 *ngIf="dashboardData?.company_fund && dashboardData?.company_fund?.profit <= 0"
                                style="color: red">
                                {{ "SAR" | translate }}
                                <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->

                                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Vested" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.company_fund?.vested ? (dashboardData?.company_fund?.vested | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.vested | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.company_fund?.vested | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                        <td>
                            <p>{{ "Unvested" | translate }}</p>
                            <h3>
                                {{ "SAR" | translate }}
                                <!-- {{ dashboardData?.company_fund?.unvested ? (dashboardData?.company_fund?.unvested | number : '1.4-4') : '0.0000' }} -->

                                <span style="font-size: 14px;">{{ dashboardData?.company_fund?.unvested | number:'1.4-4' | slice:0:-4 }}</span> 
                                <span style="font-size: 11px;">{{ dashboardData?.company_fund?.unvested | number:'1.4-4' | slice:-4 }}</span>
                            </h3>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bg-card-bottom"></div>
        </mat-card>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="14" class="mrl-20 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3"
            ngStyle.sm="margin-bottom:20px;" ngStyle.xs="margin-bottom:14px;">
            <!-- <div class="bg-card-top"></div> -->
            <div fxLayout="row">
                <h3>{{'Employee Portfolio Detail' | translate}}</h3>
            </div>
            <div *ngIf="dashboardData">
                <app-table [dataSource]="dashboardData?.employee_assets" [columnTypes]="columnTypesEmployee"
                    [colHeader]="employeeHeader">
                </app-table>
            </div>
            <!-- <div class="bg-card-bottom"></div> -->
        </mat-card>

        <mat-card class="mt-20" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3"
            ngStyle.sm="margin-bottom:20px;" ngStyle.xs="margin-bottom:14px;">
            <div class="bg-card-top"></div>
            <div fxLayout="row">
                <h3>{{'Company Portfolio Detail' | translate}}</h3>
            </div>
            <div *ngIf="dashboardData">
                <app-table [dataSource]="dashboardData?.company_assets" [columnTypes]="columnTypesCompany"
                    [colHeader]="companyHeader">
                </app-table>
            </div>
            <!-- <div class="bg-card-bottom"></div> -->
        </mat-card>
    </div>
</div>

<!-- <div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">

</div> -->




<!-- <div fxLayout="column" fxLayoutGap="14" class="mrl-20 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20" fxLayoutAlign="space-between">
        
        <mat-card class="mt-20" fxFlex="100" fxFlex.sm="98" fxFlex.xs="94" fxFlexOffset.xs="3"
            ngStyle.sm="margin-bottom:20px;" ngStyle.xs="margin-bottom:14px;">
            <div class="bg-card-top"></div>
            <div style="padding-bottom: 20px" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxFlex="49" fxFlex.sm="98" fxFlex.xs="94" fxFlexAlign="center">
                    <h3>{{ "Yearly_Profit_Report" | translate }}</h3>
                </div>
                <form [formGroup]="Form" fxLayout="row wrap" fxFlex="49" fxFlex.sm="100" fxFlex.xs="100" style="
            text-align: end;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: end;
            position: relative;
            z-index: 9999;
          ">
                    <mat-form-field fxFlex="49" fxFlex.sm="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{ "Start_Date" | translate }}</mat-label>
                        <input matInput formControlName="start" [matDatepicker]="picker" (dateChange)="dateChange()" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex="49" fxFlex.sm="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{ "End_Date" | translate }}</mat-label>
                        <input matInput type="text" formControlName="end" [matDatepicker]="end" [min]="minDate"
                            [disabled]="isDateDisabled" (dateChange)="dateChange()" />
                        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                        <mat-datepicker #end></mat-datepicker>
                    </mat-form-field>
                </form>
            </div>
            <canvas style="position: relative; z-index: 9999" baseChart [chartType]="lineChartType"
                [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                [legend]="lineChartLegend" [colors]="lineChartColors" height="100">
            </canvas>
            <div class="bg-card-bottom"></div>
        </mat-card>
        
    </div>
</div> -->