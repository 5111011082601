import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit
{
    LOGIN_KEY = "login_status";
    companyName = '';

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected alertService: AlertService,
        protected apiService: ApiService,
        protected authService: AuthService
        )
    {
        console.log('route', route)
    }

    ngOnInit(): void
    {
        this.startCall();
    }

    getStarted()
    {
        this.router.navigateByUrl('auth/register');
    }

    startCall() {
        localStorage.removeItem(this.LOGIN_KEY);
        this.authService.getStart("start").then((res) => {
          if (res.statusCode == 200) {
            localStorage.setItem("password_token", res.data.access_token);
            if (this.route.snapshot.queryParams.code) {
                this.verifyHash(this.route.snapshot.queryParams.code);
            }
            else {
                this.alertService.alert('Error', 'No Hash found');
                this.router.navigateByUrl('auth/login');
            }
            return;
          }
        });
    }

    verifyHash(hash)
    {
        let url = `verify-invite`;
        let body = {
            hash: hash
        };
        let data = {
            enc_payload: this.authService.encryptionAuth(body),
            enc_password: localStorage.getItem("password_token"),
        };
        this.authService.auth(url, data).then(response => {
            if (response.statusCode == 200) {
                let decrypted = this.authService.decryptionAuth(response.data);
                if (decrypted) {
                    this.companyName = decrypted.company_name;
                    this.authService.employerIdForRegister = decrypted.id;
                }
                console.log('decoded hash', decrypted);
                // this.alertService.alert('Success', 'Invite verified!');
            }
            else if (response.statusCode == 402) {
                this.alertService.alert('Error', 'Invalid URL.')
            }
            else {
                this.alertService.alert('Error', response.message);
            }
        })
    }
}
