<div
  [class.logged-out]="!isLoggedIn"
  fxLayoutAlign="center center"
  style="
    border-radius: 30px;
    padding: 4px 15px;
    background-color: white;
    margin: 10px;
    color: #213060;
    font-size: 12px;
    box-shadow: 14px 17px 40px 4px #7090b014;
  "
  ngStyle.sm="margin:10px;"
>
  <b style="margin-inline: 10px">English</b>
  <mat-slide-toggle
    [checked]="isChecked"
    (change)="toggle($event)"
    class="example-margin"
    disabled="false"
  >
    <span style="font-family: cairo !important">عربي</span>
  </mat-slide-toggle>
</div>
