import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-register-complete-dialog',
    templateUrl: './register-complete-dialog.component.html',
    styleUrls: ['./register-complete-dialog.component.scss']
})
export class RegisterCompleteDialogComponent implements OnInit
{

    constructor(protected dialogRef: MatDialogRef<RegisterCompleteDialogComponent>) 
    {

    }

    ngOnInit(): void
    {

    }

    closeDialog()
    {
        this.dialogRef.close();    
    }
}
