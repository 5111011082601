import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogNew } from "../lib/alert-dialog-new/alert.dialog.new";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: "root",
})
export class AlertService
{
    isEnglish: any;

    constructor(protected dialog: MatDialog, protected toaster: ToastrService) { }

    alertAsk(
        heading: string,
        message: string,
        rightButton: string,
        leftButton: string,
        hasInput?: boolean
    )
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: message,
                hasArabic: localStorage.getItem("lang") == "ar" ? true : false,
                hasInput: hasInput ? hasInput : false,
                rightButton: {
                    text: rightButton,
                    class: "btn-blue",
                },
                leftButton: {
                    text: leftButton,
                    class: "btn-white",
                },
                type: "ask",
            };
            dialogRef.afterClosed().subscribe((result) =>
            {
                resolve(result);
            });
        });
        return promise;
    }

    alert(
        heading: string,
        message: string,
        message_ar?: string,
        btnClass?: string
    ): any
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message: message,
                hasInput: false,
                hasArabic: false,
                rightButton: {
                    text: "",
                    class: "btn-ok",
                },
                leftButton: {
                    text: "Ok",
                    class: "btn-ok-sec",
                },
                type: "success",
            };
            dialogRef.afterClosed().subscribe((result) =>
            {
                resolve(result);
            });
        });
        return promise;
    }

    alertAr(heading: string, data: any, btnClass?: string): any
    {
        var promise = new Promise((resolve, reject) =>
        {
            let dialogRef = this.dialog.open(AlertDialogNew, { autoFocus: false });
            dialogRef.componentInstance.alertData = {
                heading: heading,
                message:
                    localStorage.getItem("lang") == "ar" ? data.message_ar : data.message,
                hasInput: false,
                hasArabic: true,
                rightButton: {
                    text: "",
                    class: "btn-ok",
                },
                leftButton: {
                    text: "Ok",
                    class: "btn-ok-sec",
                },
                type: "success",
            };
            dialogRef.afterClosed().subscribe((result) =>
            {
                resolve(result);
            });
        });
        return promise;
    }

    toastSuccess(title, message)
    {
        this.toaster.success(message, title);
    }
}
