<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap">
        <div fxFlex="29" fxFlex.xs="100" fxFlex.sm="100">
            <mat-card style="width: 280px">
                <div class="card-set">
                    <h2 style="color: #213060">
                        <img class="image" style="margin-right: 10px; width: 20px" src="assets/wallet.svg" alt="" />{{
                        "wallet" | translate }}
                    </h2>
                    <div style="
              width: 230px;
              height: 75px;
              text-align: center;
              padding: 5px 5px 10px 10px;
              font-weight: 600;
              font-size: 22px;
            ">
                        <p style="text-align: start; font-size: 11px; color: #8f9bba">
                            {{ "yourAmount" | translate }}
                        </p>
                        <!-- <span style="color: #213060">{{ "SAR" | translate }} {{ available_units | number:'1.4-4' }}</span> -->
                        <span style="color: #213060">{{ "SAR " | translate }}</span>
                        <span style="font-size: 22px;">{{available_units | number:'1.4-4' | slice:0:-4}}</span>
                        <span style="font-size: 18px;">{{available_units | number:'1.4-4' | slice:-4}}</span>
                    </div>
                </div>
            </mat-card>
        </div>

        <div fxLayout="row wrap" fxFlex="69" fxFlex.xs="100" fxFlex.sm="100">
            <form [formGroup]="Form" fxLayout="row wrap" fxFlex="49" fxFlex.xs="100" fxFlex.sm="100"
                fxLayoutAlign="start end" style="margin-top: 20px">
                <h2 fxFlex="100" style="color: #213060">
                    {{ "enterWithdrawal" | translate }}
                </h2>
                <div fxFlex="10">
                    <div style="font-size: 20px; font-weight: normal; line-height: 46px" matSuffix>
                        {{ "SAR" | translate }}&nbsp;
                    </div>
                </div>
                <div fxFlex="89">
                    <mat-form-field class="input-field mt-10" fxFlexAlign="center" appearance="outline"
                        style="width: 100%">
                        <mat-label style="color: #213060">{{ "enterAmount" | translate }}</mat-label>
                        <div style="font-weight: bold; color: #79C0C2;" [ngClass]="editModes.amount ? 'hide' : 'show'"
                            [style.color]="amount || amount == 0 ? 'black' : 'transparent'"
                            (click)="setMode('amount', true)" (focus)="setMode('amount', true)">
                            <!-- {{amount | number:'1.4-4'}} -->
                            <span style="font-size: 14px;">{{amount | number:'1.4-4' | slice:0:-4}}</span>
                            <span style="font-size: 11px;">{{amount | number:'1.4-4' | slice:-4}}</span>
                        </div>
                        <input (input)="restrictDecimalPlaces($event)" autocomplete="off" type="number" matInput formControlName="amount" [(ngModel)]="amount"
                            [ngClass]="editModes.amount == true ? 'show' : 'hide-input'"
                            (focusout)="setMode('amount', false)" (focus)="setMode('amount', true)">

                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue('amount')" #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage('amount', 'Amount')"
                            [matTooltipDisabled]="!getFieldValue('amount')" matTooltipPosition="above" matSuffix>info
                        </mat-icon>
                    </mat-form-field>
                </div>
            </form>
            <div fxLayout="row wrap" fxFlex="49" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="end end"
                fxLayoutGap="10px">
                <button class="btn btn-cancel" (click)="back()">
                    {{ "Cancel" | translate }}
                </button>
                <button class="btn btn-ok" (click)="submit()" [disabled]="Form.invalid">
                    {{ "Submit" | translate }}
                </button>
            </div>
        </div>

        <div style="margin-top: 40px;">
            <h4 style="color: #ee5d50;">{{'Please note that the withdrawal amount may be adjusted for additional costs, including fees, and the final amount can vary based on market conditions and prevailing rates at the time of execution of redemption.' | translate}}</h4>
        </div>
    </div>
</div>