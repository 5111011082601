import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialog } from "src/app/lib/alert-dialog/alert.dialog";
import { ChartType } from "chart.js";
import { MultiDataSet, Label, Color } from "ng2-charts";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { TranslationService } from "src/app/lib/translation/translation.service";

@Component({
    selector: "app-risk-chart",
    templateUrl: "./risk-chart.component.html",
    styleUrls: ["./risk-chart.component.scss"],
})
export class RiskChartComponent implements OnInit
{
    sub: Subscription;
    id: any;
    colors: Color[] = [];
    risk = "medium";
    public_equity: any = 0;
    fix_income: any = 0;
    money_markeet: any = 0;
    real_estate: any = 0;
    fundAssets: any[] = [];
    assetClassArray: any[] = [];

    constructor(
        public tr: TranslationService,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        protected mainService: ApiService,
        public dec_enc: DecEncService
    )
    {
        this.sub = this._route.params.subscribe((params) =>
        {
            this.id = Number(params["id"]);
        });
    }

    ngOnInit(): void
    {
        this.getall();
    }

    doughnutChartLabels: Label[] = [];
    doughnutChartData: MultiDataSet = [
        [15, 35, 50],
        [1, 1, 1, 1],
    ];
    doughnutChartType: ChartType = "doughnut";
    chartOptions = {
        cutoutPercentage: 75,
        rotation: 3.9,

        tooltips: {
            enabled: true,
        },
        events: [],
        legend: { display: false },
    };

    getall()
    {
        let url = "funds/available";
        this.mainService.postData(url, {}).then(
            (res) =>
            {
                if (res.statusCode == 200) {
                    let result;
                    let data = res.data;
                    let arr = [];
                    result = this.dec_enc.decryption(data);
                    result.funds.forEach((element) =>
                    {
                        if (element.id == this.id) {
                            this.fundAssets = element?.assets;
                            this.risk = element.risk_rating;
                            this.fundAssets.forEach(item => {
                                arr.push({
                                  asset_class: item?.asset_class,
                                  allocation: item?.funds_assets?.allocation
                                })
                              })
                              let map = arr.reduce(function(map, invoice) {
                                let asset_class = invoice.asset_class
                                let allocation = +invoice.allocation
                                map[asset_class] = (map[asset_class] || 0) + allocation
                                return map
                              }, {})
                              
                              this.assetClassArray = Object.keys(map).map(function(asset_class) {
                                return {
                                  asset_class: asset_class,
                                  allocation: map[asset_class]
                                }
                              })
                            if (this.risk == "low") {
                                this.colors = [
                                    {
                                        backgroundColor: [
                                            "#71C9FF",
                                            "#3F638F",
                                            "#004390",
                                            "#817E7B",
                                        ],
                                    },

                                    {
                                        backgroundColor: [
                                            "#D5D5D5",

                                            "#D5D5D5",
                                            "#FFFFFF",
                                            "#E7EF0C",
                                        ],
                                    },
                                ];
                            }
                            if (this.risk == "medium") {
                                this.colors = [
                                    {
                                        backgroundColor: [
                                            "#71C9FF",
                                            "#3F638F",
                                            "#004390",
                                            "#817E7B",
                                        ],
                                    },

                                    {
                                        backgroundColor: [
                                            "#E7EF0C",

                                            "#D5D5D5",

                                            "#FFFFFF",
                                            "#E7EF0C",
                                        ],
                                    },
                                ];
                            }
                            if (this.risk == "high") {
                                this.colors = [
                                    {
                                        backgroundColor: [
                                            "#71C9FF",
                                            "#3F638F",
                                            "#004390",
                                            "#817E7B",
                                        ],
                                    },

                                    {
                                        backgroundColor: [
                                            "#E7EF0C",

                                            "#E7EF0C",
                                            "#FFFFFF",
                                            "#E7EF0C",
                                        ],
                                    },
                                ];
                            }
                        }
                    });
                    this.doughnutChartData = [
                        [
                            this.public_equity,
                            this.fix_income,
                            this.money_markeet,
                            this.real_estate,
                        ],
                        [1, 1, 1, 1],
                    ];
                } else {
                    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                    let cm = dialogRef.componentInstance;
                    cm.heading = this.tr.translation.error;
                    cm.message = this.tr.translation.serverError;
                    cm.cancelButtonText = this.tr.translation.okay;
                    cm.type = "error";
                }
            },
            (error) =>
            {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = this.tr.translation.serverError;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = "error";
            }
        );
    }

    back()
    {
        window.history.back();
    }
}
