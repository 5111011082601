import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-employee-form-new",
  templateUrl: "./employee-form-new.component.html",
  styleUrls: ["./employee-form-new.component.scss"],
})
export class EmployeeFormNewComponent implements OnInit {
  Form: FormGroup;
  kinForm: FormGroup;
  planForm: FormGroup;
  taxForm: FormGroup;
  currentDate = new Date();
  currentIndexOfStepper: number = 0;
  id: any;
  iqama_image_new: any;
  constructor(
    protected formbuilder: FormBuilder,
    public tr: TranslationService,
    protected dec_enc: DecEncService,
    protected mainService: ApiService,
    public route: Router
  ) {
    this.id = localStorage.getItem("employee_id");
    this.initForm();
    this.initKinForm();
    this.initPlanForm();
    this.initTaxForm();
  }

  ngOnInit(): void {
    this.getdata();
  }

  initForm() {
    this.Form = this.formbuilder.group({
      name: [""],
      phone: [""],
      email: [""],
      gender: [""],
      date_of_birth: [""],
      address: [""],
      iqama_id: [""],
      iqama_expiry: [""],
      role: ["employee"],
      nationality: [""],
      employee_since: [""],
      position: [""],
      are_you_dual_nationality_holder: [""],
      basic_salary: [null],
    });
  }

  initKinForm() {
    this.kinForm = this.formbuilder.group({
      first_name: [""],
      last_name: [""],
      relation_to_nok: [""],
      contact_details: [""],
      power_of_attorney: [""],
    });
  }

  initPlanForm() {
    this.planForm = this.formbuilder.group({
      monthly_contribution_amount: [""],
      matching_percent: [""],
      matching_rule: [""],
    });
  }

  initTaxForm() {
    this.taxForm = this.formbuilder.group({
      tax: [""],
      tax_ssn_no: [""],
      tax_itn_no: [""],
    });
  }

  onStepChange(event) {
    this.currentIndexOfStepper = event.selectedIndex;
  }

  getFieldValue(form, field) {
    if (form.get(field).touched) {
      if (form.get(field).invalid) {
        return true;
      }
    }
    return false;
  }

  checkValidations(form) {
    if (form.invalid) {
      form.markAllAsTouched();
      return false;
    }
    return true;
  }

  getErrorMessage(form, control, fieldName, minVal?, maxVal?) {
    if (form.get(control).invalid) {
      if (form.get(control).hasError("required")) {
        return `${fieldName} is a required field.`;
      }
      if (form.get(control).hasError("minlength")) {
        return `Minimum length for ${fieldName} is ${minVal} characters.`;
      }
      if (form.get(control).hasError("maxlength")) {
        return `Maximum length for ${fieldName} is ${maxVal} characters.`;
      }
      if (form.get(control).hasError("email")) {
        return `${fieldName} should be in valid email format.`;
      }
      if (control == "name" && form.get(control).hasError("pattern")) {
        return `${fieldName} should be letters only.`;
      }
      if (
        (control == "phone" || control == "iqama_id") &&
        form.get(control).hasError("pattern")
      ) {
        return `${fieldName} should be numbers only.`;
      }
    }
  }

  getdata() {
    let url = "employee/details";
    let employee_id = this.id;
    let enc_payload = this.dec_enc.encryption({ employee_id });
    this.mainService.postData(url, { enc_payload }).then((result) => {
      if (result.statusCode === 200) {
        let data = this.dec_enc.decryption(result.data);
        if (data.employee !== null) {
          this.Form.patchValue(data.employee);
          this.iqama_image_new =
            this.mainService.file_url + data.employee.iqama_image;
        }
        if (data.next_of_kin !== null) {
          this.kinForm.patchValue(data.next_of_kin);
        }
        if (data.plan_specific_details !== null) {
          this.planForm.patchValue(data.plan_specific_details);
        }
        if (data.tax_residency_information !== null) {
          this.taxForm.patchValue(data.tax_residency_information);
        }
      }
    });
    this.Form.disable();
    this.kinForm.disable();
    this.planForm.disable();
    this.taxForm.disable();
  }

  onNext() {
    if (this.currentIndexOfStepper < 2) {
      this.currentIndexOfStepper++;
    }
  }

  onCancel() {
    this.route.navigate(["main/manage_portfolio"]);
  }
}
