<div class="main pt-1 mrl-20 mt-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <div class="tableHeading">
    <h2 style="margin: 0; font-weight: bold; color: #213060">
      {{ "HowCanWeHelp" | translate }}
    </h2>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div fxFlex="48" fxLayout.xs="column" fxFlex.sm="100" fxFlex.xs="100">
      <div class="mt-4 card-main">
        <mat-card>
          <div class="card-set">
            <div fxLayout="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="70" class="tableHeading">
                <h3 style="margin: 0; font-weight: bold; color: #213060">
                  {{ "Faqs" | translate }}
                </h3>
              </div>
            </div>
            <mat-accordion>
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                style="background-color: #f3f3f3"
              >
                <mat-expansion-panel-header style="background-color: white">
                  <mat-panel-title>
                    {{ "q1" | translate }}
                  </mat-panel-title>
                  <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <p
                  style="padding: 10px 15px 0px !important"
                  class="tableHeading"
                >
                  {{ "ans1" | translate }}
                </p>
              </mat-expansion-panel>
            </mat-accordion>
            <div style="margin-top: 5px"></div>
            <mat-accordion>
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                style="background-color: #f3f3f3"
              >
                <mat-expansion-panel-header style="background-color: white">
                  <mat-panel-title>
                    {{ "q2" | translate }}
                  </mat-panel-title>
                  <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <p
                  style="padding: 10px 15px 0px !important"
                  class="tableHeading"
                >
                  {{ "ans2" | translate }}
                </p>
              </mat-expansion-panel>
            </mat-accordion>
            <div style="margin-top: 5px"></div>
            <mat-accordion>
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                style="background-color: #f3f3f3"
              >
                <mat-expansion-panel-header style="background-color: white">
                  <mat-panel-title>
                    {{ "q3" | translate }}
                  </mat-panel-title>
                  <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <p
                  style="padding: 10px 15px 0px !important"
                  class="tableHeading"
                >
                  {{ "ans3" | translate }}
                </p>
              </mat-expansion-panel>
            </mat-accordion>
            <div style="margin-top: 5px"></div>
          </div>
        </mat-card>
      </div>
    </div>
    <div fxFlex="48" fxLayout.xs="column" fxFlex.sm="100" fxFlex.xs="100">
      <div class="mt-4 card-main">
        <mat-card>
          <div class="card-set">
            <div fxLayout="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="70" class="tableHeading">
                <h3 style="margin: 0; font-weight: bold; color: #213060">
                  {{ "Contact" | translate }}
                </h3>
              </div>
            </div>
            <form [formGroup]="Form">
              <div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                  <mat-form-field
                    class="input-field mt-10"
                    fxFlex="100"
                    fxFlex.sm="100"
                    fxFlex.xs="100"
                    appearance="outline"
                  >
                    <mat-label>{{ "SelectOption" | translate }}</mat-label>
                    <mat-select formControlName="title">
                      <mat-option value="General Inquiry">{{
                        "GeneralInquiry" | translate
                      }}</mat-option>
                      <mat-option value="Report an Issue">{{
                        "ReportanIssue" | translate
                      }}</mat-option>
                      <mat-option value="Suggestion">{{
                        "Suggestion" | translate
                      }}</mat-option>
                      <mat-option value="Complaint">{{
                        "Complaint" | translate
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    class="input-field mt-10"
                    appearance="outline"
                    fxFlex="100"
                    fxFlex.sm="100"
                    fxFlex.xs="100"
                  >
                    <mat-label>{{ "TypeMessage" | translate }}</mat-label>
                    <textarea
                      matInput
                      style="resize: none"
                      formControlName="message"
                      placeholder="{{ 'TypeMessage' | translate }}"
                      rows="5"
                    >
                    </textarea>
                  </mat-form-field>
                </div>

                <div style="margin-top: 10px">
                  <mat-card
                    class="card"
                    fxFlex="100"
                    fxFlex.sm="93.5"
                    fxFlex.xs="97"
                    fxLayout="row"
                    fxLayout.xs="column"
                    style="height: 162px; border-radius: 20px"
                    ngStyle.xs="height:280px;"
                  >
                    <input
                      style="display: none"
                      type="file"
                      #attack
                      (change)="onFileSelected($event)"
                      accept=".png, .jpg, .jpeg"
                    />
                    <button
                      mat-button
                      class="upload-button"
                      (click)="attack.click()"
                    >
                      <ng-container *ngIf="!image">
                        <mat-icon>upload</mat-icon>
                        <h2>{{ "Upload Files" | translate }}</h2>
                        <h5>
                          {{ "PNG and JPG files are allowed" | translate }}
                        </h5>
                      </ng-container>
                      <ng-container *ngIf="image">
                        <img style="width: 100%; height: 100%" [src]="image" />
                      </ng-container>
                    </button>
                    <span
                      class="button-text"
                      fxFlexOffset="6"
                      ngStyle.xs="margin-left:22px;"
                    >
                      <h3>{{'Click to upload the Image' | translate}}</h3>
                    </span>
                  </mat-card>
                </div>
                <div class="mt-20">
                  <button
                    (click)="submit()"
                    [disabled]="Form.invalid"
                    class="btn btn-ok"
                  >
                    {{ "Submit" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="mt-20">
    <mat-divider></mat-divider>
  </div>

  <div class="mt-4 card-main">
    <mat-card>
      <div class="card-set">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <div fxFlex="70" class="tableHeading">
            <h3 style="margin: 0; font-weight: bold; color: #213060">
              {{ "follow" | translate }}
            </h3>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div
            fxFlex="10"
            fxFlex.sm="48"
            fxFlex.xs="97"
            fxLayout.xs="column"
            fxLayout.sm="column"
          >
            <img src="assets/www.png" style="width: 50px" />
            <p style="margin-top: 5px">
              <a href="https://www.thriftplan.io​" target="_blank">Website</a>
            </p>
          </div>
          <div
            fxFlex="10"
            fxFlex.sm="48"
            fxFlex.xs="97"
            fxLayout.xs="column"
            fxLayout.sm="column"
          >
            <img src="assets/likin.png" style="width: 50px" />
            <p style="margin-top: 5px">
              <a
                href="https://www.linkedin.com/company/thriftplan"
                target="_blank"
                >Linkedin</a
              >
            </p>
          </div>
          <div
            fxFlex="10"
            fxFlex.sm="48"
            fxFlex.xs="97"
            fxLayout.xs="column"
            fxLayout.sm="column"
          >
            <img src="assets/fbicon.png" style="width: 50px" />
            <p style="margin-top: 5px">
              <a href="https://www.facebook.com/ThriftPlan​" target="_blank"
                >Facebook</a
              >
            </p>
          </div>
          <div
            fxFlex="10"
            fxFlex.sm="48"
            fxFlex.xs="97"
            fxLayout.xs="column"
            fxLayout.sm="column"
          >
            <img src="assets/twiter.png" style="width: 50px" />
            <p style="margin-top: 5px">
              <a href="https://twitter.com/ThriftPlan/​" target="_blank"
                >Twitter</a
              >
            </p>
          </div>
          <div
            fxFlex="10"
            fxFlex.sm="20"
            fxFlex.xs="97"
            fxLayout="row"
            fxLayout.xs="column"
          ></div>
        </div>
      </div>
    </mat-card>
  </div>
  <div style="margin-top: 20px"></div>
</div>
