<api-loader></api-loader>
<app-translation-new></app-translation-new>
<div class="main-card" style="padding: 0px 20px 0px 20px">
  <div class="TermsMain" fxLayout="row wrap">
    <h1 class="top-heading" fxFlex="100">
      {{ "Terms_Conditions" | translate }}
    </h1>
    <p class="updated-time" fxFlex="100">
      {{ "Last Updated" | translate }} {{ updatedAt }}
    </p>
  </div>
  <div
    fxLayout="row wrap"
    style="height: 87%"
    fxLayoutAlign="space-between center"
  >
    <div fxFlex="100" class="editor-content">
      <div class="editor">
        <div id="editorjs"></div>
      </div>
    </div>
    <div fxFlex="100" fxLayoutGap="10px" style="text-align: end; height: 8%">
      <button (click)="onCancelTerms()" class="btn-cancel" mat-raised-button>
        {{ "Cancel" | translate }}
      </button>
      <button
        [class.btn-disabled]="!isAdminApproved"
        (click)="onAcceptTerms()"
        class="accept-btn"
        mat-raised-button
      >
        {{ "Accept Terms & Conditions to Continue" | translate }}
      </button>
    </div>
  </div>
</div>
